import React from 'react';
import { useTranslation } from 'react-i18next';
import PaymentMethodItem from '../PaymentMethodItem';
import classes from './styles.module.css';
import { generateTrialLabel } from '../PurchasePriceModel/utils';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

const PaymentMethods = ({
  isTrial,
  model,
  onOfferSelect,
  selectedOffer,
  isRequestedPlanChange,
  currentSubsription,
}) => {
  const { t } = useTranslation();

  return isTrial ? (
    generateTrialLabel()
  ) : (
    <div className={classes.priceMethodWrapper}>
      <div className={classes.chooseMethodTitle}>{t('subscription.choosePaymentMethod')}</div>
      {!isRequestedPlanChange && (
        <>
          <PaymentMethodItem
            onOfferSelect={onOfferSelect}
            item={{ ...model, type: paymentMethodEnum.oneTimeForMonth }}
            label="subscription.oneMonth"
            selectedOffer={selectedOffer}
          />
          <PaymentMethodItem
            onOfferSelect={onOfferSelect}
            item={{ ...model, type: paymentMethodEnum.oneTimeForYear }}
            label="subscription.wholeYear"
            selectedOffer={selectedOffer}
          />
        </>
      )}
      <PaymentMethodItem
        onOfferSelect={onOfferSelect}
        item={{ ...model, type: paymentMethodEnum.recurring }}
        label="subscription.cyclicPayment"
        selectedOffer={selectedOffer}
        currentSubsription={currentSubsription}
        isRequestedPlanChange={isRequestedPlanChange}
      />
    </div>
  );
};

export default PaymentMethods;
