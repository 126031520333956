import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useModal } from '../../../../../shared/helpers/hooks';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../ChangePassword/utils';
import InvoiceModalForm from '../InvoiceModalForm';
import OpinionsEmptyState from '../../../components/EmptyState';
import InvoiceDataItem from '../InvoiceDataItem';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';

const InvoicesData = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [formIsOpen, openForm, toggleFormVisibility] = useModal();

  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDataList, setInvoiceDataList] = useState([]);
  const isInvoiceDataListEmpty = !invoiceDataList.length && !isLoading;

  const fetchInvoiceDataList = async () => {
    setIsLoading(true);
    try {
      const {
        data: { userInvoiceDetails },
      } = await api.post('/invoice/getUserInvoiceDetails', {});
      setInvoiceDataList(userInvoiceDetails);
    } catch (error) {
      handleApiError(error, showNotification);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoiceDataList();
  }, []);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      <div className={classes.header}>
        <div className={classes.title}>{t('invoices.paymentData')}</div>
        {formIsOpen && (
          <InvoiceModalForm
            onClose={toggleFormVisibility}
            dataExist={Boolean(invoiceDataList.length)}
            onSuccess={fetchInvoiceDataList}
          />
        )}
        {!isInvoiceDataListEmpty && (
          <Button
            label={t('invoices.addNewData')}
            Icon={AddCircle}
            className={classes.addDataBtn}
            onClick={toggleFormVisibility}
          />
        )}
      </div>
      <div className={classes.body}>
        {isInvoiceDataListEmpty ? (
          <div className={classes.emptyStateWrapper}>
            <OpinionsEmptyState
              title={t('settings.noInvoiceData')}
              subTitle={t('settings.noInvoiceDataSubInfo')}
              buttonLabel={t('invoices.addNewData')}
              buttonOnClick={toggleFormVisibility}
            />
          </div>
        ) : (
          <div className={classes.invoiceDataList}>
            {invoiceDataList.map((invoiceData) => (
              <InvoiceDataItem
                key={invoiceData.id}
                invoiceData={invoiceData}
                onSuccess={fetchInvoiceDataList}
                itemsLength={invoiceDataList.length}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InvoicesData;
