export const loadPayUScript = () => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = 'https://secure.snd.payu.com/javascript/sdk';
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => resolve();
  script.onerror = () => reject(new Error('Failed to load PayU SDK'));
  document.body.appendChild(script);
});

export const optionsForms = {
  style: {
    basic: {
      fontSize: '16px',
      fontFamily: 'Poppins, sans-serif',
      color: '#000',
    },
    placeholder: {
      color: '#000',
    },
  },
  placeholder: {
    number: 'Numer karty',
    date: 'Data ważności karty',
    cvv: 'CVV',
  },
  lang: 'pl',
};

export const getErrorMessages = (obj) => Object.values(obj)
  .flatMap((error) => error)
  .map((error) => error?.message)
  .filter((message) => message !== undefined);
