import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import Pill from '../../../../../shared/components/Pill';
import classes from './styles.module.css';
import { activePillConfig } from '../../../views/AdministratorList/utils';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../../SEO/helpers/constants';

export const accountDetailsConfig = (t, userData) => {
  const { active, createdAt, lastLogin } = userData;
  return [
    {
      title: t('adminControlPanel.accountCreationDate'),
      key: 'createdAt',
      value: dayjs(createdAt).format(DATE_FORMAT_DD_MM_YYYY),
    },
    {
      title: t('adminControlPanel.lastLoggedIn'),
      key: 'lastLogin',
      value: dayjs(lastLogin).format(DATE_FORMAT_DD_MM_YYYY),
    },
    {
      title: t('adminControlPanel.status'),
      key: 'active',
      value: (
        <Pill className={clsx(classes.pill, active && classes.activeStatusPill)} label={activePillConfig(active, t)} />
      ),
    },
  ];
};
