import React from 'react';
import classes from './styles.module.css';

const CardDetailsField = () => (
  <div className={classes.cardDetails}>
    <div className={classes.expiration}>
      <div className={classes.payuCardForm} id="payu-card-date" />
    </div>
    <div className={classes.cvv}>
      <div className={classes.payuCardForm} id="payu-card-cvv" />
    </div>
  </div>
);

export default CardDetailsField;
