import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../Modal';
import classes from './styles.module.css';
import Button from '../Button';
import { useApi } from '../../helpers/api';
import { handleApiError } from '../../../app/SEO/modules/Settings/ChangePassword/utils';
import { useAuth } from '../../helpers/auth';
import { useNotification } from '../../helpers/notification';
import Loader from '../../../app/SEO/components/Loader';
import LoaderOverlay from '../LoaderOverlay';

const GoogleAuthorizationModal = ({ onCancel }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { clearAuth } = useAuth();
  const { showNotification } = useNotification();

  const [userWantToDelete, setUserWantToDelete] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const modalTitle = userWantToDelete ? t('global.deleteAccTitle') : t('global.noGoogleAuthorization');
  const modalInformationText = userWantToDelete
    ? t('settings.disconnectAccountText')
    : t('global.noGoogleAuthorizationSubTitle');

  const getGoogleReauthorizationUrl = async () => {
    const {
      data: { googleAuthUrl },
    } = await api.post('/google_auth/getReauthorizationUrl', {});

    window.open(googleAuthUrl, 'noreferrer');
  };

  const logout = async () => {
    await api.post('/auth/logout', {});
    clearAuth();
  };

  const detachAccount = async () => {
    await api.post('account/detachAccount', {});
  };

  const handleLogoutAndDetach = async () => {
    setIsLoading(true);
    try {
      await detachAccount();
      await logout();
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const firstActionBtnLabel = userWantToDelete ? t('global.back') : t('global.deleteAccBtn');
  const secondActionBtnLabel = userWantToDelete ? t('global.deleteAccBtn') : t('global.authorizationBtnLabel');
  const firstActionBtnOnClickHandler = () => setUserWantToDelete((prev) => !prev);
  const secondActionBtnOnClickHandler = () => (userWantToDelete ? handleLogoutAndDetach() : getGoogleReauthorizationUrl());

  return (
    <Modal modalTitle={modalTitle} contentStyle={classes.modalContent} onCancel={onCancel} backdropLocked>
      {isLoading ? (
        <LoaderOverlay customStyle={classes.customLoaderStyle} />
      ) : (
        <>
          <p>{modalInformationText}</p>
          <div className={classes.actionWrapper}>
            <Button
              className={clsx(!userWantToDelete && classes.deleteAccBtn, userWantToDelete && classes.goBackBtn)}
              label={firstActionBtnLabel}
              onClick={firstActionBtnOnClickHandler}
            />
            <Button label={secondActionBtnLabel} onClick={secondActionBtnOnClickHandler} />
          </div>
        </>
      )}
    </Modal>
  );
};

export default GoogleAuthorizationModal;
