import { businessTypeEnum } from '../../modules/BusinessProfileCreate/FormShareLocation/utils';

const fetchImagesForBusinessProfiles = async (businessProfiles, api, controller) => {
  const profilesWithNoImages = businessProfiles.filter((el) => !el.image);
  const imagePromiseList = profilesWithNoImages?.map((el) => {
    const { fileName, fileDate } = el;

    if (!fileName || !fileDate) {
      return Promise.reject({ status: 'rejected' });
    }

    return api.get(`/assets/place/${fileDate}/${fileName}`, {
      signal: controller?.signal,
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });
  });

  const images = await Promise.allSettled(imagePromiseList);
  return images;
};

export const handleProfileImages = async (businessProfiles, api, controller) => {
  if (!businessProfiles) return Promise.resolve([]);
  const businesProfilesWithNoImages = businessProfiles.filter((el) => !el.image);
  const images = await fetchImagesForBusinessProfiles(businesProfilesWithNoImages, api, controller);
  const profilesWithImages = businesProfilesWithNoImages.map((el, index) => {
    if (images[index].status === 'fulfilled') {
      return {
        ...el,
        image: window.URL.createObjectURL(new Blob([images[index].value.data], { type: 'image/jpg' })),
      };
    }
    return el;
  });
  return [...businessProfiles.filter((el) => el.image), ...profilesWithImages];
};

export const saveClientTokensLocalStorage = ({ token, refreshToken }) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
};

export const businessTypeParser = (businessProfiles, subscriptions) => {
  const profilesWithBusinessType = businessProfiles.map((el) => {
    if (el.address.length) {
      return {
        ...el,
        businessType: businessTypeEnum.BUSINESS_TYPE_UNSPECIFIED,
        featureList: subscriptions[el.subscription],
      };
    }
    return {
      ...el,
      businessType: businessTypeEnum.CUSTOMER_LOCATION_ONLY,
      featureList: subscriptions[el.subscription],
    };
  });
  return profilesWithBusinessType;
};
