import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useDashboard } from '../../helpers/dashboard';
import { useNotification } from '../../../../shared/helpers/notification';
import {
  dashboardPromisesData,
  fetchDataHandler,
  fetchKeywordsData,
  fetchPerformanceData,
  fetchUserChangeHistory,
  parseDataOnPeriodChange,
} from './utils';
import Header from '../../modules/Dashboard/Header';
import Statistics from '../../modules/Dashboard/Statistics';
import ViewsChart from '../../modules/Dashboard/ViewsChart';
import BusinessCardsMetadata from '../../modules/Dashboard/BusinessCardsMetadata';
import BusinessKeywordsWithOpinions from '../../modules/Dashboard/BusinessKeywordsWithOpinions';
import Loader from '../../components/Loader';
import { getLocalStorageProfileStatus } from '../../../../shared/helpers/helpers';
import { PROFILE_STATUS_ENUM } from '../../helpers/enums';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const Dashboard = ({ businessProfileId, adminPreview = false, userId }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const {
    businessProfile: { id, externalBusinessProfile, subscription },
  } = useDashboard();

  const controllerRef = useRef(new AbortController());

  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [profileStatus, setProfileStatus] = useState();
  const [chartToDisplay, setChartToDisplay] = useState(t('dashboard.viewsNumber'));
  const [period, setPeriod] = useState({
    displayPeriod: t('dashboard.last30Days'),
    period: { dateFrom: null, dateTo: null },
  });

  const profileStatusInStorage = getLocalStorageProfileStatus(id || businessProfileId);
  const isNotVerified = profileStatus !== PROFILE_STATUS_ENUM.verified;
  const placeId = dashboardData?.formData?.metadata?.placeId;

  const dashboardPromises = useMemo(
    () => dashboardPromisesData({
      id: id || businessProfileId,
      profileStatus,
      adminPreview,
      userId,
      subPlan: subscription,
    }),
    [id, profileStatus, businessProfileId, adminPreview],
  );

  const fetchDataFn = async () => {
    const responses = await fetchDataHandler({
      api,
      signal: controllerRef.current.signal,
      promises: dashboardPromises,
      profileStatus,
    });
    return responses;
  };

  const handleFetchError = (err) => {
    if (err.name === 'AbortError') {
      throw new Error('Fetch aborted');
    } else {
      setError(true);
      handleApiError({ err, showNotification, t });
    }
  };

  const onPeriodSelectHandler = async (value) => {
    const { dateFrom, dateTo } = value;
    const payload = {
      businessProfileId: id || businessProfileId,
      dateTo,
      dateFrom,
    };
    setIsLoading(true);
    try {
      const [perfomanceData, keywordsData, userChangeHistory] = await Promise.all([
        fetchPerformanceData(api, payload, userId),
        fetchKeywordsData(api, payload, userId),
        fetchUserChangeHistory(api, payload, userId),
      ]);

      const parsed = parseDataOnPeriodChange(
        {
          perfomanceData,
          keywordsData,
          userChangeHistory,
        },
        profileStatus,
      );

      const {
        userChangeHistory: historyWithPeriodChange,
        parsedStatistic,
        additionalInteractions,
        additionalSearchStatistic,
        chartData,
        interactionsChartData,
        performance,
        parsedKeywords,
      } = parsed;

      setDashboardData((prev) => ({
        ...prev,
        additionalSearchStatistic,
        userChangeHistory: historyWithPeriodChange,
        additionalInteractions,
        generalSearchData: chartData,
        statistic: { ...prev.statistic, ...parsedStatistic },
        keywordWithOpinions: { ...prev.keywordWithOpinions, ...parsedKeywords },
        performance,
        interactionsChartData,
      }));
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const onPeriodChange = async (value) => {
    const { data } = value;
    setPeriod(value);
    onPeriodSelectHandler(data);
  };

  useLayoutEffect(() => {
    const fetchData = async () => {
      if ((id || businessProfileId) && !externalBusinessProfile && profileStatus) {
        try {
          setIsLoading(true);
          const responses = await fetchDataFn();
          setDashboardData(responses);
        } catch (err) {
          handleFetchError(err);
        } finally {
          setIsLoading(false);
        }
      }
    };
    if (profileStatus) {
      fetchData();
    }
  }, [profileStatus, id, businessProfileId, externalBusinessProfile]);

  useLayoutEffect(() => {
    setProfileStatus(profileStatusInStorage);
  }, [id, businessProfileId, profileStatusInStorage]);

  useEffect(() => {
    const shouldShowNotification = isNotVerified && !isLoading && profileStatus;
    if (shouldShowNotification) {
      showNotification({ message: t('dashboard.cannotDisplayFullData'), type: 'warning' });
    }
  }, [profileStatus]);

  if (!profileStatusInStorage || isLoading) {
    return (
      <LoaderOverlay customStyle={classes.loaderWrapper}/>
    );
  }

  return (
    <div className={clsx(classes.wrapper)}>
      <Header status={profileStatus} onPeriodSelect={onPeriodChange} period={period.displayPeriod} />
      <div className={classes.body}>
        <div className={classes.statistic}>
          <Statistics
            status={profileStatus}
            data={dashboardData.statistic}
            searchDataDetails={dashboardData.additionalSearchStatistic}
            additionalInteractions={dashboardData.additionalInteractions}
            onChartToDisplayChange={setChartToDisplay}
          />
        </div>
        <div className={classes.viewsChart}>
          <ViewsChart
            chartToDisplay={chartToDisplay}
            selectedPeriod={period.displayPeriod}
            status={profileStatus}
            data={dashboardData.generalSearchData}
            additionalInteractions={dashboardData.interactionsChartData}
            performance={dashboardData.performance}
            searchDataDetails={dashboardData.additionalSearchStatistic}
            userChangeHistory={dashboardData.userChangeHistory}
          />
        </div>
        <div className={clsx(classes.businessCardsMetadata, isNotVerified && classes.noData)}>
          <BusinessCardsMetadata
            status={dashboardData.profileStatus}
            data={dashboardData.formData}
            placeId={placeId}
            adminPreview={adminPreview}
          />
        </div>
        <div className={classes.businessKeywordsWithOpinions}>
          <BusinessKeywordsWithOpinions
            data={dashboardData.keywordWithOpinions}
            status={profileStatus}
            adminPreview={adminPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
