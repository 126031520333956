import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { POLISH_CURRENCY } from '../../../helpers/constants';

const SecureFormPaymentDetails = ({ selectedPlan, paymentDetails }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.seletedPlanWrapper}>
      <div className={classes.row}>
        <Pill className={classes.subscriptionPill} label={t(`subscription.${selectedPlan.title.toLowerCase()}Plan`)} />
        <div>
          <div className={classes.paymetType}>{t('settings.cyclicMonthlyPayment')}</div>
          <div className={classes.planPrice}>
            {paymentDetails?.price || 0}
            <span>
              {' '}
              {POLISH_CURRENCY}
            </span>
            <span className={classes.pricePerPeriod}>{t(paymentDetails?.priceTagShort)}</span>
          </div>
          <div className={classes.taxIncluded}>{t('settings.taxIncluded')}</div>
        </div>
      </div>
    </div>
  );
};

export default SecureFormPaymentDetails;
