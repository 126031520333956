import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import classes from './styles.module.css';
import { actionsEnum, businessProfileListconfig, paginationLimitList } from './utils';
import { useAuth } from '../../../../../shared/helpers/auth';
import { useDashboard } from '../../../helpers/dashboard';
import { useNotification } from '../../../../../shared/helpers/notification';
import Pagination from '../../../../../shared/components/Pagination';
import NoBusinessProfiles from '../NoBusinessProfiles';
import List from '../../../../../shared/components/List';
import BusinessBasicDataSettings from '../BusinessBasicDataSettings';
import BusinessCardSettingsHeader from '../BusinessCardSettingsHeader';
import BusinessCardSettingsActionsWrapper from '../BusinessCardSettingsActionsWrapper';
import { handleApiError } from '../ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useModal, useVerificationStatuses } from '../../../../../shared/helpers/hooks';
import ConfirmDeleteModal from '../../BusinessProfileEdit/ConfirmDeleteModal';
import { payloadEnum } from '../../../views/AddPost/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const { LIMIT, OFFSET } = payloadEnum;

const BusinessCardsSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessProfiles } = useAuth();
  const { businessProfile, selectBusinessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const { api } = useApi();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllowed, setSelectAllowed] = useState(false);
  const [importedProfiles, setImportedProfiles] = useState([]);
  const [selectedBusinessProfiles, setSelectedBusinessProfiles] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    limit: 5,
    offset: 0,
    orderBy: 'name asc',
    businessProfileId: businessProfile.id,
    page: 1,
  });

  const [isOpenConfirmationModal, setIsOpenConfirmationModal, toggleConfirmationModal] = useModal();
  const { statuses, statusLoading } = useVerificationStatuses(importedProfiles, t, showNotification);

  const updateFilters = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }, []);

  const pageChangeHandler = useCallback(
    (value) => {
      updateFilters('page', value);
      updateFilters(OFFSET, value * filters.limit - filters.limit);
    },
    [filters.limit, updateFilters],
  );

  const limitChangeHandler = useCallback(
    (value) => {
      updateFilters('page', 1);
      updateFilters(LIMIT, value);
      updateFilters(OFFSET, 0);
    },
    [updateFilters],
  );

  const toggleSelectAll = useCallback(() => {
    setSelectAllowed((prev) => !prev);
  }, []);

  const onDetachHandler = useCallback((id) => {
    setImportedProfiles((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const selectAllHandler = useCallback(() => {
    const profilesID = businessProfiles.map((el) => el.id);
    setSelectedBusinessProfiles((prev) => {
      if (filters.limit < businessProfiles.length && prev.length !== businessProfiles.length) {
        showNotification({ type: 'warning', message: t('settings.selectAllWarging') });
      }
      return prev.length === businessProfiles.length ? [] : profilesID;
    });
  }, [businessProfiles, showNotification, t, selectedBusinessProfiles, filters.limit]);

  const detachSelectedHandler = useCallback(async () => {
    setIsLoading(true);
    setIsOpenConfirmationModal(false);

    try {
      const newSelectedBusinessProfiles = [...selectedBusinessProfiles];
      newSelectedBusinessProfiles.forEach((el) => onDetachHandler(el.id));
      await api.post('/business_profile/detachBusinessProfiles', { businessProfilesId: newSelectedBusinessProfiles });
      setSelectAllowed(false);
      setSelectedBusinessProfiles([]);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
      setSelectAllowed(false);
    }
  }, [selectedBusinessProfiles, onDetachHandler, api, t, showNotification, setIsOpenConfirmationModal]);

  const singleItemCheckboxClick = useCallback((id) => {
    setSelectedBusinessProfiles((prev) => {
      if (prev.includes(id)) {
        return prev.filter((el) => el !== id);
      }
      return [...prev, id];
    });
  }, []);

  const onRowClickHandler = (row) => {
    setSelectAllowed(true);
    setSelectedBusinessProfiles((prev) => {
      if (prev.includes(row.id)) {
        return prev.filter((el) => el !== row.id);
      }
      return [...prev, row.id];
    });
  };
  const debouncedSetImportedProfiles = _.debounce((value) => {
    setImportedProfiles(() => {
      if (!value.length) {
        return businessProfiles.slice(0, filters.limit);
      }
      return businessProfiles.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
    });
    setFilters((prev) => ({ ...prev, name: value }));
  }, 500);

  const config = useMemo(
    () => businessProfileListconfig({
      isSelectAllowed: selectAllowed,
      selectedItems: selectedBusinessProfiles,
      totalProfilesLength: businessProfiles.length,
      selectAll: selectAllHandler,
      singleItemCheckboxClick,
    }),
    [selectAllowed, selectAllHandler, selectedBusinessProfiles, singleItemCheckboxClick, businessProfiles.length],
  );

  const handleListAction = useCallback(
    (props) => {
      const { deletion, edit } = actionsEnum;
      const [action, key, row] = props;
      if (action === edit) {
        selectBusinessProfile(row.id);
        navigate('/dashboard/edit_business_profile');
        return;
      }

      if (action === deletion) {
        setSelectedBusinessProfiles([row.id]);
        toggleConfirmationModal();
      }
    },
    [navigate, selectBusinessProfile, toggleConfirmationModal],
  );

  useEffect(() => {
    const start = (filters.page - 1) * filters.limit;
    const end = start + filters.limit;
    const newList = businessProfiles.slice(start, end);
    setImportedProfiles(newList);
  }, [filters.page, filters.limit, businessProfiles]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const results = importedProfiles.map((item) => ({
          ...item,
          name: <BusinessBasicDataSettings title={item.name} address={item.address} />,
          businessCardStatus: statuses[item.id] || null,
        }));
        setData(results);
      } catch (err) {
        handleApiError({ err, t, showNotification });
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [importedProfiles, statuses, t, showNotification]);

  if (isLoading || statusLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.wrapper}>
      {isOpenConfirmationModal && (
        <ConfirmDeleteModal
          onClose={toggleConfirmationModal}
          onConfirm={detachSelectedHandler}
          title="settings.deleteProfileText"
          subInfo="settings.deleteProfile"
        />
      )}
      <BusinessCardSettingsHeader />

      <div className={classes.body}>
        <BusinessCardSettingsActionsWrapper
          readOnlyBtn={!selectedBusinessProfiles.length}
          businessProfiles={importedProfiles}
          onDetachSelected={detachSelectedHandler}
          selectAllowed={selectAllowed}
          filters={filters}
          selectedBusinessProfiles={selectedBusinessProfiles}
          selectAllowedHandler={toggleSelectAll}
          searchHandler={debouncedSetImportedProfiles}
        />
        <div className={classes.listWrapper}>
          {!importedProfiles.length ? (
            <NoBusinessProfiles />
          ) : (
            <List
              onClick={onRowClickHandler}
              data={data}
              config={config}
              onAction={(...props) => handleListAction(props)}
            />
          )}
        </div>
        {Boolean(businessProfiles.length) && filters.name === '' && (
          <Pagination
            className={classes.pagination}
            page={filters.page}
            total={businessProfiles.length}
            limit={filters.limit}
            paginationLimitList={paginationLimitList}
            onPageChange={pageChangeHandler}
            onLimitChange={limitChangeHandler}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessCardsSettings;
