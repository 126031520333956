export const purchaseStepConfig = {
  selectPlan: 'selectPlan',
  summary: 'summary',
  secureFormRecurring: 'secureFormRecurring',
};

export const getPreviousStep = (currentStep) => {
  switch (currentStep) {
    case purchaseStepConfig.payment:
      return null;
    case purchaseStepConfig.summary:
      return purchaseStepConfig.selectPlan;
    case purchaseStepConfig.secureFormRecurring:
      return purchaseStepConfig.summary;
    default:
      return null;
  }
};

export const getNavigationText = (isSmallMobile, purchaseFormStep, t) => {
  if (isSmallMobile) {
    return purchaseFormStep === purchaseStepConfig.selectBP
      ? t('settings.goBackSubscriptionList')
      : t('modalNaviagtion.goToPreviousStep');
  }
  return null;
};
