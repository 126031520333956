import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import Button from '../../../../../shared/components/Button';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../ChangePassword/utils';
import { useAuth } from '../../../../../shared/helpers/auth';
import Loader from '../../../components/Loader';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const DisconnectAccountModal = ({ onCancel }) => {
  const { t } = useTranslation();
  const { clearAuth } = useAuth();

  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const logout = async () => {
    await api.post('/auth/logout', {});
    clearAuth();
  };

  const detachAccount = async () => {
    await api.post('account/detachAccount', {});
  };

  const handleLogoutAndDetach = async () => {
    setIsLoading(true);
    try {
      await detachAccount();
      await logout();
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }
  return (
    <Modal contentStyle={classes.modal} onCancel={onCancel} modalTitle={t('settings.disconnectAccount')}>
      <div className={classes.modalBody}>
        <div className={classes.modalText}>{t('settings.disconnectAccountText')}</div>
        <div className={classes.modalBtns}>
          <Button className={classes.cancelBtn} onClick={onCancel} label={t('global.cancel')} />
          <Button
            className={classes.confirmBtn}
            onClick={handleLogoutAndDetach}
            label={t('settings.disconnectAccount')}
          />
        </div>
      </div>
    </Modal>
  );
};
export default DisconnectAccountModal;
