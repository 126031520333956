import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { paymentMethodConfig, paymentMethodEnum } from '../PaymentMethodItem/utils';
import { ReactComponent as LogoIcon } from '../../../../../shared/assets/logoIcon.svg';
import { ReactComponent as Check } from '../../../../../shared/assets/checkCircle.svg';
import { formatCurrency } from '../../../../../shared/helpers/helpers';

const SubscriptionPlanInfo = ({ subscription, paymentType }) => {
  const { t } = useTranslation();

  const price = paymentType ? paymentMethodConfig[subscription][paymentType]?.price : 0;
  const isYearly = paymentType === paymentMethodEnum.oneTimeForYear;
  const priceTag = isYearly ? t('subscription.perYearShort') : t('subscription.perMonthShort');

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.leftSideRow}>
        <LogoIcon className={classes.logoIcon} />
        <div>
          <div className={classes.planDetails}>
            <div className={classes.title}>{t('subscription.yourPlan')}</div>
            <Pill className={classes.subscriptionPill} label={subscription} />
          </div>
          <div className={classes.statusWrapper}>
            <Check className={classes.checkIcon} />
            <span className={classes.planStatusInfo}>{t('subscription.activePlanStatus')}</span>
          </div>
        </div>
      </div>
      <div className={classes.rightSideRow}>
        <div className={classes.priceTag}>
          {formatCurrency(price)}
          <span>{priceTag}</span>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlanInfo;
