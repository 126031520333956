import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { basicUserDataConfig } from './utils';

const UserData = ({ userData, manager }) => {
  const { t } = useTranslation();
  const dataTitle = manager ? t('adminControlPanel.managerData') : t('adminControlPanel.clientData');
  return (
    <>
      <div className={classes.sectionTitle}>{dataTitle}</div>
      <div className={classes.sectionDataWrapper}>
        {basicUserDataConfig(t).map(({ title, key }) => (
          <div key={key}>
            <div className={classes.dataTitle}>{title}</div>
            <div className={classes.dataValue}>{userData[key] || t('adminControlPanel.noData')}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default UserData;
