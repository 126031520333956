import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useDashboard } from '../../helpers/dashboard';
import ProfileNotVerified from '../../modules/Subscription/ProfileNotVerified';
import SubscriptionsEmptyState from '../../modules/Subscription/SubscriptionEmptyState';
import { profileStatus } from '../../modules/Settings/BusinessItem/utils';
import { subscriptionsConfig } from '../../helpers/constants';
import { useGetVerificationStatus, useModal } from '../../../../shared/helpers/hooks';
import PurchaseSubscriptionForm from '../../modules/Subscription/PurchaseSubscriptionForm';
import SubscriptionDetails from '../../modules/Subscription/SubscriptionDetails';
import RedirectConfirmation from '../../modules/Subscription/RedirectInformation';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';
import ReccuringSubscriptionBuyerData from '../../modules/Subscription/ReccuringSubscriptionBuyerData';
import { useApi } from '../../../../shared/helpers/api';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import { paymentMethodEnum } from '../../modules/Subscription/PaymentMethodItem/utils';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../../shared/helpers/enums';

const Subscription = () => {
  const { t } = useTranslation('');
  const { businessProfile } = useDashboard();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const {
    businessProfileVerificationStatus,
    requestStatus: { loading, error },
  } = useGetVerificationStatus(businessProfile);

  const [showForm, setShowForm, toggleForm] = useModal();
  const [isOpenConfirmationModal, setIsOpenConfirmationModal, toggleConfirmationModal] = useModal();

  const [subscriptionDetails, setSubscriptionDetails] = useState({
    subscription: null,
    paymentType: null,
    invoiceData: null,
    status: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const { subscription, paymentType } = subscriptionDetails;
  const isRecuring = paymentType === paymentMethodEnum.recurring;
  const isCanceled = subscriptionDetails.status === SUBSCRIPTION_STATUS_ENUM.CANCELED;

  const paymentDataChangeHandler = (data) => {
    setPaymentData(data);
    toggleConfirmationModal();
  };

  const fetchSubscription = async (controller = new AbortController()) => {
    setIsLoading(true);
    try {
      const { data } = await api.post(
        '/subscription/subscriptionByBpId',
        { businessProfileId: businessProfile.id },
        { signal: controller.signal },
      );
      setSubscriptionDetails(data);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const onFetchInvoiceData = async () => {
    setIsLoading(true);
    try {
      const {
        data: { userInvoiceDetails },
      } = await api.post('/invoice/getUserInvoiceDetails', {});
      setInvoiceData(userInvoiceDetails);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const isVerified = businessProfileVerificationStatus === profileStatus.verified;
    if (businessProfile.id && isVerified) {
      fetchSubscription(controller);
    }
    if (isRecuring) {
      onFetchInvoiceData();
    }

    return () => controller.abort();
  }, [businessProfile.id, isRecuring, businessProfileVerificationStatus]);

  const renderContent = () => {
    if (!businessProfileVerificationStatus) return null;

    if (businessProfileVerificationStatus === profileStatus.verified) {
      return subscription === subscriptionsConfig.free ? (
        <SubscriptionsEmptyState onPurchaseClick={toggleForm} />
      ) : (
        <>
          <SubscriptionDetails
            refetchFn={fetchSubscription}
            subscriptionData={subscriptionDetails}
            isActive={!isCanceled}
          />
          {isRecuring && !isCanceled && (
            <ReccuringSubscriptionBuyerData
              data={subscriptionDetails.invoiceData}
              invoiceData={invoiceData}
              refetchFn={fetchSubscription}
            />
          )}
        </>
      );
    }

    return <ProfileNotVerified status={businessProfileVerificationStatus} />;
  };

  useEffect(() => {
    if (paymentData) {
      setIsOpenConfirmationModal(true);
      fetchSubscription();
    }
  }, [paymentData]);

  if (isLoading) {
    return <LoaderOverlay />;
  }

  return (
    <div className={classes.wrapper}>
      {isOpenConfirmationModal && <RedirectConfirmation onClose={toggleConfirmationModal} paymentData={paymentData} />}
      {loading ? (
        <LoaderOverlay customStyle={classes.loaderWrapper} />
      ) : (
        <>
          <div className={classes.header}>{t('subscription.title')}</div>
          <div className={classes.content}>
            {renderContent()}

            {showForm && !paymentData && (
              <PurchaseSubscriptionForm onClose={toggleForm} onPaymentDataChange={paymentDataChangeHandler} />
            )}
            {error && <div className={classes.error}>{t('subscription.error')}</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default Subscription;
