export const PAID_BY_ENUM = {
  BLIK: 'blik',
  CARD: 'card',
  BANK_ACCOUNT: 'bank_account',
};

export const SUBSCRIPTION_STATUS_ENUM = {
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
};
