import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useAuth } from '../../../../../shared/helpers/auth';
import { handleProfileImages } from '../../../views/Login/utils';
import { handleApiError } from '../ChangePassword/utils';
import { ReactComponent as ImagePlaceholder } from '../../../../../shared/assets/image.svg';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';

const RelatedItem = ({ subscription }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfiles } = useAuth();
  const businessProfile = businessProfiles.find((profile) => profile.id === subscription.businessProfileId);

  const [isLoading, setIsLoading] = useState(false);
  const [businessProfileImage, setBusinessProfileImage] = useState(businessProfile?.image);

  const renderProfileImage = () => (businessProfileImage ? (
    <img className={classes.image} src={businessProfileImage} alt="business cover" />
  ) : (
    <ImagePlaceholder className={clsx(classes.businessProfileImage, classes.noImage)} />
  ));

  const businessProfileImageExist = businessProfile?.image || null;
  const businessProfileImageAvailable = Boolean(
    !businessProfileImageExist && businessProfile?.fileDate && businessProfile?.fileName,
  );

  const fetchMissingImage = async (profile, controller) => {
    setIsLoading(true);
    try {
      const data = await handleProfileImages([profile], api, controller);
      setBusinessProfileImage(data[0].image);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (businessProfileImageExist) {
      setBusinessProfileImage(businessProfileImageExist);
      return;
    }
    if (businessProfileImageAvailable) {
      fetchMissingImage(businessProfile);
    }
  }, [businessProfile, businessProfileImageAvailable]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.relatedItemImage}>{renderProfileImage()}</div>
      <div className={classes.relatedItem}>
        <div className={classes.relatedItemTitle}>{businessProfile?.name}</div>
        <div className={classes.relatedItemAddress}>{subscription?.address}</div>
      </div>
    </div>
  );
};

export default RelatedItem;
