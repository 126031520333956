import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Radio from '../../../../../shared/components/Radio';
import { paymentMethodConfig } from './utils';

const PaymentMethodItem = ({
  item,
  label,
  selectedOffer,
  onOfferSelect,
  currentSubsription,
  isRequestedPlanChange,
}) => {
  const { t } = useTranslation();
  const { type, title, popular, price } = item;
  const generatePriceTagAndSubInfo = useCallback(() => {
    const titleWithLowerCase = title.toLowerCase();
    return paymentMethodConfig[titleWithLowerCase][type];
  }, [title, type]);

  const isSelected = (selectedOffer?.type === type && selectedOffer?.title === title.toLowerCase()) || currentSubsription === title.toLowerCase();
  const details = useMemo(generatePriceTagAndSubInfo, [generatePriceTagAndSubInfo]);

  const handleClick = useCallback(() => {
    onOfferSelect(item);
  }, [type]);

  return (
    <div
      className={clsx(
        classes.paymentMethodItem,
        popular && classes.popularWrapper,
        isSelected && classes.selectedOfferBackground,
        isRequestedPlanChange && currentSubsription === title.toLowerCase() && classes.change,
      )}
      onClick={handleClick}
    >
      <Radio value={isSelected} className={clsx(classes.radio, !popular && classes.blueRadio)} primary={!popular} />
      <div className={classes.label}>
        <Trans i18nKey={label} components={{ bold: <b /> }} />
      </div>
      <div className={classes.priceTag}>
        {`${details.price} `}
        <span>{t(details.priceTag, { price })}</span>
        <div className={classes.subInfo}>{t(details.subInfo)}</div>
      </div>
    </div>
  );
};

export default React.memo(PaymentMethodItem);
