import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import classes from './styles.module.css';
import { paymentTypeLabel } from './utils';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/constants';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

const { recurring } = paymentMethodEnum;

const SubscriptionSummary = ({ subscriptionBuyDate, subscriptionDate, paymentType = '', subscriptionStatus }) => {
  const { t } = useTranslation();

  const ACTIVATION_DATE = t('subscription.activationDate');
  const isTrial = !paymentType?.length;
  const isReccuring = paymentType === recurring;
  const isCanceled = subscriptionStatus === 'CANCELED';
  const EXPIRE_DATE = (isReccuring && !isCanceled) ? t('settings.nextPaymentDate') : t('subscription.expireDate');

  return (
    <div className={clsx(classes.subscriptionDetails, isTrial && classes.twoColumns)}>
      {!isTrial && (
        <div className={classes.value}>
          {t('subscription.paymentMethod')}
          <span>{t(paymentTypeLabel[paymentType] || '')}</span>
        </div>
      )}
      {subscriptionBuyDate && (
        <div className={classes.value}>
          {ACTIVATION_DATE}
          <span>{dayjs(subscriptionBuyDate).format(DATE_FORMAT_DD_MM_YYYY)}</span>
        </div>
      )}
      <div className={classes.value}>
        {EXPIRE_DATE}
        <span>{dayjs(subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY)}</span>
      </div>
    </div>
  );
};

export default SubscriptionSummary;
