export const pl = {
  translation: {
    global: {
      login: 'Login',
      password: 'Hasło',
      signIn: 'Zaloguj się',
      confirmPassword: 'Powtórz hasło',
      next: 'Dalej',
      back: 'Powrót',
      differentPasswords: 'Hasła różnią się od siebie',
      error: 'Błąd',
      errorOccurred: 'Wystąpił błąd',
      unexpectedError: 'Wystąpił nieoczekiwany błąd',
      company: 'Firma',
      add: 'Dodaj',
      nextStep: 'Następny krok',
      requiredField: 'To pole jest wymagane',
      showMore: 'Zobacz więcej',
      showLess: 'Zobacz mniej',
      cancel: 'Anuluj',
      save: 'Zapisz',
      edit: 'Edytuj',
      delete: 'Usuń',
      success: 'Sukces',
      goBack: 'Wróć',
      confirm: 'Zatwierdź',
      download: 'Pobierz',
      saveWithSuccess: 'Zapisano pomyślnie',
      reset: 'Resetuj',
      resign: 'Rezygnuj',
      yes: 'Tak',
      no: 'Nie',
      videoNotSupported: 'Twoja przeglądarka nie obsługuje odtwarzania wideo',
      editAndChoose: 'Edytuj i użyj',
      noData: 'Brak danych',
      learnMore: 'Dowiedz się więcej',
      search: 'Szukaj',
      fetchFailed: 'Błąd pobierania danych',
      email: 'Email',
      restorePassword: 'Odzyskaj hasło',
      passEmail: 'Wpisz email, a następnie podaj nowe hasło',
      passEmailShort: 'Podaj email',
      goAhead: 'Przejdź dalej',
      logout: 'Wyloguj się',
      settings: 'Ustawienia',
      fullName: 'Imię i nazwisko',
      date: 'Data',
      chooseDate: 'Wybierz datę',
      filters: 'Filtry',
      signUp: 'Załóż konto',
      clear: 'Wyczyść',
      export: 'Eksportuj',
      noAccessSubTitle: 'Twój plan subskrypcji nie obejmuje dostępu do tej funkcji.',
      noAccessSubInformation: 'Dokonaj zmiany, aby mieć dostęp do tej funkcjonalności',
      noGoogleAuthorization: 'Brak autoryzacji Google',
      noGoogleAuthorizationSubTitle:
        'Aby korzystać z tej aplikacji, musisz nadać ponownie uprawnienia Google, przycisk poniżej przekieruje Cię do strony autoryzacji Google.',
      authorizationBtnLabel: 'Autoryzuj Google',
      deleteAccBtn: 'Usuń konto z aplikacji',
      deleteAccTitle: 'Potwierdź usunięcie konta',
      manage: 'Zarządzaj',
      close: 'Zamknij',
      warning: 'Uwaga',
      change: 'Zmień',
      changeData: 'Zmień dane',
    },
    login: {
      welcomeBack: 'Witaj z powrotem',
      login: 'Login',
      password: 'Hasło',
      signIn: 'Zaloguj się',
      incorrectData: 'Nieprawidłowe dane logowania',
      subInfo: 'Login to Twój adres email, pod który jest podpięta wizytówka Google',
      showPassword: 'Pokaż hasło',
      goBack: 'Wróć',
      remindPassword: 'Przypomnij hasło',
    },
    welcome: {
      header: 'Czy masz już konto \nw systemie SeoTracking?',
      nameLogin: 'Mam już konto',
      nameRegister: 'Zarejestruj się',
      textLogin: 'Mam już konto, chcę się zalogować i sprawdzić pozycjonowanie moich wizytówek',
      textRegister: 'Nie mam jeszcze konta, chcę założyć konto i sprawdzić pozycjonowanie moich wizytówek',
    },
    generateReport: {
      header: 'Wypełnij filtry',
      subHeader: 'Aby wygenerować raport wypełnij pola wyszukiwarki.',
      generateReport: 'Generuj raport',
      addKeyWords: 'Dodaj słowo kluczowe',
      yourCompany: 'Twoja firma',
      keywords: 'Słowo kluczowe',
      searchArea: 'Obszar wyszukiwania',
      distance: 'Odległość między punktami',
      net: 'Wielkość siatki',
      selectCompany: 'Wybierz firmę',
      selectKeyword: 'Wybierz słowo kluczowe',
      selectGridSize: 'Wybierz wielkość siatki',
      pointSearchArea: 'Obszar wyszukiwania punktu',
      pointsDistance: 'Odległość między punktami',
      businessCardNotFound: 'Wizytówka nie znaleziona w danym punkcie',
      competition: 'Konkurencja',
      nothingToSelect: 'Brak opcji do wyboru',
      positionGeneral: 'Pozycja  #{{ position }}',
      positionInPoint: 'W punkcie #{{ position }}',
      companyVisibility: 'Widoczność firmy:',
      showSuggestedKeywords: 'Zobacz proponowane słowa kluczowe',
      maxKeywords:
        'Maksymalna ilość słów kluczowych dla wizytówki wynosi 10, wybierz z dostępnych lub usuń niechciane.',
      addKeywords: 'Dodaj słowa kluczowe',
      yourReport: 'Twój raport',
      yourFilters: 'Wybrane filtry',
      changeFilters: 'Zmień filtry',
      generateNewReport: 'Wygeneruj nowy raport',
      noConcurency: 'Brak konkurencji',
      noConcurencySubInfo: 'Dla podanych kryteriów wyszukiwania, nie znaleziono konkurencji w punkcie.',
      showFilters: 'Zobacz użyte filtry',
      generatingReport: 'Generujemy Twój raport',
      downloadingReport: 'Pobieramy Twój raport',
      showFiltersDefinition: 'Zobacz definicję filtrów',
      address: 'Adres',
    },
    setPassword: {
      title: 'Załóż konto',
      password: 'Hasło',
      confirmPassword: 'Powtórz hasło',
      next: 'Dalej',
      back: 'Powrót',
      signUp: 'Załóż konto',
      showPassword: 'Pokaż hasło',
      noPassword: 'Użyj min. 8 znaków, w tym:  litery i znaku specjalnego',
      passwordRequired: 'Hasło wymagane',
      passwordMustBeAtLeast8CharactersLong: 'Hasło musi mieć min. 8 znaków',
      passwordMustContainOneCapitalLetter: 'Hasło musi zawierać jedną wielką literę',
      passwordIsOk: 'Hasło spełnia wymagania',
      passwordsAreDifferent: 'Hasła różnią się od siebie',
      passwordsAreOk: 'Hasła spełniają wymagania',
      resetPassword: 'Utwórz nowe hasło',
      passwordChanged: 'Hasło zostało zmienione',
    },
    setFilters: {
      generateReport: 'Generuj raport',
      back: 'Powrót',
      selectCompanyAnd: 'Wybierz firmę i',
      toGenerateReport: 'aby wygenerować raport',
      enterKeyword: 'podaj słowo kluczowe',
      selectCompany: 'Wybierz firmę',
      yourBusinessCards: 'Twoje wizytówki ({{ number }})',
      addKeyWords: 'Dodaj słowa kluczowe',
      youCanGenerateReport: 'Raport możesz wygenerować',
      forOne: 'dla jednego',
      keyword: 'słowa kluczowego',
      keywords: 'Słowo kluczowe',
      addKeyWord: 'Dodaj słowo kluczowe',
      selected: 'Wybrano',
      setFilters: 'Ustaw filtry',
      setMapProperties: 'Ustaw właściwości mapy',
      searchArea: 'Obszar wyszukiwania',
      pointsDistance: 'Odległość między punktami',
      gridSize: 'Wielkość siatki',
      suggestedKeyWords: 'Proponowane słowa kluczowe',
      filters: 'Filtry',
      mapProperties: 'Właściwości mapy',
      showDashBoard: 'Przejdź do Twojego puplitu',
      goToReportList: 'Wróć do raportów',
      noBusinessProfile: 'Wybierz wizytówkę aby zobaczyć sugerowane słowa kluczowe',
      whatAreMapProperties: 'Czym są właściwości mapy?',
      addNewKeywords: 'Dodaj nowe słowa kluczowe',
      searchForCompany: 'Wyszukaj firmę',
      typeNameOfCompany: 'Wpisz nazwę firmy',
      yourBusinessCardsTitle: 'Twoje wizytówki',
      BUSINESS_PROFILE_ALREADY_EXISTS: 'Wizytówka już istnieje',
      externalBusinessCardAdded: 'Wizytówka została dodana do Twojej listy',
      fromBest: 'Od najlepszej',
      fromWorst: 'Od najgorszej',
      typeAdress: 'Wpisz adres dla raportu',
      profileNotVerified: 'Wizytówka {{ status }}, nie zostanie ona uwzględniona w raporcie.',
      profileNotVerifiedPast: 'Wizytówka niezweryfikowna,\nnie została uwzględniona w raporcie.',
      keywordsForBusiness: 'Słowa kluczowe dla Twojej firmy',
      selectKeyword: 'Wybierz słowo kluczowe',
      noKeywords: 'Nie znaleziono proponowanych słów kluczowych dla tej wizytówki',
      keywordsAdditionalInfo:
        'Ta sekcja aplikacji pomoże Ci dobrać najbardziej odpowiednie słowa kluczowe, dzięki którym Twoja firma będzie lepiej widoczna w wyszukiwaniach Google w Twoim regionie. Wyświetlamy te propozycje, abyś mógł zobaczyć, jakie frazy najczęściej wpisują Twoi potencjalni klienci. Dzięki temu Twoja wizytówka będzie pojawiać się wyżej w wynikach wyszukiwania, co zwiększy szansę, że klienci wybiorą właśnie Twoją firmę. Dobór odpowiednich słów kluczowych jest kluczowy, by skuteczniej dotrzeć do osób szukających usług lub produktów, które oferujesz.',
      suggestedKeywords: 'Proponowane słowa kluczowe',
      suggestedKeywordsTooltip: 'Słowa kluczowe po których użytkownicy najczęściej szukają Twojej firmy',
      reportsAvailable: 'Wykorzystane raporty {{ count }}/{{ limit }}',
      noReportsAvailable: 'Brak dostępnych raportów',
    },
    reportList: {
      header: 'Raporty',
      headerPill: 'Ilość raportów: {{ count }}',
      subHeader: 'Poniżej znajdziesz Twoje wygenerowane raporty. Są gotowe do pobrania.',
      searchByKeyword: 'Wyszukaj słowo kluczowe',
      filters: 'Filtry',
      reportDate: 'Data raportu',
      keyword: 'Słowo kluczowe',
      position: 'Pozycja',
      grid: 'Siatka',
      searchArea: 'Obszar wyszukiwania',
      pointsDistance: 'Odległość między punktami',
      paginationItems: 'Ilość wierszy:',
      paginationOf: 'z',
      generateReport: 'Generuj nowy raport',
      selectDate: 'Wybierz datę',
      downloadReport: 'Pobierz raport',
      outOfReports: 'Wykorzystano wszystkie dostępne raporty w bieżącym okresie rozliczeniowym',
      leftReportsNumber: 'Pozostało raportów: ',
      reportsCanBeUsedTill: 'Raporty możesz wykorzystać do:',
      reportsLimitWillBeRenewed: 'Limit raportów zostanie odnowiony w dniu:',
    },
    registerMethods: {
      header: 'Połącz się z kontem Google',
      subHeader:
        'Zaloguj się na swoje konto Google, aby automatycznie pobrać wszystkie Twoje wizytówki w Mapach Google.',
      connectWithGoogle: 'Połącz konto Google',
    },
    pickBusinessProfiles: {
      header: 'Wybierz wizytówki, które chcesz zaimportować \nz Twojego konta Google',
      rwdHeader: 'Wybierz wizytówki, które chcesz zaimportować z konta Google',
      selectLabel: 'Grupa lokalizacji',
      selected: 'Wybrano',
      next: 'Dalej',
      back: 'Powrót',
      selectAll: 'Zaznacz wszystko',
      noBusinessCards: 'Brak wizytkówek',
      import: 'Importuj',
      createNewBusinessCard: ' Nie masz jeszcze wizytówki lub chcesz stworzyć nową?',
      create: 'Utwórz wizytówkę',
      selectFirstHundred: 'Wybierz pierwsze 100 wizytówek',
      youCanAttachUpTo: 'Możesz załączyć maksymalnie 100 wizytówek naraz',
    },
    keywordsManagement: {
      header: 'Czego szukają Twoi klienci?',
      add: 'Dodaj',
      company: 'Firma',
      back: 'Powrót',
      nextStep: 'Następny krok',
      emptyList: 'Brak wyników',
      suggestions: 'Proponowane frazy:',
    },
    dashboard: {
      header: 'Witaj ponownie!',
      generateReport: 'Generuj raport',
      dashboard: 'Dashboard',
      dashboardSubtitle: 'Statystyki z dnia {{ date }}',
      reports: 'Raporty',
      editBusinessCard: 'Edycja wizytówki',
      tellUs: 'Powiedz nam co szukasz?',
      posts: 'Wpisy',
      opinions: 'Opinie',
      answerTemplates: 'Szablony odpowiedzi',
      logout: 'Wyloguj',
      settings: 'Ustawienia',
      refreshDashboard: 'Odśwież dashboard',
      viewsNumber: 'Liczba wyświetleń',
      interactionsNumber: 'Liczba interakcji',
      averageRate: 'Średnia ocena',
      postsNumber: 'Liczba wpisów',
      tooltipViewsNumber:
        'Liczba wyświetleń to wskaźnik interakcji z Twoją firmą online. Obejmuje on widoczność Twojej firmy w wynikach wyszukiwania Google, kliknięcia w profil firmy oraz przeglądanie galerii zdjęć. To istotny miernik, który odzwierciedla zainteresowanie użytkowników Twoją firmą i jej treściami online.',
      tooltipInterActionsNumber:
        'Interakcje są naliczane m.in gdy użytkownicy dzwonią lub wysyłają wiadomości do twojej firmy, robią rezerwacje lub proszą o wskazówki dojazdu z poziomy profilu Twojej firmy w Google',
      interactionsToolTipTile: 'Co to są interakcje?',
      viewsToolTipTitle: 'Co to są wyświetlenia?',
      calendarTitle:
        'Możesz wybrać zakres dat tylko do 6 miesięcy wstecz. Możesz wybrać zakres kilku miesięcy lub wybrać pojedyczny miesiąc i zobaczyć wyświetlenia dzienne.',
      showPeriod: 'Wyświetl wykres',
      selectedDates: 'Wybrano zakres dat:',
      noSelectedDates: 'Aby wyświetlić zakres kliknij w dowolną datę',
      viewsChartTitle: 'Wyświetlenia',
      businessCardStatusBoxTitle: 'Status wizytówki',
      businessCardCompletion: 'Stopień uzupełnienia wizytówki',
      verifiedSubInfo: 'Twoja wizytówka jest zweryfikowana. Oznacza to, że możesz z niej korzystać bez ograniczeń',
      basicCompletion: 'Stopień podstawowy',
      locality: 'Adres działalności',
      area: 'Obszar działalności',
      phoneNumber: 'Numer telefonu',
      primaryCategory: 'Kategoria podstawowa',
      middleCompletion: 'Stopień średni',
      openingHours: 'Godziny otwarcia',
      website: 'Strona internetowa',
      openingDate: 'Data rozpoczęcia działalności',
      fullCompletion: 'Stopień pełny',
      businessKeywords: 'Najpopularniejsze słowa kluczowe',
      newestOpinions: 'Najnowsze opinie',
      showMoreKeywords: 'Zobacz więcej słów kluczowych',
      noKeywords: 'Nie znaleziono słów kluczowych dla tej wizytówki',
      impressionsDesktopMaps: 'Wyświetlenia Mapach Google na komputerach',
      mobileSearchMaps: 'Mapy Google – urządzenia mobilne',
      mobileSearch: 'Wyszukiwarka Google – urządzenia mobilne',
      googleBookings: 'Rezerwacje Google',
      googleDirectionRequests: 'Wskazówki dojazdu',
      desktopSearch: 'Wyszukiwarka Google – komputery',
      desktopMap: 'Mapy Google',
      photos: 'Zdjęcia',
      services: 'Usługi',
      description: 'Opis firmy',
      showDetails: 'Zobacz szczegóły',
      basic: 'Podstawowy',
      average: 'Średni',
      full: 'Pełny',
      profileCompletionModal: 'Stopień uzupełnienia profilu firmy',
      howItWorks: 'Jak to działa?',
      howItWorksDescription: 'Opis dlaczego to jest ważne, co to daje itd. Wyjaśnienie - tutaj liczę na pomoc klienta',
      last30Days: 'Ostatnie 30 dni',
      showAllOpinions: 'Zobacz wszystkie opinie',
      keywordTooltip:
        'Jest to zestawienie słów kluczowych jakie zapytania użytkowników doprowadziły do wyświetlenia profilu naszej firmy w wynikach wyszukiwaniach',
      keywordToolTipTitle: 'Co to są słowa kluczowe?',
      refresh: 'Odśwież',
      pickDate: 'Wybierz datę',
      high: 'Wysoki',
      opinionAdded: 'Dodano {{ date }}',
      goToOpinions: 'Przejdź do opinii',
      verificationTitle: 'Wizytówka niezweryfikowana',
      verificationDescription:
        'Dla niezweryfikowanej wizytówki niestety nie możemy wyświetlić dashboardu, zweryfikuj ją aby wyświetlić wszystkie dane',
      duplicateBusiness: 'Duplikat wizytówki',
      duplicateDescription:
        'Wizytówka jest duplikatem innej wizytówki, niestety nie możemy wyświetlić dashboardu, usuń duplikat aby wyświetlić wszystkie dane',
      dashboardRefreshed: 'Dashboard odświeżony pomyślnie',
      verified: 'Zweryfikowana',
      notVerified: 'Niezweryfikowana',
      duplicate: 'Duplikat',
      noProfilesTitle: 'Wygląda na to że nie masz żadnych zaimportowanych wizytówek',
      noProfilesSubtitle:
        'Możesz stworzyć wizytówkę lub zaimportować ją z konta Google. \nWystarczy, że klikniesz w przycisk poniżej.',
      createBusinessProfile: 'Utwórz wizytówkę',
      importBusinessProfile: 'Zaimportuj wizytówkę',
      whatYouLookingFor: 'Powiedz nam czego szukasz',
      noResults: 'Brak wyników',
      goToVerification: 'Przejdź do weryfikacji',
      duplicateSubInfo: 'Twoja wizytówka jest zduplikowana. Kliknij poniżej aby przejść do formularza.',
      suspended: 'Zawieszona',
      suspendedSubInfo: 'Twoja wizytówka jest zawieszona, kliknij poniżej aby przejść do formularza.',
      suspendedForm: 'Formularz przywrócenia wizytówki',
      cannotDisplayFullData: 'Nie możemy wyświetlić pełnych danych ze względu na status wizytówki.',
      notVerifiedSubInfo: 'Twoja wizytówka nie jest zweryfikowana. Kliknij poniżej aby przejść do weryfikacji.',
      noChartData: 'Brak danych do wyświetlenia',
      checkProfileStatus: 'Sprawdź status wizytówki',
      desktop: 'Wyszukiwarka Google',
      mobile: 'Wyszukiwania mobilne',
      mobileMap: 'Mapy Google - mobilne',
      businessDirectionRequests: 'Wskazówki dojazdu',
      dataComparison: 'Porównanie danych',
      callClicks: 'Połączenia telefoniczne',
      websiteClicks: 'Kliknięcia w stronę',
      businessFoodOrders: 'Zamówienia jedzenia',
      businessFoodMenuClicks: 'Kliknięcia w menu jedzenia',
      businessConversations: 'Rozmowy z klientami',
      businessBookings: 'Rezerwacje',
      changeHistory: 'Historia zmian',
      create: 'Dodano',
      update: 'Zaktualizowano',
      delete: 'Usunięto',
      photo: 'Zdjęcie',
      video: 'Wideo',
      post: 'Wpis',
      media: 'Media',
      remaingEvents: 'Pozostałe wydarzenia: {{ event }}',
      businessProfileNotFound: 'Nie znaleziono wizytówki',
      businessProfileNotFoundActionDelete: 'Nie znaleziono wizytówki, zostanie ona usunięta z systemu',
      opinieoCard: 'Opinieo',
      mailCopied: 'Adres email skopiowany do schowka',
      contactUs: 'Kontakt',
      subscription: 'Subskrypcja',
    },
    invoices: {
      addNewData: 'Dodaj nowe dane',
      editData: 'Edytuj dane',
      personalInvocie: 'Osoba fizyczna',
      companyInvoice: 'Firma',
      markAsDefault: 'Ustaw jako domyślny adres do rozliczeń',
      addSecondaryEmail: 'Dodaj dodatkowy adres email (opcjonalnie)',
      providedEmailInvoiceSend: 'Na podany email zostanie wysłana faktura',
      defaultInvoice: 'Domyślny adres do rozliczeń',
      setAsDefaultInvoiceData: 'Ustaw jako domyślny adres do rozliczeń',
      setNewDefaultInvoiceData: 'Ustaw nowy adres jako domyślny',
      setNewDefaultInvoiceDataSubInfo:
        'Czy na pewno chcesz ustawić poniższy adres jako domyślny do rozliczeń za faktury?',
      saveAsDefault: 'Zapisz jako domyślny',
      paymentData: 'Dane do płatności',
      defaultAddress: 'Adres domyślny',
      nameInvoiceWillBeSend: 'Na powyższy adres zostanie wystawiona faktura imienna za płatność.',
      companyInvoiceWillBeSend: 'Na powyższy adres zostanie wystawiona faktura na firmę.',
      editDataInfo: 'Dane zostaną zaktualizowane. Zmiana będzie widoczna na przyszłych fakturach.',
      selectDataForPayment: 'Wybierz adres do płatności.',
      relatedSubscriptions: 'Płatności cykliczne powiązane z tym adresem',
      personalInvoiceType: 'Faktura imienna',
      companyInvoiceType: 'Faktura na firmę',
      popupBlocked: 'Przeglądarka zablokowała wyskakujące okno, link do faktury skopiowany do schowka',
    },
    businessProfileEdit: {
      header: 'Edycja wizytówki',
      nameCompany: 'Nazwa firmy',
      companyDescirption: 'Opis firmy',
      labels: 'Etykiety',
      categories: 'Kategorie',
      phoneNumber: 'Numer telefonu',
      additionalPhoneNumber: 'Dodatkowy numer telefonu',
      edit: 'Edytuj',
      save: 'Zapisz',
      cancel: 'Anuluj',
      companyDescription: 'Opis firmy',
      suggestion: 'Wskazówka',
      country: 'Kraj',
      streetAndNumber: 'Ulica i numer',
      postalCode: 'Kod pocztowy',
      city: 'Miejscowość',
      localization: 'Lokalizacja',
      companyWebsite: 'Strona internetowa',
      readMore: 'Czytaj więcej',
      website: 'Witryna',
      year: 'Rok',
      month: 'Miesiąc',
      day: 'Dzień',
      fetchingMapError: 'Błąd podczas pobierania mapy',
      fetchingCategoriesError: 'Błąd podczas pobierania kategorii',
      from: 'Od',
      to: 'Do',
      closed: 'Zamknięte',
      searchArea: 'Obszar wyszukiwania',
      basicInfo: 'Informacje podstawowe',
      contactDetails: 'Dane kontaktowe',
      phoneNumbers: 'Numery telefonów',
      localizationAndArea: 'Lokalizacja i obszar',
      areaServed: 'Obsługiwany obszar',
      openingDate: 'Data otwarcia',
      openingHours: 'Godziny otwarcia',
      addService: 'Dodaj usługę',
      addServices: 'Dodaj usługi',
      addAdditionalCategory: 'Dodaj kategorię dodatkową',
      saveAll: 'Zapisz wszystko',
      addCategory: 'Dodaj kategorię',
      informationTitle: 'Wybierz kategorię działalności. Następnie możesz dodać usługi do wybranej kategorii.',
      editServiceDetail: 'Edytuj szczegóły usługi',
      editService: 'Edytuj usługę',
      service: 'Usługa',
      goBackToEditBusinessProfile: 'Wróć do edycji wizytówki',
      price: 'Cena',
      enterPriceForService: 'Podaj cenę usługi (PLN)',
      addServiceDescription: 'Dodaj opis usługi',
      confirmAndClose: 'Zatwierdź',
      fetchingServicesError: 'Błąd pobierania usług',
      enterBusinessCategory: 'Wpisz kategorię działalności',
      additionalCategory: 'Kategoria dodatkowa',
      addOfferedServices: 'Dodaj oferowane usługi i zacznij docierać do klientów',
      noServicesForCategory:
        'Dla wybranej kategorii nie istnieją standardowe usługi do wybrania, dodaj usługę niestandardową.',
      customService: 'Usługa niestandardowa',
      customServicesPlural: 'Usługi niestandardowe',
      addCustomService: 'Dodaj usługę niestandardową',
      noOfferedServiceAddNew: 'Nie widzisz usługi jaką oferujesz? Utwórz własną poniżej.',
      manageServices: 'Zarządzaj usługami',
      saveServices: 'Zapisz usługi',
      services: 'Usługi',
      addNewCategory: 'Dodaj kolejną kategorię',
      addNewPhoneNumberDisabled: 'Aby dodać numer telefonu kliknij przycisk “Edytuj”',
      addNewPhoneNumberEnabled: 'Dodaj dodatkowy numer telefonu',
      selectedServiceAreas: 'Wybrane obsługiwane obszary',
      inOrderToDelete: 'Aby usunąć kliknij przycisk “Edytuj”',
      areaAlreadyExist: 'Wyszukany obszar już widnieje na liście',
      fetchingAreasError: 'Błąd pobierania obszarów',
      wrongRange: 'Błędny zakres',
      addHourToolTip: 'Aby dodać godzinę, kliknij “Edytuj” na górze sekcji',
      removeHourToolTip: 'Aby usunąć godzinę, kliknij “Edytuj” na górze sekcji',
      primaryCategory: 'Kategoria podstawowa',
      addCategoryToolTip: 'Aby dodać kategorię, kliknij “Edytuj” na górze sekcji',
      removeCategoryToolTip: 'Aby usunąć kategorię, kliknij “Edytuj” na górze sekcji',
      addServiceToolTip: 'Aby dodać usługę, kliknij “Edytuj” na karcie kategorii',
      addFirstService: 'Dodaj pierwszą w usługę w tej kategorii',
      serviceAlreadyExist: 'Usługa o takiej nazwie już istnieje, zmień nazwę.',
      servicesSaved: 'Zmiany zapisano pomyślnie',
      deleteService: 'Usuń usługę',
      inOrderToEditService: 'Aby edytować usługę, kliknij przycisk “Edytuj” przy nazwie kategorii',
      additionalHours: 'Dodatkowe godziny',
      additionalHoursSubTitle: 'Dodaj więcej godzin (opcjonalnie)',
      allDay: 'Cały dzień',
      inOrderToEdit: 'Aby edytować, kliknij przycisk “Edytuj” \nw sekcji "Dodatkowe godziny"',
      inOrderToSelect: 'Aby wybrać, kliknij przycisk “Edytuj” \nw sekcji "Dodatkowe godziny"',
      maxHours: 'Osiągnięto maksymalną ilość godzin dla danego dnia',
      inOrderToChange: 'Aby zmienić, kliknij przycisk “Edytuj” \nw sekcji przy nazwie kategorii',
      photos: 'Zdjęcia',
      fetchingMediaError: 'Nie udało się zaktualizować zdjęć',
      deleteMediaError: 'Nie udało się usunąć zdjęcia',
      deleteMediaSuccess: 'Zdjęcie zostało usunięte',
      mediaEmptyStateSubtitle: 'Dodaj media, aby wyróżnić się na tle konkurencji',
      mediaEmptyStateTitle: 'Brak mediów do wyświetlenia',
      addPhotos: 'Dodaj zdjęcia',
      profileImage: 'Logo',
      additionalImages: 'Zdjęcie',
      photoType: 'Typ zdjęcia',
      mediaFetchingMediaError: 'Błąd podczas pobierania zdjęć.',
      mediaDeleteSuccess: 'Zdjęcie zostało usunięte.',
      mediaDeleteMediaError: 'Błąd podczas usuwania zdjęcia.',
      mediaHelperText: 'Aby dodać zdjęcia, musisz określić ich typ.',
      mediaAddError: 'Błąd podczas dodawania zdjęcia.',
      selectFew: 'Wybierz kilka',
      deleteSelectedMediaConfirmation: 'Czy chcesz usunąć zaznaczone zdjęcia?',
      deleteSelectedMediaSubInformation:
        'Usunięcie tej treści spowoduje, że nie będzie ona dostępna dla użytkowników. Czy na pewno chcesz kontynuować?',
      coverImageAspectRatioError: 'Zdjęcie główne musi mieć proporcje 16:9',
      coverImage: 'Zdjęcie główne',
      menuImage: 'Zdjęcie menu',
      addLabel: 'Dodaj etykietę',
      inOrderToAddLabel: 'Aby dodać etykietę, kliknij przycisk “Edytuj” \nw sekcji "Etykiety"',
      tooManyLabels: 'Maksymalna ilość etykiet to 10',
      labelsSaved: 'Etykiety zapisano pomyślnie',
      labelAlreadyExist: 'Etykieta o takiej nazwie już istnieje, etykiety powinny być unikalne',
      media: 'Media',
      video: 'Wideo',
      addMedia: 'Dodaj media',
      publication: 'Publikacja: {{ date }}',
      deleteSelected: 'Usuń zaznaczone',
      clear: 'Wyczyść',
      editMedia: 'Edytuj media',
      postponedMediaDataChange: 'Data publikacji zmieniona pomyślnie',
      mediaDeleteMediaSuccess: 'Media zostały usunięte',
      learnMoreAboutMedia: 'Dowiedz się więcej o zdjęciach i wideo',
      verificationStatus: 'Status weryfikacji',
      postoponedMediaMustBeAfterToday: 'Data publikacji musi być późniejsza niż dzisiaj',
    },
    posts: {
      addPosts: 'Dodaj wpis',
      yourPosts: 'Twoje wpisy',
      addFirstPost: 'Dodaj pierwszy wpis',
      addFirstPlannedPost: 'Dodaj pierwszy zaplanowany wpis',
      postsNumber: 'Ilość wpisów: {{ number }}',
      additionalInformation: 'Aby dodać pierwszy wpis, kliknij w przycisk poniżej',
      addPost: 'Dodaj wpis',
      editPost: 'Edytuj wpis',
      addUpdate: 'Dodaj aktualizację',
      addStandard: 'Dodaj aktualizację',
      updateStandard: 'Edytuj aktualizację',
      addUpdateContent: 'Wpisz treść aktualizacji',
      updateUpdate: 'Edytuj aktualizację',
      addOffer: 'Dodaj ofertę',
      updateOffer: 'Edytuj ofertę',
      addEvent: 'Dodaj wydarzenie',
      updateEvent: 'Edytuj wydarzenie',
      addUpdateDescription: 'Podziel się aktualnościami z klientami w Google',
      addOfferDescription: 'Utwórz ofertę i przyciągnij klientów do swojej firmy',
      addEventDescription: 'Poinformuj klientów o wydarzeniach, które organizujesz',
      yourEntries: 'Informuj klientów na bieżąco o tym co się dzieje w Twojej firmie.',
      addPhotos: 'Dodaj zdjęcia',
      addOptionalButton: 'Dodaj przycisk (opcjonalnie)',
      addCompanyDescription: 'Dodaj opis firmy',
      makeAppointment: 'Umów się na spotkanie',
      order: 'Zamów',
      checkOffer: 'Sprawdź ofertę',
      learnMore: 'Dowiedz się więcej',
      signUp: 'Zarejestruj się',
      call: 'Zadzwoń',
      addOrderLink: 'Dodaj link do zamówienia',
      addOfferLink: 'Dodaj link do oferty',
      addLink: 'Dodaj link',
      addSignUpLink: 'Dodaj link do rejestracji',
      addPhoneNumber: 'Dodaj numer telefonu',
      offerDetails: 'Szczegóły oferty',
      offerTitle: 'Nazwa oferty',
      startDate: 'Data rozpoczęcia',
      endDate: 'Data zakończenia',
      addMoreDetails: 'Dodaj więcej informacji (opcjonalnie)',
      termsOfferOptional: 'Warunki oferty (opcjonalnie)',
      termsOffer: 'Warunki oferty',
      offerDetailsOptional: 'Szczegóły oferty (opcjonalnie)',
      addCouponCode: 'Dodaj kod kuponu (opcjonalnie)',
      addOfferLinkOptional: 'Dodaj link do oferty (opcjonalnie)',
      eventTitle: 'Nazwa wydarzenia',
      eventDateAndHour: 'Data i godzina',
      fromHour: 'Od (opcjonalnie)',
      toHour: 'Do (opcjonalnie)',
      lastPosts: 'Ostatnie wpisy',
      newest: 'Najnowsze',
      oldest: 'Najstarsze',
      seeMore: 'Zobacz więcej',
      callNow: 'Zadzwoń teraz',
      deletePostSuccess: 'Wpis został usunięty',
      addPhotosSupportingText: 'Lub przeciągnij i upuść',
      addPhoto: 'Dodaj zdjęcie',
      postsCount: 'Liczba wpisów: {{ count }}',
      noButton: 'Brak przycisku',
      phoneNumberMoreInfo: 'Pod tym numerem klienci będą się z Tobą kontaktować',
      signUpMoreInfo: 'Pod tym linkiem klienci będą mogli się zarejestrować',
      makeAppointmentMoreInfo: 'Pod tym linkiem klienci będą mogli umówić się na spotkanie',
      orderMoreInfo: 'Pod tym linkiem klienci będą mogli złożyć zamówienie',
      checkOfferMoreInfo: 'Pod tym linkiem klienci będą mogli sprawdzić ofertę',
      learnMoreMoreInfo: 'Pod tym linkiem klienci będą mogli dowiedzieć się więcej',
      makeAppointmentLink: 'Dodaj link do umówienia się na spotkanie',
      previewUpdate: 'Podgląd aktualizacji',
      previewOffer: 'Podgląd oferty',
      previewEvent: 'Podgląd wydarzenia',
      deletePost: 'Usuń wpis',
      deletePostConfirmation: 'Czy na pewno chcesz usunąć ten wpis?',
      delete: 'Usuń',
      previewSubInfo: 'W tym miejscu możesz zobaczyć jak Twoi klienci, zobaczą wpis, który dodasz.',
      showPreview: 'Zobacz podgląd',
      learnMoreAboutPhoto: 'Zdjęcie nie może przekraczać rozmiaru 5MB. Zalecany format to JPG lub PNG.',
      filesWithWrongFormat: 'Pliki o nieprawidłowym formacie: {{ files }}',
      fileExtensionError: 'Pliki o nieprawidłowym formacie',
      fileSizeError: 'Plik jest za duży',
      fileResolutionError: 'Plik nie spełnia wymagań rozdzielczości',
      showFullContent: 'Zobacz pełną treść',
      startDateBeforeEndDate: 'Data rozpoczęcia musi być przed datą zakończenia',
      startDateAfterToday: 'Data rozpoczęcia musi być po dzisiejszej dacie',
      endDateAfterToday: 'Data zakończenia musi być po dzisiejszej dacie',
      endDateBeforeStartDate: 'Data zakończenia musi być równa lub późniejsza niż data rozpoczęcia',
      passStartTime: 'Podaj godzinę rozpoczęcia',
      passEndTime: 'Podaj godzinę zakończenia',
      endDateAfterYear: 'Data zakończenia nie może być późniejsza niż rok od dzisiaj',
      startAndEndBeforeToday: 'Data rozpoczęcia lub data zakończenia jest wcześniejsza niż dzisiaj.',
      eventDetails: 'Szczegóły wydarzenia',
      offerValid: 'Oferta ważna od {{ startDate }} do {{ endDate }}',
      oneDayOfferValid: 'Oferta ważna {{ offerDate }}',
      endDateBeforeToday: 'Data zakończenia musi być równa lub późniejsza niż dzisiejsza data',
      oneDayEventValid: 'Wydarzenie ważne {{ eventDate }}',
      showCouponCode: 'Pokaż ten kod w naszej placówce',
      redeemOnline: 'Zobacz link do oferty',
      postAdded: 'Dodano: {{ date }}',
      viewOnPostGoogle: 'Zobacz w Google',
      postsReached: ' Posiadasz już 100 wpisów, wyświetlamy jedynie sto najnowszych wpisów',
      live: 'Dodano',
      rejected: 'Odrzucono',
      processing: 'Przetwarzanie',
      postHasBeenAdded: 'Post został dodany',
      someProblemWithImageSending: 'Podczas przesyłania zdjęcia wystąpił problem.',
      eventDescription: 'Opis wydarzenia (opcjonalnie)',
      profileNotVerified: 'Twoja wizytówka jest oznaczona jako {{ status }} \nwpisy nie wyświetlą się w Google',
      publicationDate: 'Data publikacji',
      publicationDateOptional: 'Data publikacji (opcjonalnie)',
      publicationTimeOptional: 'Godzina publikacji (opcjonalnie)',
      published: 'Opublikowane',
      planned: 'Zaplanowane',
      plannedDateRelease: 'Planowana data publikacji: {{ date }}',
      cannotAddOffer: 'Ta wizytówka nie ma możliwości dodania tego typu wpisu',
      postsHasBeenChanged: 'Wpis został edytowany',
      plannedDateAfterOfferSchedule: 'Data publikacji nie może być późniejsza niż data zakończenia.',
      needHelpAi: 'Skorzystaj z AI',
      ai: 'AI',
      selectKeywords:
        'Użyj w treści słów kluczowych po których klienci szukają Twojej firmy lub dodaj własne propozycje',
      addKeyword: 'Dodaj słowo kluczowe',
      addMoreAIDetails: 'Napisz czego treść ma dotyczyć - opcjonalnie',
      generateContent: 'Generuj treść',
      aiHelper: 'Wskaż, co chcesz zawrzeć w treści, a wygeneruję dla Ciebie propozycję',
      selectKeywordsNotVerified: 'Wpisz ważne słowa kluczowe dla Twojej firmy, aby wygenerować treść',
      selectModel: 'Wybierz model',
      publicationDateAfterEndDate: 'Data publikacji nie może być późniejsza niż data zakończenia',
    },
    opinions: {
      allOpinions: 'Wszystkie opinie',
      opinionsWithAnswers: 'Z odpowiedzią',
      opinionsWithoutAnswers: 'Bez odpowiedzi',
      getMoreOpinions: 'Uzyskaj więcej opinii',
      customerOpinions: 'Opinie klientów',
      opinionsPlural: 'opinii',
      newest: 'Najnowsze',
      oldest: 'Najstarsze',
      bestRating: 'Najlepsza ocena',
      worstRating: 'Najgorsza ocena',
      noOpinionsYet: 'Nie masz jeszcze żadnych opinii',
      shareLink:
        'Udostępnij link klientom aby mogli dodać opinię. By to zrobić kliknij przycisk “Uzyskaj więcej opinii ”',
      answerTemplates: 'Szablony odpowiedzi',
      answersTemplatesSubHeading:
        'Możesz dodawać szablony odpowiedzi na dodawane opinie. To znacznie przyśpieszy Twoją pracę.',
      noTemplateYet: 'Nie masz jeszcze żadnego szablonu',
      addFirstTemplate: 'Aby dodać pierwszy szablon, kliknij w przycisk poniżej',
      createTemplate: 'Utwórz szablon',
      added: 'Dodano: {{ date }}',
      reportOpinion: 'Zgłoś opinię',
      reply: 'Odpowiedz',
      replyWithTemplate: 'Odpowiedz z szablonu',
      positions: '{{ number }} pozycji',
      addPublicReply: 'Dodaj publiczną odpowiedź',
      cancel: 'Anuluj',
      submitReply: 'Dodaj odpowiedź',
      answerWithTemplate: 'Odpowiedz z szablonem',
      askForOpinionVia: 'Poproś o opinię przez:',
      shareLinkModal:
        'Udostępniaj klientom link, by ułatwić im napisanie opinii o Twojej firmie w Google. Opinie pomagają budować zaufanie i dzięki nim profil Twojej firmy może wyróżnić się w wyszukiwarce i Mapach.',
      shareLinkModalSubInfo:
        'Profile firm podobnych do Twojej, które mają co najmniej 5 opinii, przyciągają nawet 2 razy więcej klientów',
      linkToOpinion: 'Link do opinii',
      learnMore: 'Dowiedz się więcej',
      noTemplatesYetPlural: 'Nie masz jeszcze żadnego szablonu',
      noTemplatesYetModal:
        'Aby to zrobić przejdź do zakładki “Szablony odpowiedzi” w nawigacji, lub kliknij przycisk poniżej',
      goToTemplates: 'Przejdź do szablonów',
      templatesNumber: 'Liczba szablonów: {{ number }}',
      editTemplate: 'Edytuj szablon',
      addTemplate: 'Dodaj szablon',
      useTemplate: 'Użyj szablonu',
      editPublicReply: 'Edytuj publiczną odpowiedź',
      businessProfileNoAuth: 'Twoja wizytówka nie jest autoryzowana',
      businessProfileNoAuthSub: 'Autoryzuj wizytówkę, aby móc wyświetlać opinie klientów',
      editReply: 'Edytuj odpowiedź',
      opinionsNumber: 'Liczba opinii: {{ number }}',
      howItWorks: 'Jak to działa?',
      submit: 'Dodaj',
      linkCopied: 'Skopiowano link do schowka',
      savedCorrectly: 'Zapisano pomyślnie, zmiany będą widoczne do 24h.',
      deleteTemplate: 'Usuń szablon',
      enterTemplateContent: 'Wpisz treść szablonu',
      new: 'NOWA',
      success: 'Zapisano pomyślnie',
      copyError: 'Nie udało się skopiować linku, brak wsparcia przeglądarki.',
      answerTemplatesLimitReached: 'Posiadasz już 10 szablonów, usuń lub edytuj istniejące',
      businessProfileDuplicate: 'Wizytówka jest duplikatem',
      businessProfileDuplicateSub: 'Usuń duplikat aby wyświetlić wszystkie dane',
      selectedAnswerSuccess: 'Odpowiedzi zapisano pomyślnie',
      replyWithAI: 'Odpowiedz z AI',
      generatedWithAiProposition: 'Wygenerowaliśmy za pomocą AI propozycje',
    },
    validators: {
      requiredField: 'Pole wymagane',
      requiredZipCodeFormat: 'Wymagany format 00-000',
      incorrectAmount: 'Nieprawidłowa kwota',
      incorrectValue: 'Nieprawidłowa wartość',
      valueMustBePositive: 'Wartość musi być dodatnia',
      valueTooBig: 'Wartość jest zbyt duża',
      incorrectFormat: 'Nieprawidłowy format',
      incorrectWebsiteFormat: 'Nieprawidłowy format adresu strony',
      passwordRequired: 'Hasło wymagane',
      passwordMustBeAtLeast8CharactersLong: 'Hasło musi zawierać minimum 8 znaków',
      passwordMustContainOneDigitAndSpecialCharacter: 'Hasło musi zawierać minimum jedną cyfrę i jeden znak specjalny',
      passwordMustContainOneCapitalLetter: 'Hasło musi zawierać minimum jedną wielką literę',
      incorrectCityName: 'Nieprawidłowa nazwa miasta',
      expectedNumber: 'Oczekiwano liczby',
      expectedYearFormat: 'Wymagany format YYYY',
      incorrectEmailFormat: 'Nieprawidłowy format adresu email',
      incorrectStreetFormat: 'Nieprawidłowy adres',
      urlWarning: 'W opisie firmy nie powinno być linków do stron internetowych',
      incorrectDay: 'Nieprawidłowa data',
      passwordMustBeLessThan20CharactersLong: 'Hasło musi zawierać maksymalnie 20 znaków',
      updateDescriptionRequired: 'Treść aktualizacji jest wymagana',
      updateDescriptionTooLong: 'Maksymalna liczba znaków to 1500',
      plannedPostDateError: 'Data publikacji nie może być wcześniejsza niż dzisiejsza',
      noFutureDate: 'Data nie może być późniejsza niż dzisiejsza',
      alphabeticalCharactersOnly: 'Dozwolone są jedynie znaki alfabetyczne',
      incorrectNipFormat: 'Nieprawidłowy format NIP',
      minLength: 'Minimalna długość to {{ minLength }}',
      alphanumericCharactersOnly: 'Dozwolone są jedynie znaki alfanumeryczne',
      urlPrefixRequired: 'Adres nie zawiera protokołu',
    },
    form: {
      name: 'Imię',
      lastName: 'Nazwisko',
      email: 'Email',
      phoneNumber: 'Numer telefonu',
      phoneOptional: 'Numer telefonu (opcjonalnie)',
      country: 'Kraj',
      voivodeship: 'Województwo',
      locality: 'Miejscowość',
      address: 'Adres',
      postalCode: 'Kod pocztowy',
      nip: 'NIP',
      companyName: 'Nazwa firmy',
      wantInvoice: 'Chcę otrzymać fakturę',
      additionalEmail: 'Dodatkowy adres email',
      saveAddress: 'Zapisz adres',
    },
    modalNaviagtion: {
      goBackToEditBusinessProfile: 'Wróć do edycji wizytówki',
      goToPreviousStep: 'Przejdź do poprzedniego kroku',
    },
    calendar: {
      lastWeek: 'Ostatni tydzień',
      last14days: 'Ostatnie 14 dni',
      lastMonth: 'Ostatnie 30 dni',
      sinceCreate: 'Od utworzenia',
      last2Months: 'Ostatnie 2 miesiące',
      last3Months: 'Ostatnie 3 miesiące',
      last6Months: 'Ostatnie pół roku',
      lastYear: 'Ostatni rok',
    },
    businessProfileCreate: {
      title: 'Utwórz nową wizytówkę',
      subTitle:
        'W naszym systemie możesz założyć Profil Firmy.\nW łatwy i szybki sposób załóż wizytówkę. Podając\nwszystkie informacje klienci łatwiej Cię znajdą. ',
      createBusinessProfile: 'Stwórz wizytówkę',
      goBackToDashBoard: 'Wróć do dashboardu',
      inNextSteps: 'W kolejnych krokach będziemy prosili Cię o podanie\npodstawowych informacji o Twojej firmie.',
      stepperTitle: 'Utwórz nową wizytówkę',
      basicInformation: 'Podstawowe dane',
      locationAndArea: 'Lokalizacja i obszar',
      contactDetails: 'Dane kontaktowe',
      verification: 'Weryfikacja',
      basicInformationTitle: 'Podaj nazwę swojej firmy oraz podstawową kategorię',
      allFieldsRequired: 'Wszystkie pola są wymagane',
      resignToCreateBusinessProfile: 'Zrezygnuj z tworzenia wizytówki',
      businessName: 'Nazwa firmy',
      resignToCreateBusinessProfileSubinfo: 'Czy na pewno chcesz zrezygnować z tworzenia tej wizytówki?',
      primaryCategory: 'Kategoria podstawowa',
      locationFormTitle: 'Czy chcesz podać lokalizację swojej firmy?',
      locationFormSubtitle: 'Lokalizacja będzie wyświetlać się klientom na Google Maps oraz w wyszukiwarce Google',
      passLocationData: 'Podaj lokalizacje firmy',
      passLocationDataSubinfo: 'Pod tym adresem klienci będą mogli odwiedzić Cię osobiście',
      passServiceArea: 'Gdzie obsługujesz swoich klientów?',
      passServiceAreaSubinfo:
        'Podaj obszar, w którym realizujesz dostawy oraz przyjmujesz klientów. Takie informacje pojawią się na Twoim profilu biznesowym w Google',
      optionalInformationServiceArea: 'Te pole jest opcjonalne. Możesz je wypełnić później',
      whatIsYourCountry: 'W jakim kraju znajduje się Twój biznes?',
      selectCountry: 'Wybierz kraj',
      contactFormTitle: 'Podaj swoje dane kontaktowe, które chcesz pokazać klientom',
      optionalInformationPlural: 'Pola są opcjonalne. Możesz je wypełnić później',
      countryOrRegion: 'Kraj/region',
      doYouHaveDelivery: 'Czy posiadasz opcje dostawy lub wizyt w domu/biurze klienta?',
      doYouHaveDeliverySubInfo:
        'Jeśli np. posiadasz opcje dostawy lub wizyt domowych możesz dodać informację w jakich rejonach świadczona jest usługa',
      optionalContactInformation: 'Pola są opcjonalne. Możesz je wypełnić później.',
      goBackToDashboard: 'Wróć do dashboardu',
      goBackOneStep: 'Wróć do poprzedniego kroku',
      step: 'Krok',
      importExistingBusinessProfile: 'Importuj istniejącą wizytówkę',
      youCanImportExistingOne: 'Możesz też zaimportować istniejącą wizytówkę tutaj.',
      submit: 'Wyślij',
      youHaveProvideAtLeastOneFormOfContact: 'Musisz podać przynajmniej jeden sposób kontaktu',
      cannotFindThisAddress: 'Nie możemy zlokalizować podanego adresu, sprawdź jego poprawność',
      verificationTitle: 'Wizytówka założona pomyślnie',
      verificationDescription: 'Teraz zweryfikuj ją na Google, aby móc w pełni korzystać z jej funkcjonalności.',
      checkingAddress: 'Sprawdzanie adresu, proszę czekać...',
      verificationOnGoogle: 'Przejdź do weryfikacji na Google',
      prefix: 'Prefix',
      confirmLocation: 'Potwierdź lokalizację',
      verificationMethodEmail: 'Weryfikacja przez email',
      verificationMethodPhone: 'Weryfikacja przez telefon',
      verificationMethodSMS: 'Weryfikacja przez SMS',
      selectVerificationMetod: 'Wybierz sposób weryfikacji',
      verificationMethodGoogle: 'Weryfikacja przez Google',
      verificationMethodGoogleSubInfo: 'Zostaniesz przekierowany na stronę Google, aby zweryfikować wizytówkę',
      verificationMethodSMSSubInfo: 'Wyślemy na Twój numer telefonu kod weryfikacyjny z końcówką {{ phoneNumber }}',
      verificationMethodPhoneSubInfo:
        'Wykonamy do Ciebie połączenie na numer telefonu z końcówką {{ phoneNumber }}, gdzie podamy Ci kod weryfikacyjny',
      verificationMethodEmailSubInfo: 'Wyślemy na Twój adres email o domenie {{ domain }} kod weryfikacyjny',
      verificationErrorTitle: 'Podczas weryfikacji wystąpił błąd',
      verificationErrorSubTitleAlreadyVerified:
        'Wizytówka zostala już zweryfikowna lub nie posiada dostępnych form weryfikacji',
      checkVerificationStatus: 'Sprawdź status weryfikacji',
      verifyLater: 'Weryfikuj później',
      verify: 'Weryfikuj',
      verificationErrorSubTitle: 'sprawdź status wizytówki na stronie Google lub wróć do dashboardu.',
      typeGivenCode: 'Wpisz kod otrzymany od Google',
      missingDataTitle: 'Podaj swój adres pocztowy w celu zweryfikowania.',
      missingDataSubtitle:
        'Twój adres pocztowy nie będzie publicznie dostępny.\nUpewnij się, że nie jest to adres skrytki pocztowej.',
      missingDataHelperText: 'Weryfikację możesz dokończyć również na stronie Google.',
      sendAddress: 'Wyślij adres',
      finishVerification: 'Ukończ weryfikacje',
      finishVerificationSubtitle:
        'Weryfikacja wizytówki jest wymagana,\naby móc korzystać z jej funkcjonalności, wpisz otrzymany kod poniżej.',
      goBackToList: 'Wróć do listy',
      enterEmail: 'Podaj adres e-mail',
      processing: 'Przetwarzanie',
      processingSubtitle: 'Trwa przetwarzanie Twojej wizytówki.',
      verificationSuccess: 'Wizytówka została zweryfikowana pomyślnie',
      locationsGroup: 'Grupa lokalizacji',
      personalType: 'Konto osobiste',
      locationGroupType: 'Grupa lokalizacji',
      userGroupType: 'Grupa użytkowników',
      organizationType: 'Grupa organizacji',
      addressNotFound: 'Nie znaleziono adresu',
      addressFetchError: 'Błąd podczas pobierania adresu',
    },
    settings: {
      title: 'Ustawienia',
      myAccount: 'Moje konto',
      password: 'Hasło',
      myBusinessCards: 'Moje wizytówki',
      businessCardsCount: 'Liczba wizytówek: {{ number }}',
      addNew: 'Dodaj nową',
      searchForCard: 'Wyszukaj wizytówkę',
      deleteSelected: 'Usuń zaznaczone',
      all: 'Wszystkie',
      active: 'Aktywne',
      inactive: 'Nieaktywne',
      connectedAccount: 'Podłączone konto',
      disconnectAccount: 'Odłącz konto',
      disconnectAccountText: 'Czy na pewno chcesz odłączyć konto z SEO?',
      changePassword: 'Zmień hasło',
      currentPassword: 'Aktualne hasło',
      newPasword: 'Nowe hasło',
      repeatPassword: 'Powtórz hasło',
      noBusinessProfile: 'Brak wizytówek',
      selectFew: 'Zaznacz',
      verified: 'Zweryfikowana',
      notVerified: 'Niezweryfikowana',
      pending: 'Oczekująca',
      duplicate: 'Duplikat',
      suspended: 'Zawieszona',
      wrongCredentials: 'Podano nieprawidłowe dane',
      passwordChanged: 'Hasło zostało zmienione',
      detachForbiddenError: 'Brak dostępu do wybranego konta, odśwież stronę i spórbuj ponownie.',
      cannotDetachAllProfiles: 'Nie możesz odłączyć wszystkich wizytówek',
      typeBusinessName: 'Wyszukaj po nazwie firmy',
      add: 'Dodaj',
      delete: 'Usuń',
      verifiedSubtitle: 'Wizytówka jest zweryfikowana, możesz ją edytować i korzystać z jej funkcjonalności',
      duplicateSubtitle: 'Wizytówka jest duplikatem innej wizytówki, usuń duplikat aby móc edytować wizytówkę',
      deleteProfile: 'Czy chcesz usunąć profil z listy wizytówek?',
      deleteProfileText: 'Potwierdź usunięcie profilu',
      deleteProfilesText: 'Liczba wizytówek do odpięcia z aplikacji: {{ number }}',
      selectAllThisPage: 'Zaznacz wszystko z tej strony',
      phoneNumberRequestLimit: 'Zbyt wiele żądań w krótkim czasie, spróbuj ponownie później.',
      externalProfile: 'Wizytówka zewnętrzna',
      mySubscriptions: 'Moje subskrypcje',
      displayPlans: 'Zobacz plany subskrypcyjne',
      pickTheBestForYourBusiness: 'Wybierz najlepszy plan',
      forYourBusienss: 'dla Twojego biznesu',
      pickTheBestSubInfo: 'Jeśli masz jakieś pytania - skontaktuj się z nami.',
      subscriptions: 'Subskrypcje',
      buySubscriptionMobile: 'Zakup subskrypcję',
      goOn: 'Przejdź dalej',
      selectBpSubInfo: 'Wybierz wizytówkę, dla której chcesz zakupić subskrypcję',
      currentPlan: 'Obecny plan',
      businessProfile: 'Wizytówka',
      singleMonthPayment: 'Płatność jednorazowa za jeden miesiąc',
      singleYearPayment: 'Płatność jednorazowa za 12 miesięcy',
      cyclicMonthlyPayment: 'Płatności cykliczne, co miesiąc',
      fullYearSubInfo: '{{ price }} zł x 12 msc',
      pickPaymentMethod: 'Wybierz sposób rozliczenia',
      monthPriceTag: 'zł/miesiąc',
      yearPriceTag: 'zł/rok',
      goToSummary: 'Przejdź do podsumowania',
      price: 'Cena:',
      perMonth: ' /miesiąc',
      perYear: ' /rok',
      purchaseSummary: 'Podsumowanie zamówienia',
      buyerDataTitle: 'Dane do płatności',
      addBuyerData: 'Dodaj dane do płatności',
      noBuyerData: 'Brak danych do płatności',
      fullName: 'Imię i nazwisko',
      email: 'Email',
      phoneNumber: 'Numer telefonu',
      country: 'Kraj',
      voivodeship: 'Województwo',
      locality: 'Miejscowość',
      address: 'Adres',
      postalCode: 'Kod pocztowy',
      goToPurchase: 'Przejdź do zakupu',
      verificationRequired: 'Do zakupu subskrypcji wymagana jest weryfikacja wizytówki',
      warningVerificationRequired: 'Wizytówka oznaczona jako {{ status }} zakup subskrypcji nie jest możliwy',
      step: 'Krok {{ currentStepNumber }}/{{ totalSteps }}',
      noBusinessProfiles: 'Wygląda na to, że nie masz jeszcze żadnej wizytówki do której możesz zakupić subskrypcję',
      activateTrial: 'Aktywuj okres próbny',
      goToPayment: 'Przejdź do płatności',
      trialSummarySubInfo: 'Plan będzie aktywny niezwłocznie po aktywacji okresu próbnego',
      paidSubPlanSubInfo: 'Plan będzie aktywny ',
      afterPaymentVerification: 'niezwłocznie po zweryfkowaniu płatności',
      onePayment: 'Jednorazowa płatność',
      cyclicPayment: 'Płatności cykliczne, co miesiąc',
      consentToStartServiceBefore14Days: 'Wyrażam zgodę na rozpoczęcie świadczenia usługi przed upływem 14 dni',
      accept: 'Akceptuję ',
      termsOfService: 'regulamin świadczenia usług',
      taxIncluded: '*cena zawiera podatek VAT',
      aboutCyclicMethod:
        'Subskrypcja będzie odnawiana automatycznie do czasu jej anulowania. Płatności pobierane będą cyklicznie zgodnie z okresem rozliczeniowym.',
      subscriptionPlans: 'Plany subskrypcyjne',
      goBackSubscriptionList: 'Wróć do listy subskrypcji',
      showDetails: 'Zobacz szczegóły',
      hideDetails: 'Zwiń szczegóły',
      changeData: 'Zmień dane',
      savedData: 'Zapisane dane',
      dataHasBeenSaved: 'Dane zostały zapisane',
      invoicesData: 'Dane do płatności',
      businessCard: 'Wizytówka',
      businessCardStatus: 'Status wizytówki',
      plan: 'Plan',
      opinieoCards: 'Karty Opinieo',
      selectAllWarging: 'Zaznaczono wszystkie wizytówki, również na kolejnych stronach',
      noInvoiceData: 'Brak danych do płatności',
      noInvoiceDataSubInfo: 'Kliknij przycisk poniżej, aby dodac dane do płatności',
      payment: 'Płatność',
      connectedBusinessProfile: 'Przypisana wizytówka',
      paymentTypeOneMonth: 'Jednorazowa\nmiesięczna',
      paymentTypeOneYear: 'Jednorazowa\nroczna',
      paymentTypeRecurring: 'Cykliczna',
      subscriptionStatus: 'Status subskrypcji',
      date: 'Data',
      nextPaymentDate: 'Data odnowienia',
      expirationDate: 'Data wygaśnięcia',
      subActive: 'Aktywna',
      paymentHistory: 'Historia płatności',
      orderDate: 'Data płatności',
      orderNo: 'Nr zamówienia',
      paymentMethod: 'Metoda płatności',
      status: 'Status',
      paymentPrice: 'Cena',
      reccuring: 'Cykliczna',
      oneTimeMonth: 'Jednorazowa\nmiesięczna',
      oneTimeYear: 'Jednorazowa\nroczna',
      completed: 'Zakończona',
      rejected: 'Odrzucona',
      canceled: 'Anulowana',
      new: 'Nowa',
      noData: 'Brak danych',
      noSubscriptionData: 'Nie masz żadnych uruchomionych płatnych subskrypcji',
      noPaymentData: 'Dokonaj pierwszej transakcji, żeby zobaczyć dane',
      manage: 'Zarządzaj',
      card: 'Karta',
      transfer: 'Przelew',
      searchTransaction: 'Wyszukaj transakcję',
      singleMonthly: 'Jednorazowa miesięczna',
      singleYearly: 'Jednorazowa roczna',
      recurring: 'Cykliczna',
      filtersPlan: 'Plan',
      filtersStatus: 'Status',
      filtersPaymentType: 'Rodzaj płatności',
      reset: 'Resetuj',
      filter: 'Filtruj',
      filters: 'Filtry',
      completedPayment: 'Zrealizowana',
      selectDate: 'Wybierz datę',
      forSelectedFiltersNoData: 'Brak danych dla wybranych filtrów',
      searchSubscriptions: 'Wyszukaj subskrypcje',
      paymentStatus: 'Status płatności',
      external: 'Zewnętrzna',
    },
    businessProfileChangeHistory: {
      headerTitle: 'Historia zmian na wizytówce',
      subTitle: 'Poniżej znajdziesz zapis logów dotyczących Twojej wizytówki. ',
      date: 'Data',
      editedField: 'Edytowane pole',
      action: 'Akcja',
      additionalCategory: 'Kategoria dodatkowa',
      additionalPhone: 'Dodatkowy numer telefonu',
      serviceItem: 'Usługa',
      regularHours: 'Godziny otwarcia',
      title: 'Nazwa firmy',
      primaryPhone: 'Numer telefonu',
      description: 'Opis firmy',
      labels: 'Etykiety',
      openingDate: 'Data otwarcia',
      website: 'Strona internetowa',
      serviceArea: 'Obszar usług',
      moreHours: 'Dodatkowe godziny:  {{ type }}',
      added: 'Dodano: {{ value }}',
      removed: 'Usunięto: {{ value }} ',
      updated: 'Zaktualizowano z {{ oldValue }} →\n {{ value }}',
      selectDate: 'Wybierz datę',
      actionType: 'Typ akcji',
      create: 'Dodanie',
      update: 'Aktualizacja',
      delete: 'Usunięcie',
      all: 'Wszystko',
      freeFormService: 'Usługa niestandardowa: {{ service }}',
      price: 'Cena {{ price }} {{ currency }}',
      address: 'Adres',
      moreHoursWithoutType: 'Dodatkowe godziny',
      structuredFormService: 'Usługa standardowa: {{ service }}',
    },

    modalMobile: {
      goBackToOpinions: 'Wróć do opinii',
      goBackToBusinessProfileEdit: 'Wróć do edycji wizytówki',
      goBackToTemplates: 'Wróć do szablonów',
      goBackToPosts: 'Wróć do wpisów',
      goBackToSelectForm: 'Wróć do wyboru formularza',
      goBackToForm: 'Wróć do formularza',
      goBackToReport: 'Wróć do raportu',
    },
    remindPassword: {
      title: 'Przypomnij hasło',
      subtitle:
        'Nie pamiętasz swojego hasła? Wpisz email podany podczas rejestracji na Seotracking, a w krótkim czasie otrzymasz wiadomość z dalszymi instrukcjami.',
      successTitle: 'Wysłaliśmy na Twojego maila informacje z dalszymi instrukcjami.',
      openMessage: 'Przejdź do wiadomości',
    },
    errors: {
      wrongLogin: 'Nieprawidłowy login lub hasło',
      forbiddenPermission: 'Nie masz odpowiednich uprawnień',
      forbiddenAccessTemplate: 'Brak dostępu do tego szablonu',
      forbiddenAccessReport: 'Brak dostępu do tego raportu',
      forbiddenFeature: 'Ta funkcja jest niedostępna',
      forbiddenGoogleAccount: 'Nie można uzyskać dostępu do konta Google',
      internalError: 'Wystąpił wewnętrzny błąd systemu',
      unauthorized: 'Brak autoryzacji',
      notFoundUser: 'Nie znaleziono użytkownika',
      notFoundGoogleAccount: 'Nie znaleziono konta Google',
      notFoundMedia: 'Nie znaleziono  pliku multimedialnego',
      notFoundBusinessProfile: 'Nie znaleziono profilu biznesowego',
      notFoundPlace: 'Nie znaleziono tego miejsca',
      notFoundPlaces: 'Nie znaleziono żadnych miejsc',
      notFoundReport: 'Nie znaleziono tego raportu',
      notFoundPlacePhoto: 'Nie znaleziono zdjęcia tego miejsca',
      notFoundLocation: 'Nie znaleziono tej lokalizacji',
      notFoundCategory: 'Nie znaleziono kategorii',
      notFoundPreviewReply: 'Nie znaleziono podglądu odpowiedzi',
      userAlreadyExists: 'Ten użytkownik już istnieje',
      businessProfileAlreadyExistsSomeAlreadyAttached: 'Profil biznesowy już istnieje, niektóre są już przypisane',
      businessProfileAlreadyExistsAlreadyAttached: 'Profil biznesowy już istnieje i jest już przypisany',
      badRequest: 'Nieprawidłowe żądanie',
      freeFormServiceItemBadRequest: 'Nieprawidłowy element usługi z wolnym formularzem',
      invalidWebsiteUri: 'Nieprawidłowy adres URL witryny',
      invalidGrantByCode: 'Nieprawidłowa zgoda na podstawie kodu',
      invalidPostalCode: 'Nieprawidłowy kod pocztowy',
      invalidPrimaryPhone: 'Nieprawidłowy numer telefonu głównego',
      invalidAdditionalPhone: 'Nieprawidłowy dodatkowy numer telefonu',
      invalidStorefrontAddress: 'Nieprawidłowy adres witryny',
      invalidServiceArea: 'Nieprawidłowy obszar usługi',
      throttledManyChangedPhoneNumbers: 'Ograniczono - Zmieniono zbyt wiele numerów telefonu',
      throttledManyChangedAddress: 'Ograniczono - Zmieniono zbyt wiele adresów',
      eventEndDateError: 'Błąd daty zakończenia wydarzenia',
      resetPasswordTokenExpired: 'Token resetowania hasła wygasł',
      permissionDenied: 'Odmowa uprawnień',
      aborted: 'Operacja została przerwana',
      invalidPinDropRequired: 'Wymagane jest poprawne umieszczenie pinezki',
      invalidOpeningDateTooFarInTheFuture: 'Nieprawidłowa data otwarcia - zbyt daleka w przyszłość',
      invalidArgument: 'Nieprawidłowy argument',
      invalidBadMedia: 'Nieprawidłowe złe media',
      invalidFetchingImage: 'Nieprawidłowe pobieranie obrazu',
      invalidVerification: 'Nieprawidłowa weryfikacja',
      failedPrecondition: 'Nieudane warunki wstępne',
      badRequestDateIsInvalid: 'Nieprawidłowa data w żądaniu',
      badRequestDateEarlier: 'Data w żądaniu jest wcześniejsza niż oczekiwano',
      badRequestDateLaterYear: 'Data w żądaniu jest w późniejszym roku niż dopuszczalne',
      badRequestSavePhotoFiles: 'Nieprawidłowe zapisywanie plików zdjęciowych',
      badRequestCategoryRequiresLocalization: 'Kategoria wymaga podania lokalizacji',
      badRequestIncorrectCode: 'Nieprawidłowy kod',
      badRequestSavePhotoDb: 'Nieprawidłowe zapisywanie zdjęć w bazie danych',
      badRequestHaveLocation: 'Nieprawidłowa lokalizacja',
      badRequestOnlyDev: 'Tylko dla dewelopera',
      badRequestLocation: 'Nieprawidłowa lokalizacja',
      internalErrorBusinessProfileDenied: 'Wystąpił wewnętrzny błąd - odmowa profilu biznesowego',
      internalErrorDb: 'Wystąpił wewnętrzny błąd - błąd bazy danych',
      internalErrorEnv: 'Wystąpił wewnętrzny błąd - błąd środowiska',
      internalErrorEmptyRefreshToken: 'Wystąpił wewnętrzny błąd - pusty token odświeżania',
      internalErrorPlace: 'Wystąpił wewnętrzny błąd - błąd miejsca',
      internalErrorServiceItem: 'Wystąpił wewnętrzny błąd - błąd elementu usługi',
      internalErrorParseLocalPost: 'Wystąpił wewnętrzny błąd - błąd analizowania lokalnego postu',
      contentLengthRequired: 'Wymagana jest długość zawartości',
      contentTooLarge: 'Zawartość jest zbyt duża',
      invalidBase64Data: 'Nieprawidłowe dane Base64',
      invalidBase64Prefix: 'Nieprawidłowy prefiks Base64',
      locationCannotCreateOffer: 'Ta wizytówka nie może tworzyć oferty',
      notImplemented: 'Nie zaimplementowano',
      invalidServiceItem: 'Nieprawidłowy element usługi',
      noBusinessProfileData: 'Brak danych profilu biznesowego',
      tooManyRequests: 'Zbyt wiele żądań',
      notFoundRequestedEntity: 'Nie znaleziono lokalizacji',
      somethingWentWrong: 'Coś poszło nie tak.',
      googleTokenUnauthorized: 'Brak autoryzacji Google',
    },
    media: {
      mediaHeader: 'Media',
      mediaPill: 'Liczba mediów: {{ count }}',
      addMedia: 'Dodaj media',
      noMedia: 'Brak opublikowanych mediów',
      mediaSubheader: 'Dodaj zdjęcia oraz filmy, które chcesz pokazać klientom',
      addFirstMedia: 'Aby dodać pierwsze media, kliknij przycisk poniżej',
      coverImageAspectRatioAdjusted: 'Zdjęcie zostało przycięte do odpowiedniego formatu',
      mediaDeleted: 'Zdjęcie zostało usunięte',
      deleteSelected: 'Usuń zaznaczone',
      noPlannedMedia: 'Brak zaplanowanych mediów',
      noPlannedMediaSubInfo: 'Zaplanuj publikację zdjęć i filmów',
      coverEdit: 'Edytuj zdjęcie główne',
      profileEdit: 'Edytuj logo',
      changeCover: 'Zmień zdjęcie główne',
      changeProfile: 'Zmień logo',
      profile: 'Logo',
      cover: 'Zdjęcie główne',
    },
    seoCard: {
      title: 'Karty Opinieo',
      cardName: 'Nazwa karty',
      cardID: 'ID karty',
      cardStatus: 'Status karty',
      cardStatusActive: 'Aktywna',
      cardStatusInactive: 'Nieaktywna',
      cardCreatedAt: 'Data utworzenia karty',
      acitvateCard: 'Aktywuj kartę',
      deactivateCard: 'Dezaktywuj kartę',
      deleteCard: 'Usuń kartę',
      cardInformation: 'Informacje o karcie',
      data: 'Podstawowe dane',
      statistics: 'Statystyki karty',
      connectedBusinessProfile: 'Przypisana wizytówka',
      redirectLinks: 'Linki przekierowujące ({{ number }}) ',
      cardActive: 'Aktywna',
      cardInactive: 'Nieaktywna',
      searchCard: 'Wyszukaj kartę',
      pickDate: 'Wybierz datę',
      saveChanges: 'Zapisz zmiany',
      deleteCardSuccess: 'Karta została usunięta',
      noCard: 'Nie posiadasz żadnych kart',
      noCardSubtitle:
        'Aby skonfigurować kartę, skontaktuj się z administratorem za pomocą formularza kontaktowego lub numeru telefonu: 882 825 232',
      cardWithoutName: 'Karta bez nazwy',
      redirectModalTitle: 'Ostrzeżenie',
      redirectModalInformation: 'Za chwilę nastąpi przeniesienie do sklepu',
      redirtectHelpInformation: 'Za chwilę nastąpi przeniesienie do formularza kontatkowego',
      connectedProfile: 'Przypisana wizytówka',
      purchaseCard: 'Kup kartę',
      contactForm: 'Formularz kontaktowy',
    },
    subscription: {
      trial: 'Trial',
      proPlan: 'Plan Pro',
      freePlan: 'Plan Free',
      basicPlan: 'Plan Basic',
      miniPlan: 'Plan Mini',
      trialPlan: 'Plan Trial',
      title: 'Subskrypcja',
      notVerified: 'niezweryfikowana',
      duplicate: 'duplikatem',
      suspended: 'zawieszona',
      buySubscription: 'Zakup plan subskrypcyjny',
      subEmptyState: 'Nie posiadasz jeszcze żadnej płatnej subskrypcji',
      subEmptyStateSubInfo: 'Aby zakupić plan subskrypcyjny, kliknij w przycisk poniżej',
      showOfferDetails: 'Zobacz szczegółowy opis planów',
      trialLabel: 'Plan Trial zawiera wszystkie zaawansowane funkcje z planu Pro.',
      additionalInfoTrialLabel:
        'Powyższy plan można aktywować <bold>tylko raz</bold> w ramach <bold>jednego konta.</bold>',
      whatPlanIncludes: 'Zobacz co zawiera plan',
      purchaseSummary: 'Podsumowanie zamówienia',
      activateTrial: 'Aktywuj okres próbny',
      trialSummarySubInfo: 'Plan będzie aktywny <bold>niezwłocznie po aktywacji okresu próbnego</bold>',
      trialNotAvailable: 'Plan Trial nie jest dostępny dla Twojego konta',
      trialActivated: 'Plan Trial został aktywowany',
      pleaseAcceptConsents: 'Przed zakupem subskrypcji, zaakceptuj wszystkie zgody',
      choosePaymentMethod: 'Wybierz sposób rozliczenia',
      oneMonth: 'Płatnośc jednorazowa za <bold>1 msc</bold>',
      wholeYear: 'Płatność jednorazowa za <bold>12 mscy</bold>',
      cyclicPayment: 'Płatności <bold>cykliczne, co miesiąc</bold>',
      perMonth: 'zł/miesiąc',
      twoMonthsGratis: '* 2 miesiące gratis!',
      perYear: 'zł/rok - {{ price }} zł x 10 msc',
      yourPlan: 'Twój plan:',
      expireDate: 'Data wygaśnięcia',
      activationDate: 'Data aktywacji',
      activePlanStatus: 'Plan jest aktywny',
      cyclicPaymentSummarySubInfo: 'Płatność cykliczna, co miesiąc',
      perYearShort: ' /rok',
      perMonthShort: ' /miesiąc',
      wholeYearSummarySubInfo: 'Płatność jednorazowa za cały rok',
      oneMonthSummarySubInfo: 'Płatność jednorazowa za jeden miesiąc',
      cyclicPaymentSubinfo:
        'Subskrypcja będzie odnawialna automatycznie do czasu jej anulowania. Płatności pobierane będą cyklicznie zgodnie z okresem rozliczeniowym.',
      priceToPay: 'Do zapłaty',
      subscriptionBought: 'Subskrypcja została zakupiona',
      goToPaymentDetails: 'Przejdź do szczegółów płatności',
      singlePaymentInfoTitle: 'Jeśli chcesz zmienić, anulować lub zawiesić plan skontaktuj się z administratorem',
      changePlan: 'Zmień plan',
      suspendPlan: 'Zawieś plan',
      cancelSubscription: 'Anuluj subskrypcję',
      paymentMethod: 'Sposób rozliczenia',
      paymentTypeOneMonth: 'Jednorazowo, na miesiąc',
      paymentTypeOneYear: 'Jednorazowo, na rok',
      paymentTypeRecurring: 'Cyklicznie, co miesiąc',
      cancelSubscriptionModalTitle: 'Anuluj plan',
      cancelSubscriptionModalSubTitle:
        'Usługi przestaną być dostępne dnia {{ date }}, następna płatność nie zostanie naliczona',
      warningRedirectPaymentOperator: 'Za chwilę zostaniesz przekierowany do platformy PayU',
      activePaymentTitle: 'Dokończ płatność',
      activePaymentSubTitle: 'Na Twój adres email został wysłany link użyj go aby dokończyć płatność',
      cardNumber: 'Numer karty',
      cardExpirationDate: 'Data ważności karty',
      cardCVV: 'CVV',
      cancelSubscriptionTitle: 'Czy na pewno chcesz anulować ten plan subskrypcyjny?',
      cancelSubscriptionSubtitle:
        'Twoja subskrypcja zostanie anulowana, a dostęp do usług zostanie zakończony. Utracisz wszystkie korzyści związane z subskrypcją, ale możesz ponownie ją aktywować w przyszłości, jeśli zmienisz zdanie.',
      cancelSubscriptionSinceDate:
        'Subskrypcja będzie nieaktywna od kolejnego okresu rozliczeniowego tj. <bold>{{ date }}</bold>',
      provideCancelReason: 'Czy chcesz podać przyczynę anulowania? (opcjonalnie)',
      buyerDataForSubscription: 'Dane do faktury za płatność cykliczną',
      proccedToCheckout: 'Przejdź do realizacji zamówienia',
      securePayment: 'Realizacja zamówienia',
      recurringData: 'Dane do płatności cyklicznej',
      changeInvoiceDataTitle: 'Aby zmienić dane do faktury, wybierz inny adres z listy poniżej',
      changeWillBeVisibleWithNextPayment: 'Zmiany będą widoczne na fakturach od następnego okresu rozliczeniowego.',
      changeInvoiceData: 'Zmień dane do faktury',
      invoiceDataChanged: 'Dane do faktury zostały zmienione',
      canceled: 'Anulowana',
      active: 'Aktywna',
      pending: 'Oczekująca',
    },
    adminControlPanel: {
      navUserTitle: 'Użytkownicy',
      users: 'Użytkownicy ({{ value }})',
      addAdministrator: 'Dodaj administratora',
      editAdministrator: 'Edytuj administratora',
      name: 'Imię',
      lastName: 'Nazwisko',
      emailAddress: 'Adres email',
      id: 'ID',
      createdAt: 'Utworzono',
      status: 'Status',
      remindPasswordSubtitle: 'Na podany email wyślemy wiadomość z dalszymi instrukcjami.',
      withPermission: 'Z uprawnieniami',
      logout: 'Wyloguj',
      title: 'Panel administracyjny',
      adminPanel: 'Admin Panel',
      setPassword: 'Utwórz hasło',
      setPasswordSubTitle: 'Podaj hasło, którym będziesz się logował do systemu.',
      statistics: 'Statystyki',
      administrators: 'Administratorzy',
      clientsSEO: 'Klienci SEO',
      cardsSEO: 'Karty Opinieo',
      payments: 'Płatności',
      subscriptions: 'Subskrypcje',
      couponCodes: 'Kody rabatowe',
      contentManagement: 'Zarządzanie treścią',
      settings: 'Ustawienia',
      checkMailBox: 'Sprawdź swojego maila i postępuj zgodnie z instrukcjami',
      changePassword: 'Zmień hasło',
      saveChanges: 'Zapisz zmiany',
      oldPassword: 'Stare hasło',
      newPassword: 'Nowe hasło',
      repeatNewPassword: 'Powtórz nowe hasło',
      saveNewPassword: 'Zapisz nowe hasło',
      passOldPassword: 'Podaj stare hasło',
      passNewPassword: 'Podaj nowe hasło',
      passwordChanged: 'Hasło zostało zmienione',
      incorrectData: 'Nieprawidłowe dane',
      changesSaved: 'Zmiany zostały zapisane',
      userData: 'Dane klienta',
      managerData: 'Dane managera',
      role: 'Rola',
      moderator: 'Moderator',
      superAdmin: 'Administrator',
      accountInformation: 'Informacje o koncie',
      accountCreationDate: 'Data utworzenia konta',
      lastLoggedIn: 'Data ostatniego logowania',
      accountCreated: 'Utworzenie konta',
      lastLogin: 'Ostatnie logowanie',
      accountState: 'Status konta',
      subscritpionState: 'Status subskrypcji',
      lastStatusChangeDate: 'Ostatnia zmiana statusu: {{ date }}',
      desactivateAccount: 'Dezaktywuj konto',
      activateAccount: 'Aktywuj konto',
      descactivateAccountModalInfo:
        'Czy na pewno chcesz dezaktywować konto? Po dezaktywacji użytkownik nie będzie miał dostępu do swojego konta w systemie.',
      notActive: 'Nieaktywny',
      active: 'Aktywny',
      desactivateAccountSuccess: 'Użytkownik został zdezaktywowany',
      activateAccountSuccess: 'Użytkownik został aktywowany',
      passName: 'Podaj imię',
      passLastName: 'Podaj nazwisko',
      passMail: 'Podaj email',
      email: 'Email',
      noData: 'Brak danych',
      show: 'Pokaż',
      rows: '{{ value }} wierszy',
      exportCSV: 'Eksportuj jako CSV',
      export: 'Eksportuj',
      all: 'Wszyscy',
      noResults: 'Nie znaleziono wyników',
      adminListEmpty: 'Żaden administrator nie został jeszcze utworzony',
      noManagers: 'Żaden manager nie został jeszcze utworzony',
      noClients: 'Żaden klient nie został jeszcze utworzony',
      clients: 'Klienci',
      managersNav: 'Managerowie',
      managers: 'Managerowie ({{ value }})',
      nip: 'NIP',
      seo: 'SEO',
      phoneNumber: 'Nr telefonu',
      basicData: 'Dane podstawowe',
      businessCards: 'Wizytówki ({{ value }})',
      businessCardErrorTitle: 'Błąd w obsłudze google',
      businessCardErrorValue:
        'Użytkownik usunął aplikację z listy aplikacji autoryzowanych na swoim koncie Google i musi przywrócić dostęp do aplikacji.',
      SEOcards: 'Opinieo ({{ value }})',
      clientData: 'Dane klienta',
      accountInformationData: 'Informacje o koncie',
      suspendAccount: 'Zawieś konto',
      businessCardsNumber: 'Liczba wizytówek',
      activeSubscriptionsNumber: 'Liczba aktywnych subskrypcji',
      opinieoCardsNumber: 'Liczba kart Opinieo',
      noCardsToDisplay: 'Brak kart do wyświetlenia',
      noBusnessCardsToDisplay: 'Brak wizytówek do wyświetlenia',
      cardsSeoPlural: '{{ value }} karty Opinieo',
      cardsSeo: '{{ value }} kart Opinieo',
      displayBusinessCard: 'Zobacz wizytówkę',
      dashboard: 'Pulpit',
      reports: 'Raporty',
      businessProfileEdit: 'Edycja wizytówki',
      businessProfileChangeHistory: 'Historia zmian wizytówki',
      localPosts: 'Wpisy',
      reviews: 'Opinie',
      paginationLabel: 'na {{ total}} wyników',
      notVerifiedSubInfoAdmin: 'Wizytówka jest niezweryfikowana.',
      suspendedSubInfoAdmin: 'Wizytówka jest zawieszona.',
      templates: 'Szablony odpowiedzi',
      noLocalPostsToDisplay: 'Brak wpisów do wyświetlenia',
      seoCards: 'Karty Opinieo',
      opinieo: 'Opinieo',
      createNewCard: 'Utwórz nową kartę',
      addManager: 'Dodaj managera',
      seoCardModalFirstStep: 'Krok 1/2',
      seoCardModalSecondStep: 'Krok 2/2',
      seoCardModalFirstStepTitle: 'Podaj dane klienta',
      seoCardModalFirstStepSubtitle:
        ' Jeśli nie znajdziesz użytkownika na liście, możesz go dodać poniżej, wpisując maila oraz pozostałe dane',
      fullPhoneNumber: 'Numer telefonu',
      companyNip: 'NIP firmy',
      saveAndContinue: 'Zapisz i przejdź dalej',
      passCompanyNip: 'Podaj NIP firmy',
      passPhoneNumber: 'Podaj numer telefonu',
      searchOrAddUser: 'Wyszukaj lub dodaj użytkownika',
      searchEmail: 'Wyszukaj email',
      addSubscription: 'Dodaj subskrypcję',
      removeSubscription: 'Usuń subskrypcję',
      deleteManager: 'Usuń managera',
      createCard: 'Utwórz kartę',
      configureCard: 'Skonfiguruj kartę',
      seoCardModalSecondStepSubtitle:
        'Aby skonfigurować kartę podaj linki źródłowe, a następnie przypisz do nich linki przekierowujące. Linki możesz dodać później lub może to zrobić klient w swojej aplikacji. Możesz podać maksymalnie 5 linków przekierowujących.',
      passCardName: 'Wpisz nazwę karty',
      passCardId: 'Podaj ID karty',
      cardTitle: 'Nazwa karty',
      cardId: 'ID karty',
      redirectionLinks: 'Linki przekierowujące',
      linkNumber: '{{ value }}/5',
      redirectName: 'Nazwa przekierowania',
      baseCode: 'Kod źródłowy',
      redirectLink: 'Link przekierowujący',
      addNext: 'Dodaj kolejny',
      saveCard: 'Zapisz kartę',
      country: 'Kraj',
      passCountry: 'Podaj kraj',
      street: 'Ulica',
      passStreet: 'Podaj ulicę, numer domu / lokalu',
      postalCode: 'Kod pocztowy',
      passCity: 'Podaj miejscowość',
      city: 'Miejscowość',
      passPostalCode: 'Podaj kod pocztowy',
      invoiceData: 'Dane do faktury',
      phoneNumberShort: 'nr tel. {{ phone }}',
      cardCodeExist: 'Kod karty musi być unikalny',
      linkIdExists: 'Kod musi być unikalny',
      userNotFound: 'Nie znaleziono użytkownika',
      confirmDesactivation: 'Potwierdź dezaktywację konta użytkownika',
      desactivationReasonInformation: 'Podaj powód dezaktywacji konta, minimum 10 znaków',
      confirmActivation: 'Potwierdź aktywację konta użytkownika',
      activationReasonInformation: 'Dodaj komentarz do aktywacji konta, minimum 10 znaków',
      interactionAmount: 'Suma interakcji',
      activity: 'Aktywność',
      cardCreatedAt: 'Utworzenie karty',
      clientMail: 'Email klienta',
      businessProfile: 'Wizytówka',
      cardStatus: 'Status karty',
      redirectLinks: 'Linki przekierowujące ({{ value }})',
      cardStatistic: 'Statystyki karty',
      connectedBusinessProfile: 'Przypisana wizytówka',
      cardInformation: 'Informacje o karcie',
      cardNotActive: 'Nieaktywna',
      cardActive: 'Aktywna',
      redirectLinkList: 'Linki przekierowujące ({{ value }})',
      desactivateCard: 'Dezaktywuj kartę',
      deleteCard: 'Usuń kartę',
      deleteCardSuccess: 'Karta została usunięta',
      desactivateCardModalTitle: 'Potwierdź dezaktywację karty',
      desactivateCardModalDescription: 'Czy na pewno chcesz dezaktywować kartę?',
      deleteCardModalTitle: 'Potwierdź usunięcie karty',
      deleteCardModalDescription: 'Czy na pewno chcesz usunąć kartę?',
      cardDesactivationSuccess: 'Karta została dezaktywowana pomyślnie',
      activateCard: 'Aktywuj kartę',
      activationSuccess: 'Karta została aktywowana pomyślnie',
      link: 'Link',
      cardDataUpdated: 'Zaktualizowano dane karty',
      noOpinionsYet: 'Zasugeruj klientowi pozyskanie opinii',
      noOpinionsYetTitle: 'Wizytówka nie posiada jeszcze opinii',
      accountChangeTypeAndDate: 'Rodzaj oraz data zmiany statusu konta',
      accountChangeReason: 'Powód zmiany statusu konta',
      showStatistics: 'Zobacz statystki',
      lastInteraction: 'Ostatnia interakcja',
      redirectAmount: 'Suma przekierowań',
      selectDate: 'Wybierz datę',
      tooltipLinkStatisticStyle:
        'Domyślnie suma przekierowań prezentuje wszystkie przekierowania od daty utworzenia karty',
      lastInteractionDate: 'Data ostatniego użycia',
      redirects: 'Przekierowania',
      filters: 'Filtry',
      clientSEOSignUp: 'Klient zarejestrowany w SEO',
      date: 'Data',
      applyFilters: 'Zastosuj filtry',
      signedUp: 'Zarejestrowany',
      notSignedUp: 'Niezarejestrowany',
      interactionDate: 'Data interakcji',
      seoCardActive: 'Aktywna',
      seoCardInactive: 'Nieaktywna',
      details: 'Szczegóły',
      noBusinessProfile: 'Brak powiązanej wizytówki',
      noAddressBusinessProfile: 'Brak adresu wizytówki',
      hourly: 'Godzinowo',
      daily: 'Dziennie',
      monthly: 'Miesięcznie',
      weekly: 'Tygodniowo',
      subscriptionExpiration: 'Subskrypcja do',
      subscriptionRemoved: 'Usunięto subskrypcję',
      subscriptionAdded: 'Dodano subskrypcję',
      agregationType: 'Sposób agregacji danych',
      media: 'Media',
    },
    landingPage: {
      about: 'O nas',
      whyIsWorth: 'Dlaczego warto?',
      features: 'Funkcjonalości',
      pricing: 'Cennik',
      contact: 'Kontakt',
      signUpForFree: 'Załóż konto za darmo',
      copyRights: '© Copyright 2024 by Seotracking',
      privacyPolicy: 'Polityka prywatności',
      termsOfService: 'Regulamin',
      contactUs: 'Skontaktuj się z nami',
      clientMail: 'kontakt@seotracking.pl',
      clientPhoneNumber: '+48882825232',
      doYouKnowTitle: 'Czy wiesz, że 93% wszystkich doświadczeń online\nzaczyna się od wyszukiwarki?',
      doYouKnowDescription:
        'Niezależnie od branży, odpowiednie zarządzanie wizytówką w Google może być kluczem do sukcesu w świecie online.',
      callToActionTitle: 'Skorzystaj z darmowego miesięcznego okresu próbnego',
      callToActionSubtitle:
        'Sprawdź jak działa nasze narzędzie używając wszystkich funkcjonalności całkowicie bez ograniczeń i opłat.',
      heroSection: {
        title: 'Zwiększaj widoczność swojej firmy i zdobywaj nowych klientów',
        description: 'Zyskaj pełną kontrolę nad swoją wizytówką Google - wykorzystaj moc AI dla lepszych wyników!',
        firstMonthForFree: 'Pierwszy miesiąc za darmo!',
      },
      pricingSection: {
        title: 'Wybierz plan, który pasuje do Twoich potrzeb',
        description:
          'Jeśli nie jesteś pewien, który plan jest dla Ciebie najlepszy - skontaktuj się z nami, pomożemy Ci dokonać najlepszego wyboru!',
        popular: 'Popularny',
        monthly: ' /miesiąc',
        fourteenDays: ' /14 dni',
        selectPlan: 'Wybierz plan',
        import: 'Utwórz / zaimportuj wizytówkę',
        dashboard: 'Dashboard',
        businessCardStatus: 'Status wizytówki',
        displayAmountStatistic: 'Liczba wyświetleń',
        businessProfileCompletion: 'Stopień uzupełnienia wizytówki',
        businessProfileEdit: 'Edycja wizytówki',
        basicInformation: 'Podstawowe informacje',
        contactInformation: 'Informacje kontaktowe',
        locationAndArea: 'Lokalizacja i obszar',
        openingHours: ' Godziny otwarcia',
        services: 'Usługi',
        descriptionAIAssistant: 'Asystent AI - edycja opisów',
        reviews: 'Opinie',
        displayAndFilter: 'Podgląd, filtrowanie opinii',
        reviewAnswering: 'Odpowiadanie na opinie',
        answerTemplatesMassActions: 'Szablony odpowiedzi,akcje zbiorcze',
        reviewAIAssistant: 'Asystent AI - odpowiedzi',
        localPosts: 'Wpisy',
        createLocalPost: 'Tworzenie nowych wpisów',
        scheduleAddPost: 'Planowanie dodawania wpisów',
        scheduleAddMedia: 'Planowanie dodawania zdjęć',
        media: 'Zdjęcia',
        uploadMedia: 'Dodawanie nowych zdjęć',
        reports: 'Raporty',
        numberOfReports: 'Liczba raportów',
        reportNet: 'Siatka raportu',
        keywordsAmount: 'Liczba fraz kluczowych',
        aiPositioningAssistant: 'Asystent Pozycjonowania',
        comparePlans: 'Porównaj plany',
        googleBusienssCards: 'Wizytówki Google',
        seoCards: 'Opinieo',
        free: 'Free',
        basic: 'Basic',
        mini: 'Mini',
        trial: 'Trial',
        pro: 'Pro',
        expandMoreFn: 'Rozwiń więcej funkcji',
        expandLessFn: 'Zwiń',
        manageCards: 'Zarządzanie kartami',
        cardStatistic: 'Statystyki kart',
        preview: 'Podgląd',
        freeModelSubTitle: 'Darmowy plan pozwala na podgląd wizytówki oraz statystyk',
        miniModelSubTitle: 'Idealny dla posiadaczy kart Opinieo, którzy chcą widzieć statystyki kart',
        basicModelSubTitle: 'Plan pozwala na zaawansowane zarządzanie wizytówką',
        proModelSubTitle: 'Plan pozwala na zaawansowane zarządzanie wizytówką',
        createAndImportBusinessCard: 'Tworzenie i importowanie wizytówek',
        businessCardStatistics: 'Statystyki wizytówek',
        previewBusinessCard: 'Podgląd treści wizytówki',
        previewReviews: 'Podgląd opinii',
        seoCardsManagement: 'Zarządzanie kartami Opinieo',
        seoCardsStatisticsMonthly: 'Statystyki Opinieo.pl - miesięczne',
        seoCardsStatisticsWeekly: 'Statystyki Opinieo.pl - tygodniowe',
        miniPlanIncluded: 'Wszystko co w planie Mini',
        aiAssistant: 'Asystent AI',
        positioningAssistant: 'Asystent pozycjonowania',
        reviewManagmnet: 'Zarządzanie opiniami',
        managmentAndPlaningPostAndMedia: 'Zarządzanie i planowanie wpisów oraz zdjęć',
        cyclicReportBasic: 'Liczba raportów',
        seoCardsStatisticDaily: 'Statystyki Opinieo.pl - dziennie',
        seoCardsStatisticHourly: 'Statystyki Opinieo.pl - godzinowe',
        reportNumberPro: 'Liczba raportów 6',
        reportNumberBasic: 'Liczba raportów 2',
        reportNumberTrial: 'Liczba raportów 4',
        monthlyPlural: 'Miesięczne',
        weeklyPlural: 'Tygodniowe',
        dailyPlural: 'Dzienne',
        hourlyPlural: 'Godzinowe',
        comingSoon: 'Dostępny już wkrótce!',
      },
      featuresSection: {
        title: 'Poznaj funkcjonalności, które pomogą Ci zarządzać wizytówką',
        generatingReport: 'Generowanie raportu',
        generatingReportDescription:
          'W wygenerowanym raporcie zobaczysz aktualną pozycję i widoczność Twojej firmy w zestawieniu z konkurencją.',
        businessCardStatistics: 'Statystyki wizytówek',
        businessCardStatisticsDescription:
          'Na bieżąco będziesz kontrolować wyniki twojego profilu na podstawie których nasz asystent AI podpowie Ci co zrobić aby uzyskiwać jeszcze lepsze wyniki.',
        localPostPlanning: 'Planowanie wpisów, zdjęć',
        localPostPlanningDescription:
          'Ta funkcjonalność pozwoli Ci zaplanować dodawanie zdjęć i wpisów na temat swojej firmy z wyprzedzeniem do nawet 12 miesięcy. Już nigdy nie zapomnisz dodać informacji i promocji którą organizujesz.',
        aiAssistant: 'Asystent AI',
        aiAssistantDescription:
          'Aplikacja umożliwia automatyczne generowanie profesjonalnych wpisów, opisów i odpowiedzi na opinie na profilu firmowym w Google, dzięki wsparciu sztucznej inteligencji ChatGPT. Użytkownicy mogą szybko tworzyć treści dostosowane do potrzeb swojej firmy. Inteligentne narzędzie ułatwia komunikację z klientami, oszczędzając czas i zwiększając zaangażowanie.',
        positioningAssistant: 'Asystent pozycjonowania',
        positioningAssistantDescription:
          'Nasz asystent Pozycjonowania AI wykona za Ciebie wszelkie analizy i wskaże, co zrobić, aby wyprzedzić konkurencję i zdobyć więcej klientów w swojej firmie. Dzięki zaawansowanym algorytmom i stałemu monitorowaniu trendów rynkowych, pomoże Ci podejmować lepsze decyzje w prowadzeniu swojej wizytówki na wyszukiwarce Google, które przełożą się na realny wzrost.',
        seoCards: 'Opinieo',
        seoCardsDescription:
          'Seo Tracking w połączeniu z rozwiązaniami do łatwego zbierania pozytywnych opinii od Twoich klientów dostarczanymi przez Opinieo to duet idealny. Dzięki rozwiązaniom opinieo pozyskasz łatwo i szybko bardzo dużą ilość pozytywnych opinii która będzie budować wysoką reputację Twojej firmy wśród potencjalnych klientów.',
      },
      aboutSection: {
        title: 'Czym jest',
        seoTracking: 'SeoTracking?',
        descriptionFristParagraph:
          'SeoTracking to proste narzędzie, które pomoże Ci zarządzać wizytówką Google tak aby to Twoja firma stała się pierwszym wyborem osób które szukają Twoich usług. Nasze rozwiązanie jest tak zoptymalizowane aby pomóc Ci osiągnąć najwyższe pozycje w wynikach wyszukiwania w możliwie jak najkrótszym czasie.',
        descriptionSecondParagraph:
          'Posiadamy wieloletnie doświadczenie, dzięki któremu wiemy jak sprawić, by Twoja firma była pierwszym wyborem klientów. SeoTracking to narzędzie innowacyjne, niezawodne i łatwe w użyciu.',
      },
      whyIsWorthSection: {
        cardList: {
          firstCard: {
            title: 'Poprawa wizerunku firmy',
            description:
              'Zbuduj profesjonalny wizerunek Twojej firmy poprzez efektywne zarządzanie i optymalizację danych dotyczących Twoich wizytówek Google',
          },
          secondCard: {
            title: 'Wyższe zarobki',
            description:
              'Dzięki skutecznej optymalizacji wizytówek i większej widoczności w Google, przyciągniesz więcej klientów, co bezpośrednio przełoży się na wzrost sprzedaży i zysków Twojej firmy.',
          },
          thirdCard: {
            title: 'Poprawa ocen i opinii',
            description:
              'Dzięki monitorowaniu i zarządzaniu opiniami oraz recenzjami, możesz zadbać o pozytywny wizerunek Twojej firmy w Internecie.',
          },
          fourthCard: {
            title: 'Oszczędność czasu',
            description:
              'Poprzez automatyzację zarządzania wizytówkami Google, zaoszczędzisz cenny czas, który możesz przeznaczyć na rozwój Twojego biznesu.',
          },
          fifthCard: {
            title: 'Aktualne statystyki',
            description:
              'SeoTracking dostarcza najnowsze dane i analizy dotyczące widoczności i interakcji z Twoimi wizytówkami Google, umożliwiając Ci śledzenie efektywności i adaptację strategii na bieżąco.',
          },
        },
      },
    },
    privacyPolicy: {
      title: 'POLITYKA PRYWATNOŚCI',
      generalProvisions: 'Postanowienia ogólne',
      dataController:
        '1. Administratorem danych jest:  Ewelina Wiśniewska, Aleja Armii Krajowej nr 66/369, 42-200 Częstochowa, NIP: 5080017369, REGON: 388610634, tel. 882-825-232',
      contactInfo: [
        '2. Kontakt z Administratorem jest możliwy pod adresem e-mail: rodo@opinieo.pl oraz tel. 882-825-232',
      ],
      dataProcessing:
        '3. Państwa dane osobowe są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchwały dyrektywy 95/46/WE (tzw. RODO) oraz zgodnie z ustawą z dnia 10.05.2018 r. o ochronie danych osobowych (tj. Dz. U. z 2019 r. poz. 1781).',
      dataProtection:
        '4. Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są:',
      dataProtectionList: [
        'przetwarzane zgodnie z prawem,',
        'zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,',
        'merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane,',
        'przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.',
      ],
      automaticallyCollectedData: 'Dane zbierane automatycznie',
      automaticDataCollection:
        'Podczas Państwa wizyty na stronie internetowej opinieo.pl automatycznie zbierane są dane: adres IP, nazwa domeny, typ przeglądarki, typ systemu operacyjnego. Dane te nie pozwalają na jednoznaczne zidentyfikowanie Państwa osoby.',
      noAutomatedProcessing:
        'Administrator nie przetwarza danych osobowych w sposób zautomatyzowany (w tym w formie profilowania) w ten sposób, aby w wyniku takiego zautomatyzowanego przetwarzania mogły zapadać jakiekolwiek decyzje, miały być powodowane inne skutki prawne lub w inny sposób miałyby to istotnie wpływać na Państwa prawa i obowiązki.',
      automatedDecisionMaking:
        'Jeśli Państwa dane będą przedmiotem zautomatyzowanego podejmowania decyzji powodującej skutki prawne lub podobnie istotny wpływ, podejmowanie takich decyzji będzie odbywało się na zasadach określonych w treści klauzuli zgody, o której wyrażenie zwrócimy się w odrębnym oświadczeniu.',
      dataProcessingPurpose: 'Cel i podstawa prawna przetwarzania danych osobowych',
      clientsAndContractors: '1. Klienci i osoby wyznaczone do realizacji umowy',
      contractDataProcessing:
        'W przypadku zawarcia umowy z Administratorem lub zamiaru zawarcia takiej umowy, dane, które przetwarzamy, to informacje niezbędne do realizacji tej umowy. Dane te otrzymaliśmy bezpośrednio od Państwa.',
      dataProcessingPurposeList: [
        'zawarcia i wykonania umowy (art. 6 ust. 1 lit. b RODO),',
        'realizacji obowiązków prawnych spoczywających na Administratorze, w tym w szczególności prowadzenia rachunkowości (art. 6 ust. 1 lit. c RODO),',
        'realizacji prawnie uzasadnionego interesu Administratora, polegającego na zapewnieniu obsługi niezbędnej do zawarcia i realizacji umowy, udzieleniu odpowiedzi na kierowaną korespondencję, utrzymaniu kontaktów biznesowych, jak również na ustaleniu, dochodzeniu i obronie przed roszczeniami (art. 6 ust. 1 lit. f RODO).',
      ],
      dataProvisionVoluntary:
        'Podanie danych osobowych jest dobrowolne, jednak jest niezbędne do nawiązania współpracy i obsługi umowy. Odmowa podania danych osobowych będzie skutkowała brakiem możliwości zawarcia i realizacji umowy lub udzielenia odpowiedzi na Państwa pytanie.',
      dataCategories:
        'Dane osobowe zostały pozyskane od Państwa. Dane osobowe są przetwarzane w zakresie niezbędnym do zawarcia i realizacji umowy. Przetwarzamy następujące kategorie Państwa danych: imię, nazwisko, adres dostawy zamówienia, adres e-mail, nr telefonu, dane dotyczące zamówienia oraz dane do faktury. W przypadku elektronicznej obsługi transakcji – także login, hasło oraz historię zakupów i aktywności.',
      dataRetention:
        'Państwa dane będą przetwarzane przez okres realizacji umowy. Państwa dane będą także przechowywane przez okres wymagany przepisami prawa, w szczególności przepisami o rachunkowości, zaś jeśli jest to uzasadnione – do czasu upływu terminów przedawnienia roszczeń wynikających z zawartej umowy, w zależności, który okres jest dłuższy.',
      correspondents: '2. Korespondujący',
      correspondenceDataProcessing:
        'W przypadku podjęcia kontaktu z Administratorem w innym celu niż zawarcie umowy, Państwa dane, które przetwarzamy, stanowią dane kontaktowe oraz dane wynikające z Państwa pytania. Dane te otrzymaliśmy bezpośrednio od Państwa.',
      correspondencePurpose: 'Państwa dane osobowe przetwarzane są w celu:',
      correspondencePurposeList: [
        'realizacji prawnie uzasadnionego interesu Administratora, polegającego na zapewnieniu obsługi korespondencji, udzielenia odpowiedzi na wynikające z niej pytania, zapewnienia obiegu i archiwizacji dokumentów (art. 6 ust. 1 lit. f RODO).',
      ],
      correspondenceVoluntary:
        'Podanie danych osobowych jest dobrowolne, jednak jest niezbędne, by udzielić odpowiedzi na Państwa pytanie. Odmowa podania danych osobowych będzie skutkowała brakiem możliwości obsługi korespondencji i jej niezwłocznym usunięciem.',
      correspondenceDataCategories:
        'Przetwarzamy następujące kategorie Państwa danych: imię, nazwisko, adres e-mail, nr telefonu i inne dane wskazane w treści zapytania.',
      correspondenceRetention:
        'Dane przetwarzane są przez okresy wskazane przepisami prawa lub przez czas, kiedy ich przechowywanie mieści się w ramach prawnie uzasadnionego celu Administratora regulowanego terminem dochodzenia roszczeń. Okres przechowywania danych stanowiących treść korespondencji jest uzależniony od celu przetwarzania danych, którego dotyczy dana korespondencja.',
      usersAndVisitors: '3. Użytkownicy i odwiedzający sklep internetowy',
      userDataProcessing:
        'W przypadku założenia konta na stronie internetowej Administratora w związku z planowanym korzystaniem z usług elektronicznych, Państwa dane, które przetwarzamy, stanowią dane niezbędne do korzystania z takiej usługi. Dane te otrzymaliśmy bezpośrednio od Państwa.',
      userPurpose: 'Państwa dane osobowe przetwarzane są w celu:',
      userPurposeList: [
        'zapewnienia funkcjonalności strony internetowej i ułatwienia korzystania z serwisu (art. 6 ust. 1 lit. b RODO),',
        'realizacji prawnie uzasadnionego interesu Administratora, polegającego na zapewnieniu obsługi, udzieleniu odpowiedzi na zapytania, polegającego na monitorowaniu funkcjonowania strony, jak również na ustaleniu, dochodzeniu i obronie przed roszczeniami (art. 6 ust. 1 lit. f RODO).',
        'prowadzenia rozliczeń, księgowości i sprawozdawczości finansowej (art. 6 ust. 1 lit. c i f RODO)',
        'realizacji obowiązków wynikających z przepisów prawa (art. 6 ust. 1 lit. c RODO)',
        'realizacji uzasadnionego interesu Administratora polegającego na marketingu własnych produktów i usług (art. 6 ust. 1 lit. f RODO)',
      ],
      userVoluntary:
        'Podanie danych osobowych jest dobrowolne, jednak jest niezbędne, by założyć konto w serwisie. Odmowa podania danych osobowych będzie skutkowała brakiem możliwości założenia konta i korzystania z usług Administratora w tym zakresie.',
      userRetention:
        'Przetwarzamy następujące kategorie Państwa danych: imię, nazwisko, adres dostawy zamówienia, adres e-mail, nr telefonu, dane dotyczące zamówienia, dane do faktury, login, hasło, historie zakupów i aktywności.',
      userRetentionSecondParagraph:
        'Państwa dane osobowe będą przechowywane do czasu wycofania zgody lub wniesienia sprzeciwu, tj. okazania w dowolny sposób, że nie życzą sobie Państwo otrzymywania informacji o naszych usługach. Po wycofaniu zgody lub wyrażeniu sprzeciwu, dane osobowe mogą być przechowywane na potrzeby wykazywania prawidłowości spełnienia obowiązków prawnych spoczywających na Administratorze lub do czasu upływu terminów przedawnienia roszczeń, w zależności, który termin jest dłuższy. W przypadku zawarcia z Administratorem umowy (np. w zakresie świadczenia usług drogą elektroniczną, korzystania ze sklepu internetowego), dane osobowe będą przetwarzane przez czas trwania umowy, a jeśli jest to uzasadnione – do upływu terminów przedawnienia wynikających z niej roszczeń.',
      dataUsage: 'Wykorzystywanie danych',
      dataUsageText: [
        'Państwa dane osobowe będą przetwarzane wyłącznie w zakresie i w celu dozwolonym prawem lub w celu, jaki obejmowała Państwa zgoda.',
        'Dane zbierane automatycznie mogą być użyte do analizy zachowań Użytkowników w Serwisie lub też do zbierania danych demograficznych o Użytkownikach.',
        'Dane zbierane w trakcie korespondencji pomiędzy Państwem a Administratorem będą wykorzystane wyłącznie w celu możliwie poprawnej, pełnej i sprawnej odpowiedzi na Państwa pytanie.',
        'Dane zbierane w celu zawarcia lub realizacji umowy pomiędzy Państwem a Administratorem będą wykorzystane wyłącznie w celu poprawnej i sprawnej obsługi realizacji umowy.',
      ],
      dataRecipients: 'Odbiorcy danych',
      dataRecipientsText: [
        'Dane osobowe będą przekazywane podmiotom przetwarzającym je w imieniu Administratora (w szczególności: podmiotom obsługującym i dostarczającym systemy IT, podmiotom świadczącym usługi księgowe i prawne) i osobom współpracującym, które muszą mieć dostęp do danych, aby wykonywać swoje obowiązki.',
        'W uzasadnionych przypadkach, Administrator może udostępnić Państwa dane: organom wymiaru sprawiedliwości, urzędom skarbowym, podmiotom obsługującym przesyłki lub bankom.',
        'Nie przekazujemy Państwa danych osobowych do państw trzecich i organizacji międzynarodowych, tj. poza Europejski Obszar Gospodarczy.',
        'Państwa dane osobowe mogą być przekazywane do innych podmiotów powiązanych z Administratorem, co zostało przewidziane przez odpowiednie uregulowania prawne łączące te podmioty.',
        'Wszyscy odbiorcy zostali zweryfikowani przez Administratora oraz zobowiązani do przetwarzania Państwa danych zgodnie z obowiązującym prawem lub na podstawie umowy z Administratorem.',
        'W każdym przypadku przekazanie danych innym podmiotom nastąpi wyłącznie w celu, w jakim przetwarzanie danych jest legalne i niezbędne.',
      ],
      dataStorageAndDeletion: 'Przechowywanie i usuwanie danych',
      dataStorageAndDeltionTitle:
        'Administrator maksymalnie ogranicza okres przechowywania danych osobowych i przetwarza je przez niezbędny czas:',
      dataStorageAndDeletionText: [
        'trwania umowy, a także do upływu terminów przedawnienia wynikających z odpowiednich przepisów prawa, tj. terminów przedawnienia zobowiązań podatkowych lub terminów przedawnienia roszczeń cywilnoprawnych',
        'prowadzenia obsługi korespondencji',
        'do momentu cofnięcia uprzednio wyrażonej zgody',
        'do momentu wniesienia sprzeciwu wobec przetwarzania danych osobowych.',
      ],
      dataStorageAndDeletionSubInfo:
        'Po upływie okresu zgodnego z prawem przetwarzania danych osobowych, wszystkie dane osobowe umożliwiające identyfikację Państwa osoby zostaną usunięte.',
      userRights: 'Państwa uprawnienia',
      userRightsText: [
        '1. Jeżeli Administrator przetwarza Państwa dane na podstawie dobrowolnej zgody, zgoda ta może zostać cofnięta w dowolnym momencie. Wyłączną konsekwencją cofnięcia zgody będzie fakt, że Administrator nie będzie mógł dłużej przetwarzać na tej podstawie Państwa danych osobowych. Cofnięcie zgody nie wpływa na legalność przetwarzania danych, które dokonało się przed tym cofnięciem.',
        '2. Przysługuje Państwu prawo dostępu do treści Państwa danych osobowych oraz ich poprawiania, sprostowania, usunięcia lub ograniczenia przetwarzania.',
        '3. Jeżeli znaleźli się Państwo w szczególnej sytuacji, która sprawia, że dalsze przetwarzanie przez nas Państwa danych zagraża Państwa prywatności, możecie Państwo zawiadomić nas o tym fakcie i wezwać do zaprzestania lub ograniczenia przetwarzania.',
        '4. W przypadku, gdy podstawą przetwarzania danych osobowych jest prawnie uzasadniony interes – przysługuje Państwu prawo do wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych osobowych z przyczyn związanych z Państwa szczególną sytuacją. W przypadku, gdy prawnie uzasadniony interes polega na prowadzeniu działań marketingu bezpośredniego – macie Państwo prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania dotyczących Państwa danych osobowych na potrzeby takiego marketingu, bez konieczności uzasadniania swojej decyzji.',
        '5. Przysługuje Państwu prawo do wezwania nas, abyśmy przesłali Państwa dane innemu podmiotowi w postaci, którą odbiorca będzie mógł swobodnie przetwarzać. Prawo to dotyczy tylko tych danych, które od Państwa otrzymaliśmy.',
        '6. Przysługuje Państwu również prawo do wniesienia skargi do organu nadzorczego tj. Prezesa Urzędu Ochrony Danych Osobowych.',
      ],
      cookies: 'Pliki "COOKIES"',
      cookiesText: [
        '1. Strona internetowa Administratora używa plików „cookies”. Brak zmiany po stronie Użytkownika ustawień przeglądarki jest równoznaczny z wyrażeniem zgody na ich użycie.',
        '2. Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia usług na stronie internetowej. W plikach „cookies” znajdują się informacje niezbędne do prawidłowego funkcjonowania strony internetowej, w szczególności tych wymagających autoryzacji.',
        '3. Pliki cookies wykorzystywane są w celu:',
        '- dostosowania zawartości stron internetowych do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych, w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb,',
        '- tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości.',
        '4. W ramach strony internetowej stosowane są rodzaje plików „cookies”:',
        {
          list: [
            '„sesyjne” (session cookies) – pliki tymczasowe, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej),',
            '„stałe” (persistent cookies) – przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików „cookies” lub do czasu ich usunięcia przez Użytkownika.',
          ],
        },
        '5. Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich uprzedni wybór w oknie swojej przeglądarki.',
        '6. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).',
        '7. „Cookies” w żaden sposób nie niszczą ani nie uszkadzają systemu w Państwa komputerze, zapisanych plików, ani też celowo nie wpływają niekorzystnie na jego wydajność lub funkcjonalność. Identyfikacja odbywa się bezosobowo, bezimiennie i są to dane dotyczące jedynie sposobu i formy korzystania z serwisów.',
        '8. Więcej informacji o plikach „cookies” znajduje się pod adresem: <a href="http://wszystkoociasteczkach.pl/" target="_blank">http://wszystkoociasteczkach.pl/</a>',
      ],
    },
    termsOfService: {
      title: 'REGULAMIN SERWISU I SKLEPU INTERNETOWEGO',
      1: {
        title: 'Postanowienia ogólne',
        1.1: 'Niniejszy Regulamin określa zasady świadczenia usług przez Sprzedawcę na rzecz Klientów Serwisu oraz Sklepu Internetowego działających pod adresem: https://seotracking.pl/ a także prawa i obowiązki Klienta i Sprzedawcy.',
        1.2: {
          title: 'Serwis oraz Sklep Internetowy prowadzony jest przez Sprzedawcę.',
          '1.2.1': 'telefonicznie; numer telefonu: 725 733 380;',
          '1.2.2': 'mailowo: adres poczty elektronicznej: kontakt@seotracking.pl;',
          '1.2.3': 'listownie: adres miejscowy do doręczeń: Al. Armii Krajowej 66/369, 42-200 Częstochowa.',
        },
        1.3: 'Niniejszy Regulamin umieszczony jest na stronie internetowej https://seotracking.pl w sposób umożliwiający Klientom jego pozyskanie, odtwarzanie i utrwalanie jego treści. Regulamin jest również udostępniany Klientom przed zawarciem Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        1.4: 'Regulamin kierowany jest do Klientów będącymi Konsumentami, Przedsiębiorcami na prawach konsumenta i Przedsiębiorcami.',
        1.5: 'Zawarte w niniejszym Regulaminie postanowienia dotyczące Konsumenta stosuje się dla Umów o dostarczenie Treści Cyfrowych lub Usług Cyfrowych zawartych również z Przedsiębiorcami na prawach konsumenta, chyba że co innego wynika z Regulaminu - przedmiotowe postanowienia nie dotyczą Przedsiębiorców.',
        1.6: 'Złożenie przez Klienta Zamówienia poczytuje się za złożenie oferty. Do zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych dochodzi w momencie przesłania Klientowi oświadczenia Sprzedawcy o przyjęciu oferty. Wszelkie informacje zawarte w Serwisie i Sklepie Internetowym nie stanowią oferty lecz jedynie zaproszenie do zawarcia umowy.',
        1.7: 'Domniemywa się, że osoba składająca Zamówienie jest uprawniona do reprezentowania Klienta, składania w jego imieniu oświadczeń woli prowadzących do zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        1.8: 'Akceptacja Regulaminu jest dobrowolna, ale konieczna do złożenia Zamówienia i zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
      },
      definitions: {
        title: 'Definicje',
        termsOfService: 'Regulamin',
        termsOfServiceDescription:
          ' - przedmiotowy dokument, który określa zasady świadczenia usług przez Sprzedawcę za pośrednictwem Sklepu Internetowego na rzecz Klientów. Regulamin określa prawa i obowiązki Klienta i Sprzedawcy. W zakresie usług świadczonych drogą elektroniczną niniejszy Regulamin jest regulaminem, o którym mowa w art. 8 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną. Regulamin jest nieodpłatnie udostępniany przed zawieraniem umów na stronie Sklepu Internetowego, a także – na żądanie Klienta – w taki sposób, który umożliwia pozyskanie przez niego, odtwarzanie i utrwalanie treści Regulaminu za pomocą systemu teleinformatycznego',
        seller: 'Sprzedawca ',
        sellerDescription:
          '– Ewelina Wiśniewska, prowadząca działalność gospodarczą pod nazwą Ewelina Wiśniewska, Aleja Armii Krajowej nr 66/369, 42-200 Częstochowa, NIP: 5080017369, REGON: 388610634',
        price: 'Cena',
        priceDescription:
          ' - kwota jaką obowiązany jest zapłacić Klient za zakupione Treści Cyfrowe, Usługi Cyfrowe lub świadczone usługi w ramach Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych. Cena wyrażona jest zawsze w kwocie brutto zawierającej podatek VAT.',
        totalPrice: 'Cena łączna',
        totalPriceDescription:
          ' - Cena wraz z innymi kosztami, na jakie wyraźnie wyraził zgodę Klient, składającymi się na całość kosztów wykonania Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        workingDays: 'Dni robocze',
        workingDaysDescription:
          ' – oznacza dni od poniedziałku do piątku z wyłączaniem dni ustawowo wolnych od pracy oraz sobót.',
        orderForm: 'Formularz Zamówienia',
        orderFormDescription:
          ' – wyodrębniona funkcjonalnie część Serwisu i Sklepu Internetowego umożliwiająca złożenie zamówienia i zawarcie Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        client: 'Klient',
        clientDescription:
          ' – podmiot posiadający pełną zdolność do czynności prawnych (Konsument, Przedsiębiorca na prawach konsumenta oraz Przedsiębiorca), który planuje dokonać lub dokonuje Zamówienia za pośrednictwem Sklepu internetowego oraz na rzecz, którego świadczone są usługi drogą elektroniczną przez Sprzedawcę.',
        account: 'Konto',
        accountDescription:
          ' - funkcjonalność Serwisu i Sklepu Internetowego (zbiór zasobów i uprawnień przypisanych Klientowi w systemie teleinformatycznym, w którym gromadzone są dane podane przez Klienta oraz informacje o złożonych przez niego Zamówieniach w Sklepie Internetowym) dostępna po dokonaniu rejestracji lub logowaniu Klienta.',
        cart: 'Koszyk',
        cartDescription:
          ' – usługa świadczona drogą elektroniczną; formularz stanowiący integralną część systemu zakupowego Sklepu, w której Klient określa i zatwierdza produkt oraz warunki Zamówienia.',
        newsletter: 'Newsletter',
        newsletterDescription:
          ' – bezpłatna usługa świadczona drogą elektroniczną, za pomocą, której Sprzedawca informuje Klienta o nowościach w Sklepie, poprzez okresowe przesyłanie wybranych i stosownie zredagowanych treści w postaci listu elektronicznego.',
        consumer: 'Konsument',
        consumerDescription:
          ' - osoba fizyczna dokonująca z przedsiębiorcą czynności prawnej niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową.',
        entrepreneur: 'Przedsiębiorca',
        entrepreneurDescription:
          ' - osoba fizyczna, osoba prawna lub jednostka organizacyjna niebędąca osobą prawną, której ustawa przyznaje zdolność prawną, prowadząca we własnym imieniu działalność gospodarczą lub zawodową i dokonująca czynności prawnej związanej bezpośrednio z jej działalnością gospodarczą lub zawodową.',
        entrepreneurConsumerRights: 'Przedsiębiorca na prawach konsumenta',
        entrepreneurConsumerRightsDescription:
          ' - osoba fizyczna zawierająca umowę bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści tej umowy wynika, że nie posiada ona dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.',
        onlineStore: 'Sklep Internetowy',
        onlineStoreDescription: ' – serwis internetowy prowadzony poprzez stronę internetową https://seotracking.pl.',
        digitalContent: 'Treści Cyfrowe',
        digitalContentDescription:
          ' - produkt przedstawiony przez Sprzedawcę za pośrednictwem Sklepu Internetowego, mogący być przedmiotem Umowy o dostarczenie Treści Cyfrowych.',
        digitalServices: 'Usługi Cyfrowe',
        digitalServicesDescription:
          ' – usługi pozwalające na wytwarzanie, przetwarzanie, przechowywanie lub dostęp do danych w postaci cyfrowej, wspólne korzystanie z danych w postaci cyfrowej, które zostały przesłane lub wytworzone przez Konsumenta lub innych użytkowników tej usługi, inne formy interakcji za pomocą danych w postaci cyfrowej dostarczane przez Sprzedawcę.',
        digitalContentOrServicesAgreement: 'Umowa o dostarczenie Treści Cyfrowych lub Usług Cyfrowych',
        digitalContentOrServicesAgreementDescription:
          ' – umowa, zawierana z wykorzystaniem środków porozumiewania się na odległość i dotycząca świadczenia usług przez Sprzedawcę, w tym dostarczenia przez Sprzedawcę na rzecz Klienta Treści Cyfrowych lub Usług Cyfrowych za obowiązkiem zapłaty Ceny.',
        electronicServices: 'Usługi elektroniczne',
        electronicServicesDescription:
          ' – wszelkie usługi świadczone przez Sprzedawcę drogą elektroniczną za pomocą Serwisu i Sklepu Internatowego, w tym w szczególności Formularz rejestracji i logowania, Konto, Formularz Zamówienia, Koszyk, Newsletter, wyszukiwarka produktów, wystawianie Komentarzy.',
        order: 'Zamówienie',
        orderDescription:
          ' - oświadczenie woli Klienta składane za pomocą Formularza Zamówienia i zmierzające bezpośrednio do zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych z obowiązkiem zapłaty. Zamówienia można składać drogą elektroniczną poprzez system sprzedażowy Sklepu, 24 godziny na dobę, 7 dni w tygodniu przez cały rok.',
        privacyPolicy: 'Polityka Prywatności',
        privacyPolicyDescription:
          ' – dokument regulujący bezpieczeństwo ochrony prywatności i przetwarzania danych osobowych Klientów. Polityka Prywatności stanowi załącznik do Regulaminu, dostępny na seotracking.pl/politykaprywatnosci',
        civilCode: 'Kodeks cywilny',
        civilCodeDescription: ' - ustawa z dnia 23 kwietnia 1964 roku.',
        tradeInspectionAct: 'Ustawa o Inspekcji Handlowej',
        tradeInspectionActDescription: ' - ustawa z dnia 15 grudnia 2000 roku o Inspekcji Handlowej.',
        consumerRightsAct: 'Ustawa o prawach konsumenta',
        consumerRightsActDescription: ' - ustawa z dnia 30 maja 2014 roku o prawach konsumenta.',
      },
      electronicServices: {
        title: 'Usługi elektroniczne',
        3.1: 'Sprzedawca świadczy na rzecz Klientów Serwisu i Sklepu Internetowego nieodpłatnie (z wyłączeniem ewentualnych kosztów połączenia z siecią internetową lub kosztów połączenia telefonicznego zgodnych z pakietem taryfowym dostawcy usług, z których korzysta Klient) następujące Usługi elektroniczne:',
        '3.1.list': [
          '3.1.1 Formularz rejestracji i logowania',
          '3.1.2 Konto',
          '3.1.3 Formularz Zamówienia',
          '3.1.4 Koszyk',
          '3.1.5 Newsletter',
          '3.1.6 wyszukiwarka produktów',
          '3.1.7 wystawianie Komentarzy',
        ],
        3.2: 'Warunkiem korzystania z Usług elektronicznych jest spełnienie przez Klienta następujących wymagań technicznych:',
        '3.2.list': [
          '3.2.1 komputer lub urządzenie mobilne z dostępem do Internetu;',
          '3.2.2 dostęp do poczty elektronicznej;',
          '3.2.3 przeglądarka internetowa;',
          '3.2.4 dla poprawnego świadczenia usług zalecane jest włączenie w przeglądarce internetowej możliwości zapisu plików Cookies oraz obsługi Javascript.',
        ],
        3.3: 'Korzystanie z Usług elektronicznych może wiązać się powszechnie występującymi zagrożeniami związanymi z przekazywaniem danych do Internetu, w szczególności narażenie się na otrzymanie niezamówionych informacji handlowych, złośliwe oprogramowanie, łamanie haseł, utrata danych oraz ich pozyskanie lub rozpowszechnianie przez osoby nieuprawnione.',
        3.4: 'Sprzedawca stosuje środki techniczne i organizacyjne właściwe i proporcjonalne do stopnia zagrożenia Usług elektronicznych.',
        3.5: 'Sprzedawca jest uprawniona do zablokowania dostępu do Konta lub innych Usług elektronicznych w sytuacji, gdy Klient będzie podejmował działania zmierzające do wyrządzenia Sprzedawcy szkody, a także w przypadku naruszenia przepisów prawa lub postanowień Regulaminu lub z uwagi na względy bezpieczeństwa, w szczególności atak hakerski. Sprzedawca zawiadamia Klienta o tym fakcie i przyczynach zablokowania dostępu na podany przez niego adres.',
      },
      clientAccount: {
        title: 'Konto klienta',
        registration_procedure:
          'W celu założenia Konta i uzyskania statusu zarejestrowanego Klienta, należy dokonać uprzednio procedury rejestracji.',
        registered_client_status:
          'Użytkownik zyskuje status zarejestrowanego Klienta w następstwie wykonania niezbędnych czynności rejestracyjnych w Sklepie Internetowym według interaktywnego formularza. Klient wypełnia formularz stosując się do odpowiednich komunikatów wyświetlanych w formularzu podczas jego uzupełniania (w szczególności Klient podaje swoje prawdziwe dane oraz te oznaczone jako obowiązkowe), a następnie aktywuje przycisk „Dokończ Rejestrację”.',
        true_data_obligation:
          'Klient, używając funkcji formularzy, zobowiązany jest podać swoje prawdziwe dane i uzupełnić pola oznaczone jako obligatoryjne oraz aktualizować dane w przypadku ich zmiany.',
        service_agreement:
          'Z chwilą potwierdzenia dokonania rejestracji zostaje zawarta umowa o świadczenie usługi drogą elektroniczną prowadzenia Konta ze Sprzedawcą na czas nieokreślony.',
        account_termination:
          'Klient może wypowiedzieć umowę prowadzenia Konta w każdym czasie, poprzez wysłanie takiego oświadczenia woli Sprzedawcy. Wypowiedzenie jest skuteczne z chwilą jego otrzymania przez Sprzedawcę. Zgłoszenia takiego można dokonać poprzez skorzystanie z opcji „Usuń konto”.',
        password_protection:
          'W procesie Rejestracji Klient podaje hasło, poprzez które następnie uzyskuje dostęp do Konta. Sprzedawca zachęca do utworzenia silnego hasła, niewykorzystywanego w innych serwisach. Klient ma obowiązek chronić hasło i nie może udostępniać hasła osobom trzecim. Klient ponosi pełną odpowiedzialność za działania osób, którym powierza lub którzy uzyskali dostęp do Konta. Konto jest niezbywalne.',
        terms_acceptance: 'Założenie Konta wymaga akceptacji Regulaminu i Polityki Prywatności.',
        marketing_consent: 'Klient może wyrazić zgodę na otrzymywanie materiałów marketingowych Sprzedawcy.',
        rights_protection:
          'Wszelkie prawa do Serwisu i Sklepu Internetowego przysługują Sprzedawcy i podlegają ochronie prawnej. Wykorzystywanie ich w jakichkolwiek celach bez uprzedniej zgody Sprzedawcy jest zabronione.',
      },
      newsLetter: {
        title: 'Newsletter i usługa komentarzy:',
        newsletter:
          'Usługa subskrypcji Newslettera świadczona jest bezpłatnie przez Sprzedawcę na rzecz Klientów, którzy dobrowolnie wyrażą na to zgodę.',
        newsletter_subscription_procedure:
          'W celu zasubskrybowania Newslettera, Klient wykonuje następujące czynności: (a) zamawia poprzez formularz dostępny na stronie seotracking.pl oraz poprzez podanie swojego adresu poczty elektronicznej (e-mail) usługę Newslettera; (b) dokonuje akceptacji treści Regulaminu oraz wyraża zgodę na przekazywanie informacji handlowych środkami komunikacji elektronicznej związanych z świadczoną usługą Newslettera; (c) uaktywnia przycisk „Zapisz się”; (d).',
        newsletter_service_agreement:
          'Z chwilą „zapisania się”, pomiędzy Sprzedawcą a Klientem zostaje zawarta umowa o świadczeniu usługi Newsletter na czas nieoznaczony.',
        newsletter_termination:
          'Klient może w każdej chwili wypowiedzieć umowę o świadczenie usługi Newslettera poprzez dezaktywację subskrypcji. Rezygnacja z Newslettera następuje poprzez wysłanie takiego oświadczenia woli na adres kontakt@seotracking.pl lub poprzez przycisk „Rezygnuję z subskrypcji” w wiadomości e-mail. Umowa zostanie również rozwiązana, jeżeli Klient usunie Konto.',
        marketing_consent_withdrawal:
          'Zgoda na otrzymywanie informacji handlowych środkami komunikacji elektronicznej wysyłanymi przez Sprzedawcę jest dobrowolna i w każdej chwili Klient może ją wycofać.',
        comments_service:
          'Usługa Komentarzy polega na udostępnieniu Klientom narzędzi teleinformatycznych służących zamieszczaniu na dedykowanych im podstronach, osobistych, subiektywnych opinii Klienta.',
      },
      deliveryElectronicServices: {
        title: 'Formularz zamówienia i warunki zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych:',
        6.1: 'Składać Zamówienia mogą Klienci, którzy mają status zarejestrowanego użytkownika Sklepu Internetowego.',
        6.2: 'Usługi Cyfrowe lub Treści Cyfrowe przeznaczone są dla posiadaczy profili firmowych Google.',
        6.3: 'Klient sporządza Zamówienie poprzez wirtualne dodawanie Treści Cyfrowych lub Usług Cyfrowych do Koszyka. Główne cechy Treści Cyfrowych lub Usług Cyfrowych oraz ich Ceny są określone na stronie internetowej. Skuteczne dodanie powoduje, iż w zakładce Koszyka zmienia się wskaźnik Treści Cyfrowych lub Usług Cyfrowych na liście Zamówienia, którą można dowolnie modyfikować.',
        6.4: 'Po potwierdzeniu wyboru Treści Cyfrowych lub Usług Cyfrowych, Klient wskazuje w Formularzu zamówienia formę płatności. Po skompletowaniu Zamówienia, Klient zatwierdza je oraz przesyła do Sprzedawcy poprzez uaktywnienie przycisku „Zamawiam z obowiązkiem zapłaty ceny”.',
        6.5: 'Każdorazowo, przed wysłaniem Zamówienia do Sprzedawcy, Klientowi wskazywane jest podsumowanie Zamówienia w celu jego potwierdzenia tj. łączna cena i informacje szczegółowe Zamówienia. Przed wysłaniem zamówienia Klient akceptuje Regulamin oraz Politykę Prywatności. Klient ma możliwość otworzyć, pobrać i wydrukować ww. dokumenty.',
        6.6: 'W procesie składania Zamówienia, do chwili uaktywnienia przycisku „Zamawiam z obowiązkiem zapłaty ceny” istnieje możliwość wykrywania i korygowania błędów przez Klienta w Zamówieniu i jego modyfikacji, poprzez system sprzedażowy Sklepu. Po tym momencie, Klient może zmienić Zamówienie, w szczególności skorygować błędy we wprowadzonych danych poprzez bezpośredni, niezwłoczny kontakt ze Sprzedawcą.',
        6.7: 'Zawarcie Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych następuje wraz z przyjęciem Zamówienia do realizacji, co zostanie potwierdzone Klientowi w wiadomości e-mail - Klient otrzyma e-mail z potwierdzeniem przyjęcia do realizacji jednej, kilku lub wszystkich ofert na Treści Cyfrowe lub Usługi Cyfrowe złożone w ramach Zamówienia i potwierdzeniem zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych lub Umów o dostarczenie Treści Cyfrowych lub Usług Cyfrowych w zakresie wskazanym w wiadomości e-mail.',
        6.8: 'W wyniku wykupienia abonamentu na podstawie Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, Klient może:',
        '6.8.list': [
          '6.8.1. uzyskać dostęp do dodatkowych funkcjonalności serwisu;',
          '6.8.2 według własnego uznania, korzystać z darmowego planu lub wykupić odnawialną subskrypcję na okres jednego miesiąca lub jednego roku według aktualnych opłat obowiązujących zgodnie z cennikiem.',
        ],
        6.9: 'W ramach darmowego planu Klient otrzymuje nieograniczony czasowo dostęp do serwisu z dostępem do funkcjonalności zawartych w tymże planie.',
        '6.10':
          'Klient może zakupić jeden abonament na jeden slot dodany przez siebie w ramach swojego Konta. Możliwym jest dodanie do Konta więcej niż jednego slotu, co umożliwi Klientowi wykupienie więcej niż jednego abonamentu. W takiej sytuacji abonamenty będą rozliczane co 30 dni od daty zakupu.',
        6.11: 'Użytkownik może w każdej chwili dodać lub usunąć slot, co jest równoznaczne z anulowaniem (wypowiedzeniem) abonamentu. Anulowanie abonamentu na jeden slot pozostaje bez wpływu na inne aktywowane abonamenty na innych slotach. Po usunięciu slotu, pozostaje on aktywny do końca okresu rozliczeniowego. Usunięcie dodatkowych slotów następuje natychmiastowo, a nadpłata zostaje zaksięgowana.',
        6.12: 'Sprzedawca  informuje, że analizy i wyniki prezentowane są wyłącznie w celach informacyjnych i wymagają umiejętności obsługi profilu firmowego Google. Analizy nie mogą być wykorzystywane jako wskazówki, wytyczne, czy też rekomendacje, a podejmowanie jakichkolwiek działań w oparciu o uzyskane wyniki jest całkowicie dobrowolne. Klient przyjmuje do wiadomości, że Sprzedawca nie ponosi odpowiedzialności za wykorzystanie danych uzyskanych za pośrednictwem serwisu i ich wyniki.',
        6.13: 'Zakazane jest, pod rygorem poniesienia ewentualnej odpowiedzialności cywilnej (w tym odszkodowawczej) lub karnej, w szczególności, zarówno co do całości Treści Cyfrowej lub Usługi Cyfrowej, jak i ich części, bez wyraźnej zgody Sprzedawcy:',
        '6.13.list': [
          '6.13.1 udostępnianie i prezentowanie ich osobom trzecim,',
          '6.13.2 publikowanie niezależnie od formy publikacji,',
          '6.13.3 kopiowanie, powielanie na potrzeby inne niż własny użytek.',
        ],
        6.14: 'Umowa o dostarczenie Treści Cyfrowych lub Usług Cyfrowych zawarta zostaje w języku polskim na warunkach określonych niniejszym Regulaminem.',
        6.15: 'Utrwalanie, zabezpieczanie i udostępnianie Klientowi treści zawartej Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych następuje za pośrednictwem systemu sprzedażowego Sklepu, w tym w Koncie Klienta oraz wysyłana jest na adres e-mail Klienta, podany przez niego podczas składania Zamówienia. Klientowi, który złożył Zamówienie i otrzymał oświadczenie o przyjęciu oferty przez Sprzedawcę zostanie przekazany cyfrowy dokument potwierdzający zawarcie Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych wraz z jej treścią.',
        6.16: 'Dokonywanie Zamówień w Sklepie Internetowym jest możliwe 24 godziny na dobę przez 7 dni w tygodniu.',
      },
      couponCode: {
        title: 'Zasady dotyczące korzystania z kodów rabatowych:',
        description:
          'Kod rabatowy upoważnia do rabatu (zniżki) w wysokości wskazanej przez Sklep Internetowy seotracking.pl Sklep zastrzega sobie prawo do zmiany warunków promocji w dowolnym czasie. Kody rabatowe posiadają określone daty ważności. Po ich upłynięciu nie będzie można skorzystać z kodów.',
        promotion_offer:
          'Oferta promocyjna w postaci kodów rabatowych dotyczy wyłącznie Klientów, którzy w okresie obowiązywania promocji wpisali poprawny kod w polu „Kod rabatowy” oraz potwierdzili go przyciskiem „aktywuj” podczas składania zamówienia. Wpisanie kodu rabatowego w polu spowoduje obniżenie wartości zamawianych produktów o wartość procentową lub pieniężną przekazanego Klientowi Kodu rabatowego.',
        usage_restrictions:
          'Kod rabatowy można wykorzystać wyłącznie przy zakupie produktów nieobjętych innymi promocjami. Kody rabatowe nie łączą się, nie sumują się ze sobą oraz nie mogą być wykorzystywane przy zakupie produktów objętych innymi promocjami (o ile regulamin promocji nie mówi inaczej). W jednym zamówieniu może zostać wykorzystany tylko jeden Kod rabatowy.',
      },
      prices: {
        title: 'Ceny',
        displayed_price:
          'Cena uwidoczniona na stronie Sklepu Internetowego podana jest w złotych polskich i zawiera podatki (ceny brutto).',
        total_order_value:
          'Całkowita wartość Zamówienia (Cena Łączna) podana jest w Formularzu Zamówienia po wyborze formy dostawy oraz formy płatności.',
        order_costs_info:
          'Klient otrzymuje informację o łącznych kosztach Zamówienia obejmujących Cenę oraz koszty dostawy i innych kosztach wraz z e-mailem zawierającym potwierdzenie Zamówienia.',
      },
      payments: {
        title: 'Terminy i sposoby płatności za Treści Cyfrowe i Usługi Cyfrowe:',
        9.1: 'Płatność za Treści Cyfrowe lub Usługi Cyfrowe może zostać dokonana przez Klienta w następujący sposób:',
        '9.1.list': [
          '9.1.1. przelew na konto bankowe Sprzedawcy,',
          '9.1.2. płatność za pośrednictwem zewnętrznych dostawców usług płatniczych,',
        ],
        9.2: 'Sklep Internetowy umożliwia dokonywanie płatności za pośrednictwem zewnętrznych dostawców usług płatniczych takich jak:',
        '9.2.list': [
          '9.2.1. payu.pl - PayU S.A. z siedzibą w Poznaniu (60-166), przy ul. Grunwaldzkiej 18, NIP: 7792308495, KRS: 0000274399',
        ],
        9.3: 'W przypadku wyboru przez Klienta płatności przelewem na konto bankowe Sprzedawcy lub za pośrednictwem zewnętrznych dostawców usług płatniczych, Klient zobowiązany jest do dokonania płatności niezwłocznie, nie później jednak niż w terminie 3 dni kalendarzowych. Brak zapłaty w powyższym terminie będzie skutkował brakiem przyjęcia przez Sprzedawcę Oferty złożonej przez Klienta w ramach Zamówienia.',
        9.4: 'Na każdą sprzedaną Treść Cyfrową lub Usługę Cyfrową wystawiany jest dokument sprzedaży w postaci rachunku, lub faktury na żądanie Klienta. Dokument sprzedaży stanowi potwierdzenie istotnych elementów złożonego Zamówienia.',
        9.5: 'W przypadku wyboru faktury Klient zobowiązany jest do podania numeru NIP oraz innych prawidłowych danych nabywcy - Sprzedawca informuje, że system wystawia faktury automatycznie na podstawie danych wprowadzonych przez Klienta.',
        9.6: 'Zgodnie z art. 106b ust. 5 ustawy o VAT, w przypadku sprzedaży na rzecz podmiotu prowadzącego działalność, faktura do paragonu może zostać wystawiona wyłącznie w sytuacji, gdy na paragonie potwierdzającym dokonanie sprzedaży zostanie zamieszczony NIP nabywcy.',
      },
      delivery: {
        title: 'Czas realizacji Zamówienia oraz dostawa Treści Cyfrowych i Usług Cyfrowych:',
        10.1: 'Treści Cyfrowe i Usługi Cyfrowe opłacone przelewem online są wysyłane do Klienta automatycznie po dokonaniu płatności (dostęp do funkcjonalności serwisu klient uzyskuje od razu po uiszczeniu opłaty), a opłacone przelewem bankowym w ciągu 2 dni roboczych od zaksięgowania wpłaty.',
        10.2: 'Sprzedawca przesyła Klientowi wiadomość e-mail, w której zamieszcza aktywny link do Treści Cyfrowej lub Usługi Cyfrowej.',
        10.3: 'Treść Cyfrową uważa się za dostarczoną w chwili, gdy Treść Cyfrowa lub środek, który pozwala na uzyskanie dostępu do Treści Cyfrowej lub pobranie Treści Cyfrowej, zostały udostępnione Klientowi lub fizycznemu lub wirtualnemu urządzeniu, które Klient wybrał samodzielnie w tym celu, lub gdy Klient lub takie urządzenie, uzyskali do niej dostęp.',
        10.4: 'Usługę Cyfrową uważa się za dostarczoną w chwili, gdy Klient lub fizyczne lub wirtualne urządzenie, które Klient wybrał samodzielnie w tym celu, uzyskali do niej dostęp.',
        10.5: 'W przypadku, gdy Klient poda nieprawidłowy e-mail, wpisze go błędnie albo nie zapewni warunków dostarczalności wiadomości e-mail zgodnie z Regulaminem, ponosi odpowiedzialność za brak dostarczenia Zamówienia Treści Cyfrowej lub Usługi Cyfrowej. Zalecane jest, aby Klient skontaktował się ze Sprzedawcą celem wyjaśnienia sprawy i doprowadzenia do dostarczalności Treści Cyfrowej lub Usługi Cyfrowej.',
      },
      complaint: {
        title: 'Reklamacje - Treści Cyfrowe:',
        11.1: 'Sprzedawca ponosi odpowiedzialność wobec Konsumenta lub Przedsiębiorcy na prawach konsumenta za brak zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych istniejący w chwili ich dostarczenia i ujawniony w ciągu dwóch lat od momentu dostarczenia.',
        11.2: 'Treść Cyfrowa lub Usługa Cyfrowa są zgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, jeżeli zgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych pozostają w szczególności ich:',
        '11.2.list': [
          '11.2.1. opis, rodzaj, ilość, jakość, kompletność, funkcjonalność, kompatybilność, interoperacyjność oraz dostępność wsparcia technicznego i aktualizacji;',
          '11.2.2. przydatność do szczególnego celu, do którego są potrzebne Konsumentowi lub Przedsiębiorcy na prawach konsumenta, o którym Konsument lub Przedsiębiorca na prawach konsumenta powiadomił Sprzedawcę najpóźniej w chwili zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych i który Sprzedawca zaakceptował.',
        ],
        11.3: 'Ponadto Treść Cyfrowa lub Usługa Cyfrowa, aby mogły zostać uznane za zgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, muszą:',
        '11.3.list': [
          '11.3.1. nadawać się do celów, w których zazwyczaj korzysta się z Treści Cyfrowej lub Usługi Cyfrowej tego rodzaju, z uwzględnieniem obowiązujących przepisów prawa, norm technicznych lub dobrych praktyk;',
          '11.3.2. występować w takiej ilości i mieć takie cechy, w tym funkcjonalność, kompatybilność, dostępność, ciągłość i bezpieczeństwo, jakie są typowe dla Treści Cyfrowej lub Usługi Cyfrowej tego rodzaju i których Konsument lub Przedsiębiorca na prawach konsumenta może rozsądnie oczekiwać, biorąc pod uwagę charakter Treści Cyfrowej lub Usługi Cyfrowej oraz publiczne zapewnienia złożone przez Sprzedawcę, jego poprzedników prawnych lub osoby działające w jego imieniu, w szczególności w reklamie lub na etykiecie, chyba że Sprzedawca wykaże, że:',
          {
            '11.3.2.list': [
              '11.3.2.1. nie wiedział o danym publicznym zapewnieniu i oceniając rozsądnie, nie mógł o nim wiedzieć,',
              '11.3.2.2. przed zawarciem Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych publiczne zapewnienie zostało sprostowane z zachowaniem warunków i formy, w jakich publiczne zapewnienie zostało złożone, lub w porównywalny sposób,',
              '11.3.3.3. publiczne zapewnienie nie miało wpływu na decyzję Konsumenta lub Przedsiębiorcy na prawach konsumenta o zawarciu Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
            ],
          },
          '11.3.3. być dostarczane z akcesoriami i instrukcjami, których dostarczenia Konsument lub Przedsiębiorca na prawach konsumenta może rozsądnie oczekiwać;',
          '11.3.4. być zgodne z wersją próbną lub zapowiedzią, które zostały udostępnione Konsumentowi lub Przedsiębiorcy na prawach konsumenta przez Sprzedawcę przed zawarciem Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        ],
        11.4: 'Sprzedawca nie ponosi odpowiedzialności za brak zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, jeżeli Konsument lub Przedsiębiorca na prawach konsumenta, najpóźniej w chwili zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, został wyraźnie poinformowany, że konkretna cecha Treści Cyfrowej lub Usługi Cyfrowej odbiega od wymogów zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych oraz wyraźnie i odrębnie zaakceptował brak konkretnej cechy Treści Cyfrowej lub Usługi Cyfrowej.',
        11.5: 'Jeżeli Treść Cyfrowa lub Usługa Cyfrowa są niezgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, Konsument lub Przedsiębiorca na prawach konsumenta może żądać doprowadzenia do ich zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych. Sprzedawca może odmówić doprowadzenia Treści Cyfrowej lub Usługi Cyfrowej do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, jeżeli doprowadzenie do zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych jest niemożliwe albo wymagałoby nadmiernych kosztów dla Sprzedawcy. Przy ocenie nadmierności kosztów dla Sprzedawcy uwzględnia się wszelkie okoliczności sprawy, w szczególności znaczenie braku zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych oraz wartość Treści Cyfrowej lub Usługi Cyfrowej zgodnych z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych. Sprzedawca doprowadza Treść Cyfrową lub Usługę Cyfrową do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych w rozsądnym czasie od chwili, w której Sprzedawca został poinformowany przez Konsumenta lub Przedsiębiorcę na prawach konsumenta o braku zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, i bez nadmiernych niedogodności dla Konsumenta lub Przedsiębiorcy na prawach konsumenta, uwzględniając ich charakter oraz cel, w jakim są wykorzystywane. Koszty doprowadzenia Treści Cyfrowej lub Usługi Cyfrowej do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych ponosi Sprzedawca.',
        11.6: 'Jeżeli Treść Cyfrowa lub Usługa Cyfrowa są niezgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, Konsument lub Przedsiębiorca na prawach konsumenta może złożyć oświadczenie o obniżeniu ceny albo odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, gdy:',
        '11.6.list': [
          '11.6.1. doprowadzenie do zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych jest niemożliwe albo wymaga nadmiernych kosztów;',
          '11.6.2. Sprzedawca nie doprowadził Treści Cyfrowej lub Usługi Cyfrowej do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
          '11.6.3. brak zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych występuje nadal, mimo że Sprzedawca próbował doprowadzić Treść Cyfrową lub Usługę Cyfrową do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
          '11.6.4. brak zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych jest na tyle istotny, że uzasadnia obniżenie ceny albo odstąpienie od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych bez uprzedniego skorzystania ze środka ochrony określonego w pkt 11.5;',
          '11.6.5. z oświadczenia Sprzedawcy lub okoliczności wyraźnie wynika, że nie doprowadzi on Treści Cyfrowej lub Usługi Cyfrowej do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych w rozsądnym czasie lub bez nadmiernych niedogodności dla Konsumenta lub Przedsiębiorcy na prawach konsumenta.',
        ],
        11.7: 'Obniżona cena musi pozostawać w takiej proporcji do ceny wynikającej z Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, w jakiej wartość Treści Cyfrowej lub Usługi Cyfrowej niezgodnych z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych pozostaje do wartości Treści Cyfrowej lub Usługi Cyfrowej zgodnych z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych. Jeżeli Umowa o dostarczenie Treści Cyfrowych lub Usług Cyfrowych stanowi, że Treść Cyfrowa lub Usługa Cyfrowa są dostarczane w częściach lub w sposób ciągły, przy obniżeniu ceny należy uwzględnić czas, w którym Treść Cyfrowa lub Usługa Cyfrowa pozostawały niezgodne z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        11.8: 'Konsument lub Przedsiębiorca na prawach konsumenta nie może odstąpić od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych, jeżeli Treść Cyfrowa lub Usługa Cyfrowa są dostarczane w zamian za zapłatę ceny, a brak zgodności Treści Cyfrowej lub Usługi Cyfrowej z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych jest nieistotny.',
        11.9: 'W razie odstąpienia od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych Sprzedawca zwraca Cenę nie później niż w terminie 14 dni od dnia otrzymania oświadczenia Konsumenta lub Przedsiębiorcy na prawach konsumenta o odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych lub obniżeniu ceny. Sprzedawca dokonuje zwrotu Ceny przy użyciu takiego samego sposobu zapłaty, jakiego użył Konsument lub Przedsiębiorca na prawach konsumenta, chyba że Konsument lub Przedsiębiorca na prawach konsumenta wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.',
        '11.10':
          'Po odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych Sprzedawca nie może wykorzystywać treści innych niż dane osobowe dostarczone lub wytworzone przez Konsumenta lub Przedsiębiorcę na prawach konsumenta w trakcie korzystania z Treści Cyfrowej lub Usługi Cyfrowej dostarczonych przez Sprzedawcę, z wyjątkiem treści, które:',
        '11.10.list': [
          '11.10.1. są użyteczne wyłącznie w związku z Treścią Cyfrową lub Usługą Cyfrową, które stanowiły przedmiot Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
          '11.10.2. dotyczą wyłącznie aktywności Konsumenta lub Przedsiębiorcy na prawach konsumenta w trakcie korzystania z Treści Cyfrowej lub Usługi Cyfrowej, które stanowiły przedmiot Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
          '11.10.3. zostały połączone przez Sprzedawcę z innymi danymi i nie mogą zostać rozłączone bez nadmiernych trudności;',
          '11.10.4. zostały wytworzone przez Konsumenta lub Przedsiębiorcę na prawach konsumenta wspólnie z innymi Konsumentami lub Przedsiębiorcami na prawach konsumenta, którzy nadal mogą z nich korzystać.',
        ],
        11.11:
          'Oświadczenie o niezgodności Towaru z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych (Reklamacja) może zostać złożona przez Konsumenta lub Przedsiębiorcę na prawach konsumenta:',
        '11.11.list': [
          '11.11.1 pisemnie na adres: Ewelina Wiśniewska ul. Armii Krajowej 66/369 42-200 Częstochowa lub',
          '11.11.2.w formie elektronicznej za pośrednictwem poczty elektronicznej na adres: kontakt@seotracking.pl',
        ],
        11.12: 'Zaleca się podanie w opisie reklamacji:',
        '11.12.list': [
          '11.12.1. informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i daty wystąpienia nieprawidłowości lub braku zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych;',
          '11.12.2. żądania sposobu doprowadzenia do zgodności z Umową o dostarczenie Treści Cyfrowych lub Usług Cyfrowych lub oświadczenia o obniżeniu ceny albo odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych lub innego roszczenia; oraz',
          '11.12.3. danych kontaktowych składającego reklamację - ułatwi to i przyspieszy rozpatrzenie reklamacji przez Sprzedawcy.',
        ],
        11.13:
          'Wymogi podane powyżej są jedynie niewiążącym zaleceniem i nie wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.',
        11.14:
          'Sprzedawca ustosunkuje się do reklamacji niezwłocznie, nie później niż w terminie 14 dni kalendarzowych od dnia jej złożenia na Trwałym nośniku.',
        11.15:
          'Odpowiedzialność Sprzedawcy z tytułu rękojmi za Treści Cyfrowe lub Usługi Cyfrowe wobec Klienta będącego Przedsiębiorcą zostaje wyłączona.',
      },
      judgementSolution: {
        title:
          'Pozasądowe sposoby rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur:',
        12.1: 'Poniższe informacje mają charakter wyłącznie informacyjny i nie stanowią o zobowiązaniu Sprzedawcy do skorzystania z pozasądowych sposobów rozwiązywania sporów',
        12.2: 'Szczegółowe informacje dotyczące możliwości skorzystania przez Klienta będącego Konsumentem z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, oraz pod następującymi adresami internetowymi Urzędu Ochrony Konkurencji i Konsumentów:',
        '12.2.list': [
          '12.2.1. http://www.uokik.gov.pl/spory_konsumenckie.php;',
          '12.2.2. http://polubowne.uokik.gov.pl/;',
          '12.2.3. https://www.biznes.gov.pl/pl/portal/001090',
        ],
        12.3: 'Konsument posiada w szczególności (lecz nie wyłącznie) następujące przykładowe możliwości skorzystania z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:',
        '12.3.list': [
          '12.3.1. Konsument uprawniony jest do zwrócenia się do stałego polubownego sądu konsumenckiego, o którym mowa w art. 37 ust. 1 Ustawy o Inspekcji Handlowej z wnioskiem o rozstrzygnięcie sporu wynikłego z zawartej Umowy.',
          '12.3.2. Konsument uprawniony jest do zwrócenia się do wojewódzkiego inspektora Inspekcji Handlowej, zgodnie z art. 36 ust. 1 Ustawy o Inspekcji Handlowej, z wnioskiem o pozasądowe rozwiązanie sporu między Konsumentem, a Sprzedawcą. Informacja na temat zasad i trybu procedury mediacji prowadzonej przez wojewódzkiego inspektora Inspekcji Handlowej dostępna jest w siedzibach oraz na stronach internetowych poszczególnych Wojewódzkich Inspektoratów Inspekcji Handlowej.',
          '12.3.3. Konsument może złożyć skargę za pośrednictwem platformy internetowej ODR: http://ec.europa.eu/consumers/odr/. Platforma ODR stanowi także źródło informacji na temat form pozasądowego rozstrzygania sporów mogących powstać pomiędzy przedsiębiorcami i Konsumentami.',
          '12.3.4. Konsument może uzyskać pomoc w sprawie rozstrzygnięcia sporu między Konsumentem a Sprzedawcą, korzystając także z bezpłatnej pomocy powiatowego (miejskiego) rzecznika konsumentów lub organizacji społecznej, do której zadań statutowych należy ochrona konsumentów (m.in. Federacja Konsumentów).',
        ],
      },
      withdrawalContract: {
        title: 'Odstąpienie do Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych:',
        13.1: 'Kupujący będący Konsumentem lub Przedsiębiorcą na prawach konsumenta, który zawarł umowę na odległość ma prawo bez podawania przyczyny oraz bez ponoszenia kosztów odstąpić od zawartej Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych w terminie 14 (czternastu) dni od dnia zawarcia Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        13.2: 'Jeżeli Konsument lub Przedsiębiorcą na prawach konsumenta złożył oświadczenie o odstąpieniu od umowy zanim Sprzedawca przyjął jego ofertę, oferta przestaje wiązać.',
        13.3: 'Od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych można odstąpić, składając Sprzedawcy oświadczenie o odstąpieniu od umowy. Złożenie oświadczenia może nastąpić:',
        '13.3.list': [
          '13.3.1. w formie pisemnej - na adres Ewelina Wiśniewska ul. Armii Krajowej 66/369 42-200 Częstochowa',
          '13.3.2. za pośrednictwem poczty elektronicznej na adres: kontakt@seotracking.pl',
        ],
        13.4: 'Do zachowania terminu wystarczy wysłanie oświadczenia przed jego upływem.',
        13.5: 'Wzór oświadczenia o odstąpieniu stanowi załącznik do Regulaminu, jednak skorzystanie z niego nie jest obowiązkowe.',
        13.6: 'Realizacja powyższego uprawnienia nie ma wpływu na pozostałe Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych zawarte w ramach jednego Zamówienia.',
        13.7: 'W przypadku odstąpienia od umowy zawartej na odległość umowę uważa się za niezawartą.',
        13.8: 'Sprzedawca po otrzymaniu oświadczenia o odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych niezwłocznie przesyła Konsumentowi lub Przedsiębiorcy na prawach konsumenta na jego adres e-mail potwierdzenie otrzymania oświadczenia o odstąpieniu od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych.',
        13.9: 'W przypadku odstąpienia od Umowy o dostarczenie Treści Cyfrowych lub Usług Cyfrowych Sprzedawca ma obowiązek niezwłocznie, nie później niż w terminie 14 (czternastu) dni od dnia otrzymania oświadczenia Konsumenta o odstąpieniu od umowy, zwrócić wszystkie dokonane przez Konsumenta albo Przedsiębiorcę na prawach konsumenta płatności.',
        '13.10':
          'Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu zapłaty, jakiego użyto przy dokonywaniu płatności, chyba że Konsument lub Przedsiębiorca na prawach konsumenta wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.',
        13.11:
          'W przypadku odstąpienia od Umowy o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej, Sprzedawca może uniemożliwić Konsumentowi lub Przedsiębiorcy na prawach konsumenta dalsze korzystanie z Treści Cyfrowych lub Usługi Cyfrowej, w szczególności przez uniemożliwienie dostępu do Treści Cyfrowych lub Usług Cyfrowych (np. zablokowanie konta użytkownika), a Konsument lub Przedsiębiorca na prawach konsumenta jest zobowiązany zaprzestać korzystania z tej Treści Cyfrowej lub Usługi Cyfrowej i udostępniania ich osobom trzecim.',
        13.12:
          'W przypadku odstąpienia od Umowy o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej Sprzedawca od dnia otrzymania oświadczenia Konsumenta lub Przedsiębiorcy na prawach konsumenta o odstąpieniu od Umowy o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej nie może wykorzystywać treści innych niż dane osobowe dostarczone lub wytworzone przez Konsumenta lub Przedsiębiorcę na prawach konsumenta w trakcie korzystania z Treści Cyfrowych lub Usługi Cyfrowej dostarczonych przez Sprzedawcę, z wyjątkiem treści, które:',
        '13.12.list': [
          '13.12.1. są użyteczne wyłącznie w związku z Treścią Cyfrową lub Usługą Cyfrową, które stanowiły przedmiot Umowy o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej;',
          '13.12.2. dotyczą wyłącznie aktywności Konsumenta lub Przedsiębiorcy na prawach konsumenta w trakcie korzystania z Treści Cyfrowych lub Usługi Cyfrowej dostarczonych przez Sprzedawcę;',
          '13.12.3. zostały połączone przez Sprzedawcę z innymi danymi i nie mogą zostać z nich wydzielone lub mogą zostać wydzielone jedynie przy nakładzie niewspółmiernych wysiłków;',
          '13.12.4. zostały wytworzone przez Konsumenta lub Przedsiębiorcę na prawach konsumenta wspólnie z innymi Konsumentami lub Przedsiębiorcami na prawach konsumenta, którzy nadal mogą z nich korzystać.',
        ],
        13.13:
          'Z wyjątkiem przypadków, o których mowa powyżej, Sprzedawca na żądanie Konsumenta lub Przedsiębiorcy na prawach konsumenta udostępnia mu treści inne niż dane osobowe, które zostały dostarczone lub wytworzone przez Konsumenta lub Przedsiębiorcę na prawach konsumenta w trakcie korzystania z Treści Cyfrowych lub Usługi Cyfrowej dostarczonych przez Sprzedawcę.',
        13.14:
          'Sprzedawca oświadcza, że prawo do odstąpienia nie przysługuje w odniesieniu do umów wskazanych w art. 38 Ustawy o prawach konsumenta, a w szczególności:',
        '13.14.list': [
          '13.14.1. o świadczenie usług, za które Konsument lub Przedsiębiorca na prawach konsumenta jest zobowiązany do zapłaty ceny, jeżeli Sprzedawca wykonała w pełni usługę za wyraźną i uprzednią zgodą Konsumenta lub Przedsiębiorcy na prawach konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedawcę utraci prawo odstąpienia od umowy, i przyjął to do wiadomości;',
          '13.14.2. przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;',
          '13.14.3. o dostarczanie treści cyfrowych niedostarczanych na nośniku materialnym, za które Konsument lub Przedsiębiorca na prawach konsumenta jest zobowiązany do zapłaty Ceny, jeżeli Sprzedawca rozpoczął świadczenie za wyraźną i uprzednią zgodą Konsumenta lub Przedsiębiorcy na prawach konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez Sprzedawcę utraci prawo odstąpienia od umowy, i przyjął to do wiadomości, a Sprzedawca przekazał Konsumentowi lub Przedsiębiorcy na prawach konsumenta potwierdzenie, o którym mowa w art. 15 ust. 1 i 2 albo art. 21 ust. 1 Ustawy o prawach konsumenta.',
        ],
        13.15:
          'Zawierając Umowę Konsument lub Przedsiębiorca na prawach konsumenta potwierdza, iż zapoznał się z treścią Regulaminu, w tym potwierdza, że został zawiadomiony o prawie odstąpienia od zawartej Umowy o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej.',
      },
      personalData: {
        title: 'Dane osobowe:',
        14.1: 'Administratorem danych osobowych Klientów jest Sprzedawca.',
        14.2: 'Szczegółowe zasady ochrony danych osobowych znajdują się w zakładce „Polityka Prywatności” Sklepu.',
      },
      finalProvisions: {
        title: 'Postanowienia końcowe',
        15.1: 'Niniejszy Regulamin jest dostępny pod adresem internetowym seotracking.pl/regulamin',
        15.2: 'Sprzedawca zastrzega sobie prawo do zmiany Regulaminu z ważnych przyczyn. Zmiany Regulaminu wchodzą w życie w terminie wskazanym przez Sprzedawcę, ale nie krótszym niż 7 (siedem) dni od jego opublikowania na stronie Sklepu i dotyczą Umów o dostarczanie Treści Cyfrowej lub Usługi Cyfrowej zawieranych na przyszłość, po upływie tego terminu.',
        15.3: 'Zamówienia złożone przed wejściem w życie zmian Regulaminu realizowane są zgodnie z dotychczasową treścią Regulaminu.',
        15.4: 'W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie powszechnie obowiązujące przepisy prawa polskiego.',
        15.5: 'Wybór prawa polskiego na podstawie niniejszego Regulaminu nie wyłącza ochrony Konsumenta przyznanej mu na podstawie przepisów, których nie można wyłączyć w drodze umowy pomiędzy Spółką a Konsumentem, które zgodnie z właściwymi regulacjami byłoby właściwe w przypadku braku dokonania wyboru.',
        15.6: 'Wszelkie materiały, w tym elementy grafiki, kompozycja tych elementów, znaki towarowe oraz inne, dostępne w Sklepie stanowią przedmiot praw wyłącznych, w szczególności stanowią przedmiot ochrony praw autorskich i praw własności przemysłowej. Wykorzystywanie materiałów udostępnianych w Sklepie w jakiejkolwiek formie wymaga każdorazowo zgody Sprzedawcy.',
        15.7: 'W zakresie umów zawieranych między Sprzedawcą a Klientami będącymi Przedsiębiorcami, Sprzedawca ponosi odpowiedzialność tylko w przypadku umyślnego wyrządzenia szkody i w granicach rzeczywiście poniesionych strat przez Klienta będącego Przedsiębiorcą.',
        15.8: 'Regulamin wchodzi w życie z dniem 01.09 2024 roku.',
      },
    },
  },
};
