import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import PurchaseFormBusinessProfileDetails from '../PurchaseFormBusinessProfileDetials';
import { ReactComponent as ArrowIcon } from '../../../../../shared/assets/arrow-up-right.svg';
import { priceModelsConfig } from '../../../../LandingPage/modules/Pricing/utils';
import { generateKey } from '../../../../../shared/helpers/helpers';
import PurchasePriceModel from '../PurchasePriceModel';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import Loader from '../../../components/Loader';
import { useNotification } from '../../../../../shared/helpers/notification';
import { subscriptionsConfig } from '../../../helpers/constants';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const PlansContent = ({ onOfferSelect, selectedOffer, summaryClickHandler, currentSubsription }) => {
  const { t } = useTranslation();
  const isRequestedChangePlan = Boolean(currentSubsription);
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [trialAvailable, setTrialAvailable] = useState(false);

  const renderPriceModel = (model, idx) => (
    <PurchasePriceModel
      key={generateKey(model, idx)}
      model={model}
      purchaseMode
      preview
      selectedOffer={selectedOffer}
      onOfferSelect={onOfferSelect}
      onButtonClick={summaryClickHandler}
      currentSubsription={currentSubsription}
    />
  );

  const priceModels = priceModelsConfig(t, true).filter((model) => {
    const isTrialHidden = isRequestedChangePlan || !trialAvailable;
    if (isTrialHidden && model.title.toLowerCase() === subscriptionsConfig.trial) {
      return false;
    }
    return true;
  });

  const fetchTrialStatus = async (controller) => {
    setIsLoading(true);
    try {
      const {
        data: { trialUsed },
      } = await api.post('/subscription/trialStatus', {}, { signal: controller.signal });
      const userTrialAvailable = !trialUsed;
      setTrialAvailable(userTrialAvailable);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    const controller = new AbortController();
    fetchTrialStatus(controller);
    return () => controller.abort();
  }, []);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }
  return (
    <>
      <div className={classes.rowContainer}>
        <PurchaseFormBusinessProfileDetails />
        <a className={classes.navigateLanding} href="/#pricing" target="_blank">
          {t('subscription.showOfferDetails')}
          <ArrowIcon className={classes.icon} />
        </a>
      </div>
      <div className={clsx(classes.priceModelsWrapper, currentSubsription && classes.moveDown)}>
        {priceModels.map(renderPriceModel)}
      </div>
    </>
  );
};

export default PlansContent;
