import dayjs from 'dayjs';
import React from 'react';
import Pill from '../../../../shared/components/Pill';
import { ReactComponent as DownloadIcon } from '../../../../shared/assets/download.svg';
import classes from './styles.module.css';
import IconWrapper from '../../../../shared/components/IconWrapper';
import { getColorByPosition } from '../../helpers/colors';
import { reportAvailableInSubPlan } from '../SetBusinessKeyWords/utils';
import { DATE_FORMAT_DD_MM_YYYY } from '../../helpers/constants';
import { paymentMethodEnum } from '../../modules/Subscription/PaymentMethodItem/utils';
import { SUBSCRIPTION_STATUS_ENUM } from '../../../../shared/helpers/enums';

const { oneTimeForMonth, oneTimeForYear, recurring } = paymentMethodEnum;
const { CANCELED } = SUBSCRIPTION_STATUS_ENUM;
export const config = [
  {
    key: 'createdAt',
    header: 'reportList.reportDate',
    sort: 'created_at',
    render: (val) => dayjs(val).format('DD/MM/YYYY HH:mm'),
    width: 1,
  },
  {
    key: 'keyword',
    header: 'reportList.keyword',
    sort: 'keyword',
    render: (val) => <Pill className={classes.keywordWrap} label={val} color="var(--primary900)" border={false} />,
    width: 1,
  },
  {
    key: 'overallPosition',
    header: 'reportList.position',
    sort: 'min_position',
    render: (val) => (
      <Pill
        className={classes.positionPill}
        label={val || '20+'}
        color={getColorByPosition({ position: val ? val + 1 : 20 })}
        border
      />
    ),
    width: 0.5,
  },
  {
    key: 'net',
    header: 'reportList.grid',
    sort: 'net',
    render: (val) => `${val} x ${val}`,
    width: 0.5,
  },
  {
    key: 'area',
    header: 'reportList.searchArea',
    sort: 'area',
    render: (val) => `${val / 1000} km`,
    width: 1,
  },
  {
    key: 'distance',
    header: 'reportList.pointsDistance',
    sort: 'distance',
    render: (val) => `${val / 1000} km`,
    width: 1,
  },
  {
    key: 'download',
    action: true,
    render: () => (
      <IconWrapper width="20px" Icon={DownloadIcon} color="var(--grey800)" hoverColor="var(--primary500)" />
    ),
    header: '',
    width: '45px',
  },
];

const addCurrentRenewalToRenewalListWhenDifferentThanStartDate = (renewals, currentRenewal, startDate) => {
  if (!currentRenewal.isSame(startDate, 'day')) {
    renewals.push(currentRenewal.format(DATE_FORMAT_DD_MM_YYYY));
  }
};

export const calculateRenewalsUntilExpiry = (startDate, endDate) => {
  const start = dayjs(startDate);
  const expiry = dayjs(endDate);
  const renewals = [];

  let currentRenewal = start;

  while (currentRenewal.isBefore(expiry)) {
    addCurrentRenewalToRenewalListWhenDifferentThanStartDate(renewals, currentRenewal, start);
    currentRenewal = currentRenewal.add(1, 'month');
  }

  const nextMonthIsExpiring = renewals.length === 1;
  return { renewals, nextMonthIsExpiring };
};

export const generateReportLeftDetails = (subscriptionDetails) => {
  if (subscriptionDetails) {
    const { subscription, paymentType, subscriptionDate, subscriptionBuyDate, status, availableReports } = subscriptionDetails;
    const totalReportsInSubPlan = reportAvailableInSubPlan[subscription];
    const reportsUsed = totalReportsInSubPlan - availableReports;
    const isPerYear = paymentType === oneTimeForYear;
    const { renewals, nextMonthIsExpiring } = calculateRenewalsUntilExpiry(subscriptionBuyDate, subscriptionDate);
    const formattedDate = dayjs(subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY);
    const itWillBeExpired = status === CANCELED || paymentType === oneTimeForMonth || nextMonthIsExpiring;
    const properDate = isPerYear ? renewals[0] : formattedDate;
    const message = itWillBeExpired ? 'reportList.reportsCanBeUsedTill' : 'reportList.reportsLimitWillBeRenewed';
    return {
      message,
      date: properDate,
      reportsUsed,
      totalReportsInSubPlan,
      noReportsLeft: availableReports === 0,
      availableReports,
    };
  }
  return {};
};
