import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useAuth } from '../../../../../shared/helpers/auth';
import List from '../../../../../shared/components/List';
import Pagination from '../../../../../shared/components/Pagination';
import {
  parseDataToListColumns,
  SUBSCRIPTION_ACTION_KEY_ENUM,
  subscriptionFilterConfig,
  subscriptionListConfig,
} from './utils';
import { handleApiError } from '../ChangePassword/utils';
import EmptyState from '../../../components/EmptyState';
import { payloadEnum } from '../../../views/AddPost/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { useDashboard } from '../../../helpers/dashboard';
import FilterTools from '../FilterTools';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { SORT_DIRECTION_ENUM } from '../../../../../shared/helpers/const';

const DEFAULT_FILTERS = {
  offset: 0,
  limit: 10,
  search: '',
};

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { LIMIT, OFFSET } = payloadEnum;
const { MANAGE } = SUBSCRIPTION_ACTION_KEY_ENUM;
const SubscriptionSettings = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useAuth();
  const { selectBusinessProfile } = useDashboard();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [subscriptions, setSubscriptions] = useState({ data: [], totalRecords: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({ ...DEFAULT_FILTERS, userId: id });
  const [order, setOrder] = useState({ column: '', direction: '' });

  const { data, totalRecords } = subscriptions;
  const isNoData = !isLoading && !data.length && !error;
  const isFiltersUsed = filters.search || filters.status || filters.subscription || filters.paymentType || filters.dateFrom || filters.dateTo;

  const handleListAction = useCallback((props) => {
    const [action, key, row] = props;
    if (action === MANAGE) {
      selectBusinessProfile(row.bpId);
      navigate('/dashboard/subscription');
    }
  }, []);

  const handleOrderClick = (column) => {
    setOrder((prev) => ({
      column,
      direction: prev.column === column && prev.direction === ASC ? DESC : ASC,
    }));
  };

  const fetchSubscriptionList = useCallback(
    async (controller) => {
      setIsLoading(true);
      setError(null);
      const filtersWithValues = removeNullValues(filters);
      const orderWithValues = removeNullValues(order);
      const payload = { filters: { ...filtersWithValues } };
      const isOrder = orderWithValues.column && orderWithValues.direction;
      if (isOrder) {
        payload.filters.orderBy = orderWithValues;
      }
      try {
        const {
          data: { results, total },
        } = await api.post('/subscription/subscriptionsList', { ...payload }, { signal: controller.signal });
        setSubscriptions({ data: parseDataToListColumns(results), totalRecords: total });
      } catch (err) {
        if (err.code !== 'ERR_CANCELED') {
          setError(err);
          handleApiError({ err, showNotification, t });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [api, filters, order, showNotification, t],
  );

  const handlePaginationChange = (name) => (value) => {
    setFilters((prev) => ({
      ...prev,
      [name]: name === OFFSET ? prev.limit * (value - 1) : value,
    }));
  };

  const handleFiltersChange = (name) => (value) => {
    if (name === OFFSET) {
      setFilters((prev) => ({
        ...prev,
        offset: prev.limit * (value - 1),
      }));
      return;
    }
    if (name === LIMIT) {
      setFilters((prev) => ({
        ...prev,
        limit: value,
        offset: 0,
      }));
      return;
    }
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const page = useMemo(() => Math.floor(filters.offset / filters.limit) + 1, [filters.offset, filters.limit]);

  useEffect(() => {
    const controller = new AbortController();
    fetchSubscriptionList(controller);
    return () => controller.abort();
  }, [filters.limit, filters.offset, filters.search, filters.status, filters.subscription, filters.paymentType, order]);

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{t('settings.subscriptions')}</div>
        {Boolean(data.length) && (
          <a
            className={classes.displaySubscriptionPlan}
            href="/#pricing"
            target="_blank"
            referrerPolicy="no-referrer"
            rel="noreferrer"
          >
            {t('settings.displayPlans')}
          </a>
        )}
      </div>
      <FilterTools
        label={t('settings.searchSubscriptions')}
        config={subscriptionFilterConfig}
        onChange={handleFiltersChange}
        subscription={filters.subscription}
        paymentType={filters.paymentType}
        status={filters.status}
        search={filters.search}
      />
      {isNoData ? (
        <EmptyState
          title={t('settings.noData')}
          subTitle={isFiltersUsed ? t('settings.forSelectedFiltersNoData') : t('settings.noSubscriptionData')}
          buttonLabel={!isFiltersUsed && t('settings.displayPlans')}
          buttonOnClick={() => window.open('/#pricing', '_blank')}
          Icon={null}
        />
      ) : (
        <>
          <List
            onAction={(...props) => handleListAction(props)}
            className={classes.list}
            data={data}
            config={subscriptionListConfig(t)}
            onSort={handleOrderClick}
            sortKey={order.column}
            sortOrder={order.direction}
          />
          <Pagination
            className={classes.pagination}
            page={page}
            total={totalRecords}
            limit={filters.limit}
            onLimitChange={handlePaginationChange(LIMIT)}
            onPageChange={handlePaginationChange(OFFSET)}
          />
        </>
      )}
    </div>
  );
};

export default SubscriptionSettings;
