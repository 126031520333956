import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Stepper from '../../modules/BusinessProfileCreate/Stepper';
import ResignModal from '../../modules/BusinessProfileCreate/ResignModal';
import {
  changeFormDataHandler,
  createCurrentState,
  getNavigationText,
  handleApiErrors,
  initialFormData,
  parseCreateBusinessForm,
  scrollOnFocus,
  STEPPER_ENUM,
  stepsOrderHandler,
} from './utils';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import Loader from '../../components/Loader';
import { formEnum } from '../../modules/BusinessProfileCreate/WelcomeScreenCreate/utils';
import CreateBusinessprofileActionsWrapper from '../../modules/BusinessProfileCreate/CreateBusinessprofileActionsWrapper';
import NavigationMobileScreen from '../../modules/BusinessProfileCreate/NavigationMobileScreen';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const BusinessProfileCreate = () => {
  const navigate = useNavigate();
  const { api } = useApi();
  const { t, i18n } = useTranslation();
  const { showNotification } = useNotification();

  const [activeStep, setActiveStep] = useState(STEPPER_ENUM.welcome);
  const [createBusinessForm, setCreateBusinessForm] = useState(initialFormData);
  const [formMetaData, setFormMetaData] = useState({});
  const [showResignModal, setShowResignModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [readOnlyBtn, setReadOnlyBtn] = useState(false);
  const [businessCreated, setBussinessCreated] = useState(null);

  const servicesAreas = createBusinessForm.serviceArea.places.placeInfos;

  const orderSteps = useMemo(
    () => stepsOrderHandler({ metadata: formMetaData, pickedAreas: servicesAreas, businessCreated }),
    [formMetaData, servicesAreas, businessCreated],
  );
  const activeStepEl = useMemo(
    () => orderSteps.find(({ name }) => name === activeStep),
    [activeStep, formMetaData, servicesAreas, businessCreated],
  );

  const { Form, next, previous, stepperValue, name } = activeStepEl;

  const mobileNavigationText = getNavigationText(name);
  const goNextStepHandler = () => setActiveStep(next);
  const goPreviousStepHandler = () => setActiveStep(previous);
  const rwdNavigationHandler = () => goPreviousStepHandler();
  const readOnlyBtnHandler = useCallback((value) => setReadOnlyBtn(value), [setReadOnlyBtn]);
  const navigateToDashboard = () => navigate('/dashboard');

  const submitFormHandler = async () => {
    setIsLoading(true);
    try {
      const parsedForm = parseCreateBusinessForm({ createBusinessForm });
      const { data } = await api.post('business_profile/createBusinessProfile', {
        businessProfile: parsedForm,
      });
      setBussinessCreated(data);
      showNotification({ type: 'success', message: t('global.success') });
    } catch (error) {
      const errorMessage = handleApiErrors({ error, setActiveStep });
      showNotification({ type: 'error', message: t(errorMessage) });
    } finally {
      setIsLoading(false);
    }
  };

  const { createInProgress, createInitialized } = useMemo(
    () => createCurrentState({ currentStepName: name }),
    [stepperValue],
  );

  const changeDataHandler = useCallback(
    (key) => (value) => changeFormDataHandler({ name: key, value, setter: setCreateBusinessForm }),
    [setCreateBusinessForm],
  );

  const metaDataChangeHandler = useCallback(
    (key) => (value) => setFormMetaData((prev) => ({ ...prev, [key]: value })),
    [setFormMetaData],
  );

  const resignModalBtnHandler = useCallback(() => setShowResignModal((prev) => !prev), [setShowResignModal]);

  useEffect(() => {
    if (businessCreated) {
      setActiveStep(STEPPER_ENUM.verification);
    }
  }, [businessCreated]);

  useEffect(() => {
    setCreateBusinessForm(initialFormData);
    changeDataHandler(formEnum.languageCode)(i18n?.language?.split('-')[0]);
  }, []);

  return (
    <div className={classes.wrapper}>
      {showResignModal && (
        <ResignModal modalHandler={resignModalBtnHandler} navigateToDashboard={navigateToDashboard} />
      )}
      {createInitialized && <Stepper activeStep={stepperValue} />}

      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      {createInitialized && (
        <NavigationMobileScreen
          stepperValue={stepperValue}
          mobileNavigationText={t(mobileNavigationText)}
          rwdNavigationHandler={rwdNavigationHandler}
        />
      )}

      <div className={classes.body}>
        {!createInitialized && <div className={classes.RWDTitle}>{t('businessProfileCreate.title')}</div>}
        <Form
          onBusinessCreate={goNextStepHandler}
          onChange={changeDataHandler}
          onMetaDataChange={metaDataChangeHandler}
          readOnlyHandler={readOnlyBtnHandler}
          scrollOnFocus={scrollOnFocus}
          form={createBusinessForm}
          pickedAreas={servicesAreas}
          metadata={formMetaData}
          businessCreated={businessCreated}
        />

        {createInProgress && (
          <CreateBusinessprofileActionsWrapper
            onResign={resignModalBtnHandler}
            goNextStepHandler={goNextStepHandler}
            goPreviousStepHandler={goPreviousStepHandler}
            submitHandler={submitFormHandler}
            readOnly={readOnlyBtn}
            activeStepName={name}
            creatingInProgress={createInProgress}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessProfileCreate;
