import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import {
  FILTERS_ENUM,
  MIN_SEARCH_LENGTH,
  SORT_COLUMNS,
  activeListCollection,
  columns,
  parseUserDataList,
} from './utils';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../shared/helpers/notification';
import Table from '../../../../shared/components/Table';
import AdminLoader from '../../components/AdminLoader';
import { debounce } from '../../../../shared/helpers/debounce';
import { useModal } from '../../../../shared/helpers/hooks';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import { SORT_DIRECTION_ENUM } from '../../../../shared/helpers/const';
import ViewWrapper from '../../components/ViewWrapper';
import AddAdminModalForm from '../../modules/AdministratorList/AddAdminModalForm';
import SelectAlternative from '../../../../shared/components/Select/SelectAlternative';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const { ASC, DESC } = SORT_DIRECTION_ENUM;
const { login } = SORT_COLUMNS;
const { ACTIVE } = FILTERS_ENUM;

const AdministratorList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isAddAdministratorFormOpen, setAddAdministratorFormOpen, toggleAdminForm] = useModal(false);

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [filters, setFilters] = useState({ limit: 10, offset: 0, search: '', active: null });
  const [orderBy, setOrderBy] = useState({ column: login, direction: ASC });
  const [totalNumber, setTotalNumber] = useState(0);

  const filtersApplied = filters.search !== '' || filters.active !== null;
  const emptyStateTitle = filtersApplied ? t('adminControlPanel.noResults') : t('adminControlPanel.adminListEmpty');
  const currentViewTitle = `${t('adminControlPanel.administrators')} (${totalNumber})`;
  const options = useMemo(() => activeListCollection(t), []);

  const onRowClickHandler = useCallback(
    (row) => {
      const { id } = row;
      navigate(`/admin/administrators/${id}`);
    },
    [navigate],
  );

  const onSelectChangeHandler = (v) => {
    const foundOptionValue = options.find((option) => option.id === v).value;
    setFilters((prev) => ({ ...prev, active: foundOptionValue }));
  };

  const fetchUsers = async (signal = new AbortController().signal) => {
    setIsLoading(true);
    const filtersWithNoNullValue = removeNullValues(filters);
    try {
      const {
        data: { results, total },
      } = await api.post('acp/user/userList', { filters: filtersWithNoNullValue, orderBy }, { signal });
      setUserList(parseUserDataList(results, t));
      setTotalNumber(total);
    } catch (error) {
      handleApiError({ err: error, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  const sortHandler = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prev) => ({ ...prev, direction: prev.direction === ASC ? DESC : ASC }));
    } else {
      setOrderBy({ column, direction: ASC });
    }
  };

  const handleSearchbar = useCallback(
    debounce(async (payload) => {
      if (!payload.value) {
        setFilters((prev) => ({ ...prev, search: '' }));
      }
      if (payload.value.length < MIN_SEARCH_LENGTH) {
        return [];
      }
      setFilters((prev) => ({ ...prev, search: payload.value }));

      return [];
    }, 500),
    [],
  );

  const handleFiltersChange = (name) => (value) => {
    switch (name) {
      case FILTERS_ENUM.SEARCH:
        handleSearchbar({ value });
        break;
      case FILTERS_ENUM.OFFSET:
        setFilters((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
        break;
      case FILTERS_ENUM.LIMIT:
        setFilters((prev) => ({ ...prev, limit: value, offset: 0, search: '' }));
        break;
      case FILTERS_ENUM.ACTIVE:
        setFilters((prev) => ({
          ...prev,
          active: options.find((option) => option.id === value).value,
        }));
        break;
      default:
        setFilters((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };
  const selectValue = useMemo(
    () => options?.find((option) => option.value === filters.active)?.id,
    [options, filters.active],
  );

  const renderActiveFilterBtn = () => (
    <SelectAlternative
      onSelect={handleFiltersChange(ACTIVE)}
      value={selectValue}
      className={classes.select}
      list={options}
      labelStyle={classes.selectLabelStyle}
    />
  );

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    fetchUsers(signal);

    return () => {
      controller.abort();
    };
  }, [orderBy, filters]);

  if (isLoading) {
    return <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />;
  }
  return (
    <>
      {isAddAdministratorFormOpen && <AddAdminModalForm onClose={toggleAdminForm} fetchList={fetchUsers} />}
      <ViewWrapper
        isLoading={isLoading && !userList.length}
        addBtnLabel={t('adminControlPanel.addAdministrator')}
        filters={filters}
        orderBy={orderBy}
        totalRecords={totalNumber}
        title={currentViewTitle}
        onAddButtonClick={toggleAdminForm}
        options={options}
        onFilterSelect={onSelectChangeHandler}
        handleFiltersChange={handleFiltersChange}
        renderAdditionalFilter={renderActiveFilterBtn}
      >
        <Table
          tableBodyStyle={classes.tableCustomBody}
          className={classes.tableWrapper}
          data={userList}
          columns={columns(t)}
          editHandler={onRowClickHandler}
          onSortClick={sortHandler}
          orderBy={orderBy}
          emptyStateTitle={emptyStateTitle}
        />
      </ViewWrapper>
    </>
  );
};
export default AdministratorList;
