import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { buyerDataFormConfig, companyInvoiceDataFormConfig, invoiceTypeEnum, renderInputComponent } from './utils';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useAuth } from '../../../../../shared/helpers/auth';
import Radio from '../../../../../shared/components/Radio';
import Input from '../../../../../shared/components/Input';
import Checkbox from '../../../../../shared/components/Checkbox';
import Loader from '../../../components/Loader';
import { companyNipValidator, emailValidator } from '../../../../../shared/components/Input/validators';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { removeNullValues } from '../../../../../shared/helpers/parsers';
import { ReactComponent as Chevron } from '../../../../../shared/assets/chevronDown.svg';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const { company, personal } = invoiceTypeEnum;

const BuyerDataForm = ({ onCancel, onSuccess, dataExist, editData }) => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const initialData = {
    email: login,
    country: 'Polska',
    address: '',
    city: '',
    postalCode: '',
    defaultInvoice: false,
    secondEmail: editData?.secondEmail ?? '',
  };
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceType, setInvoiceType] = useState(personal);
  const [form, setForm] = useState(initialData);
  const [hasSecondaryEmail, setHasSecondaryEmail] = useState(Boolean(editData?.secondEmail) ?? false);
  const [error, setError] = useState({});

  const translatedFields = invoiceType === personal ? buyerDataFormConfig(t) : companyInvoiceDataFormConfig(t);

  const onInvoiceTypeSelect = (type) => {
    if (type === invoiceType) {
      return;
    }
    setInvoiceType(type);
    setForm(initialData);
  };

  const toggleAdditionalEmailField = () => {
    setHasSecondaryEmail((prev) => !prev);
    if (hasSecondaryEmail) {
      setForm((prev) => {
        const { secondEmail, ...rest } = prev;
        return rest;
      });
      return;
    }
    setForm((prev) => ({ ...prev, secondEmail: '' }));
  };

  const handleInputChange = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const saveInvoiceForm = async () => {
    setIsLoading(true);
    try {
      const formWithData = removeNullValues(form);
      const apiPath = editData ? '/invoice/editUserInvoiceDetails' : '/invoice/addUserInvoiceDetails';
      const apiPayload = editData ? { ...formWithData, id: editData.id } : formWithData;
      await api.post(apiPath, apiPayload);
      onSuccess();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      onCancel();
    }
  };
  const handleCheckbox = () => {
    if (!dataExist) {
      return;
    }
    setForm((prev) => ({ ...prev, defaultInvoice: !prev.defaultInvoice }));
  };
  const handleError = (key) => (err) => {
    if (invoiceType === company) {
      setError((prev) => {
        const newError = { ...prev };
        delete newError.name;
        delete newError.lastName;
        return newError;
      });
    }
    setError((prev) => ({ ...prev, [key]: err }));
  };

  const nipAutoComplete = async (nip) => {
    const { data } = await api.post('/invoice/autocompleteByNip', { nip });
    Object.keys(data).forEach((key) => {
      if (!data[key] || key === 'regon') {
        return;
      }
      setForm((prev) => ({ ...prev, [key]: data[key] }));
    });
  };

  useEffect(() => {
    const { nip } = form;
    const nipIsValid = !companyNipValidator(nip);

    if (nip && nipIsValid && !editData && invoiceType === company) {
      nipAutoComplete(nip);
    }
  }, [form.nip, invoiceType, editData]);

  useEffect(() => {
    if (editData) {
      const { userId, ...restData } = removeNullValues(editData);
      setForm(restData);
      setInvoiceType(editData.nip ? company : personal);
    }
  }, [editData]);

  return (
    <div>
      {isLoading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      <form className={classes.form}>
        <div className={classes.invoiceTypeWrapper}>
          <div
            onClick={() => onInvoiceTypeSelect(personal)}
            className={clsx(
              classes.radioWrapper,
              editData && editData?.nip && editData?.companyName && classes.displayNone,
            )}
          >
            <Radio value={invoiceType === personal} className={classes.radio} />
            <span>{t('invoices.personalInvocie')}</span>
          </div>
          <div
            onClick={() => onInvoiceTypeSelect(company)}
            className={clsx(
              classes.radioWrapper,
              editData && !editData?.nip && !editData?.companyName && classes.displayNone,
            )}
          >
            <Radio value={invoiceType === company} className={classes.radio} />
            <span>{t('invoices.companyInvoice')}</span>
          </div>
        </div>
        {translatedFields.map((field) => renderInputComponent({
          field,
          form,
          onInputChange: () => handleInputChange(field.key),
          onError: handleError,
        }),)}
      </form>
      <p className={classes.emailSubInfo}>{t('invoices.providedEmailInvoiceSend')}</p>
      <Button
        label={t('invoices.addSecondaryEmail')}
        onClick={toggleAdditionalEmailField}
        className={classes.secondaryEmailBtn}
        Icon={Chevron}
        iconStyle={clsx(classes.chevronIconStyle, hasSecondaryEmail && classes.chevronIconStyleActive)}
      />
      {hasSecondaryEmail && (
        <Input
          label={t('form.additionalEmail')}
          validator={emailValidator}
          showErrorAfterBlur
          value={form.secondEmail}
          onChange={handleInputChange('secondEmail')}
          autoCompleteName="email"
          onError={handleError('secondEmail')}
          maxLength={100}
        />
      )}

      <div className={classes.actions}>
        {dataExist && (
          <Checkbox
            label={t('invoices.markAsDefault')}
            labelPosition="right"
            checkboxStyle={clsx(classes.checkboxStyle)}
            className={clsx(classes.checkbox)}
            checkIconStyle={classes.checkIconStyle}
            value={form.defaultInvoice}
            disabled={!dataExist}
            onClick={handleCheckbox}
          />
        )}
        {editData && (
          <div className={classes.changeDataInfo}>
            <CircleInfo className={classes.circleInfo} />
            <div>{t('invoices.editDataInfo')}</div>
          </div>
        )}
        <div className={classes.row}>
          <Button label={t('global.cancel')} className={clsx(classes.btn, classes.cancelBtn)} onClick={onCancel} />
          <Button
            label={editData ? t('invoices.editData') : t('form.saveAddress')}
            className={classes.btn}
            onClick={saveInvoiceForm}
            readOnly={Object.values(error).some((el) => el)}
          />
        </div>
      </div>
    </div>
  );
};

export default BuyerDataForm;
