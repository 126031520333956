import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import ReportLeftCount from '../ReportLeftCount';
import RenewInformation from '../RenewInformation';
import { generateReportLeftDetails } from '../../../views/ReportList/utils';

const AvailableReportsSubHeader = ({ isReadOnlySubPlan, subscriptionDetails }) => {
  const { t } = useTranslation();
  const { date, message, noReportsLeft, reportsUsed, totalReportsInSubPlan, availableReports } = generateReportLeftDetails(subscriptionDetails);

  return (
    <div>
      {isReadOnlySubPlan ? (
        <div className={classes.subHeader}>{t('reportList.subHeader')}</div>
      ) : (
        <div className={clsx(classes.subHeader, classes.outOfReports)}>
          {t('reportList.leftReportsNumber')}
          <ReportLeftCount
            totalReportsInSubPlan={totalReportsInSubPlan}
            noReportsLeft={noReportsLeft}
            reportsUsed={reportsUsed}
            availableReports={availableReports}
          />
          <RenewInformation date={date} message={message} />
        </div>
      )}
    </div>
  );
};

export default AvailableReportsSubHeader;
