import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { EXTERNAL_ADDRESSES } from '../../../../../shared/helpers/const';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/circleInfo.svg';

const SubscriptionSinglePaymentInfo = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.singlePaymentInfo}>
      <div className={classes.leftSideWrapper}>
        <InfoIcon className={classes.infoIcon} />
      </div>
      <div className={classes.rightSideWrapper}>
        <div>{t('subscription.singlePaymentInfoTitle')}</div>
        <div className={classes.rowContainer}>
          <div>{EXTERNAL_ADDRESSES.SUB_HELP_TEL}</div>
          <div>{EXTERNAL_ADDRESSES.SUB_HELP_MAIL}</div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSinglePaymentInfo;
