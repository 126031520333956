import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import { ReactComponent as AiIcon } from '../../../../../shared/assets/aiReply.svg';
import ReviewerBox from '../ReviewerBox';
import Textarea from '../../../../../shared/components/Textarea';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import AiProposal from '../AiProposal';
import Loader from '../../../components/Loader';
import { ANSWER_MAX_LENGTH_FIELD } from '../../../views/Opinions/utils';
import { useDashboard } from '../../../helpers/dashboard';
import AIModels from '../../../../../shared/components/AIModels';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const AnswerWithAiModal = ({ onCancel, opinion }) => {
  const { t } = useTranslation();
  const { businessProfile } = useDashboard();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [aiReply, setAiReply] = useState([]);
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo');
  const [proposalSelected, setProposalSelected] = useState('');

  const fetchAiReply = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/review/getAutomatic', {
        content: opinion.nativeOpinion,
        rating: opinion.rating,
        model: selectedModel,
        businessProfileId: businessProfile.id,
      });
      const isAllSame = data.content.every((val, i, arr) => val === arr[0]);
      setAiReply(isAllSame ? [data.content[0]] : data.content);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  }, [selectedModel]);

  const upsertReply = useCallback(async () => {
    try {
      await api.post('review/upsertReply', {
        businessProfileId: businessProfile.id,
        comment: proposalSelected,
        reviewId: `reviews/${opinion.id}`,
      });
      showNotification({
        label: t('global.success'),
        message: t('opinions.savedCorrectly'),
        type: 'success',
      });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setProposalSelected('');
      onCancel();
    }
  }, [proposalSelected]);

  const selectModelHandler = (model) => {
    setSelectedModel(model);
  };

  const handleChangeProporsal = (value) => {
    setProposalSelected(value);
  };

  const selectProposalHandler = (proposal) => {
    setProposalSelected(proposal);
  };

  const closeModalHandler = () => {
    setProposalSelected('');
    onCancel();
  };

  useEffect(() => {
    fetchAiReply();
  }, []);

  return (
    <Modal
      contentStyle={classes.modalContent}
      modalTitle={t('opinions.replyWithAI')}
      Icon={AiIcon}
      titleStyle={classes.modalTitleStyle}
      onCancel={onCancel}
      onBack={onCancel}
      showArrowBack
    >
      <div className={classes.wrapper}>
        <div className={classes.leftSide}>
          <div className={classes.leftSideTitle}>{t('opinions.generatedWithAiProposition')}</div>
          <AIModels selectModelHandler={selectModelHandler} selectedModel={selectedModel} />
          <div className={classes.proposalWrapper}>
            {isLoading ? (
              <LoaderOverlay customStyle={classes.loader} />
            ) : (
              <AiProposal aiReply={aiReply} onSelect={selectProposalHandler} />
            )}
          </div>
        </div>
        <div className={classes.dividerLine} />
        <div className={classes.rightSide}>
          <ReviewerBox opinion={opinion} selectAllowed={false} />
          <div className={classes.opinionText}>{opinion.nativeOpinion}</div>
          {Boolean(proposalSelected) && (
            <Textarea
              onChange={handleChangeProporsal}
              className={classes.textAreaModal}
              value={proposalSelected}
              labelRised
              maxLength={ANSWER_MAX_LENGTH_FIELD}
              label={t('opinions.submitReply')}
            />
          )}
          <div className={classes.actionsWrapper}>
            <Button onClick={closeModalHandler} className={classes.cancelBtn} label={t('global.cancel')} />
            <Button
              readOnly={!proposalSelected}
              onClick={upsertReply}
              className={classes.addBtn}
              label={t('global.add')}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AnswerWithAiModal;
