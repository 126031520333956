export const showOnListInvoiceData = (value) => {
  if (!value) return '';
  const {
    name = '',
    lastName = '',
    adress = '',
    city = '',
    postalCode = '',
    country = '',
    nip = '',
    companyName = '',
  } = value;
  const commonPart = `${adress} ${city} ${postalCode} ${country}`;
  if (nip.length && companyName.length) {
    return `${companyName} NIP: ${nip} ${commonPart}`;
  }
  return `${name} ${lastName} ${commonPart}`;
};

export const renderAddress = ({ selectedInvoiceData }) => {
  const { adress = '', city = '', postalCode = '', country = '' } = selectedInvoiceData;
  return `${adress} ${city} ${postalCode} ${country}`;
};

export const renderBasicData = ({ selectedInvoiceData }) => {
  const { name = '', lastName = '', nip = '', companyName } = selectedInvoiceData;
  if (nip.length && companyName.length) {
    return `${companyName} NIP: ${nip}`;
  }
  return `${name} ${lastName}`;
};
