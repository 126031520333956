import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';

const RenewInformation = ({ message, date }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.aboutReportsRow}>
        {t(message)}
        <Pill label={date} className={classes.aboutReportPill} />
      </div>
      <div className={classes.subHeader}>{t('reportList.subHeader')}</div>
    </>
  );
};

export default RenewInformation;
