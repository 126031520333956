import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import CategoriesSelect from '../../BusinessProfileEdit/CategoriesSelect';
import { required } from '../../../../../shared/components/Input/validators';
import { ReactComponent as BasicInformationSvg } from '../../../../../shared/assets/step1.svg';
import { informationProvidedCheck, parseLocations, showOnListLocationValue } from './utils';
import { parseStringsWithWhiteSpaces } from '../../../../../shared/helpers/strings';
import { GOOGLE_BUSINESS_TITLE_MAX_LENGTH } from '../../../helpers/constants';
import { useApi } from '../../../../../shared/helpers/api';
import { StateEnum, fetchAccounts } from '../../../views/PickBusinessProfiles/utils';
import Select from '../../../../../shared/components/Select';
import Loader from '../../../components/Loader';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const FormBasicInformation = ({ onChange, form, readOnlyHandler, scrollOnFocus }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const [status, setStatus] = useState(StateEnum.loading);
  const [locations, setLocations] = useState([]);
  const basicInformationProvided = informationProvidedCheck(form);
  const locationsSelectReadOnly = locations.length <= 1;
  const selectedLocation = locations.find((el) => el.selected)?.id;

  const selectLocationHandler = (v) => {
    setLocations((prev) => prev.map((el) => ({ ...el, selected: el.id === v })));
    onChange('googleAccountName')(v);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserLocations = async () => {
      const userLocations = await fetchAccounts(api, controller, setStatus);
      const parsedLocations = parseLocations(userLocations, t);
      setLocations(parsedLocations);
      onChange('googleAccountName')(userLocations[0].id);
    };

    fetchUserLocations();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    readOnlyHandler(!basicInformationProvided);
  }, [form.title, form.categories.primaryCategory]);

  return (
    <div className={classes.wrapper}>
      {status === StateEnum.loading && <LoaderOverlay customStyle={classes.loaderWrapper} />}
      <div className={classes.formContainer}>
        <div className={classes.leftSide}>
          <div className={classes.formTitle}>{t('businessProfileCreate.basicInformationTitle')}</div>
          <div className={classes.formWrapper}>
            <div onFocus={scrollOnFocus}>
              <Select
                showOnList={showOnListLocationValue}
                onSelect={selectLocationHandler}
                label={t('businessProfileCreate.locationsGroup')}
                className={classes.input}
                list={locations}
                readOnly={locationsSelectReadOnly}
                value={selectedLocation}
              />
              <Input
                showErrorAfterBlur
                value={form?.title}
                onChange={onChange('title')}
                className={classes.input}
                label={t('businessProfileCreate.businessName')}
                validator={required(2)}
                transformer={parseStringsWithWhiteSpaces}
                maxLength={GOOGLE_BUSINESS_TITLE_MAX_LENGTH}
              />
            </div>
            <div onFocus={scrollOnFocus}>
              <CategoriesSelect
                validator={required(null)}
                value={form.categories.primaryCategory?.categoryReferenceId || ''}
                label={t('businessProfileCreate.primaryCategory')}
                onCategoryChange={onChange('categories')}
                isEdit
              />
            </div>
            <div className={classes.formSubinfo}>{t('businessProfileCreate.allFieldsRequired')}</div>
          </div>
        </div>
        <div className={classes.rightSide}>
          <BasicInformationSvg />
        </div>
      </div>
    </div>
  );
};

export default FormBasicInformation;
