export const SORT_DIRECTION_ENUM = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const EXTERNAL_ADDRESSES = {
  OPINIEO: 'https://opinieo.pl/karta-nfc/',
  EMAIL: 'kontakt@seotracking.pl',
  PHONE_NUMBER: '+48882825232',
  HELP_FORM: 'https://opinieo.pl/kontakt/',
  SUB_HELP_TEL: '+ 48 567 123 098',
  SUB_HELP_MAIL: 'adminseo@gmail.com',
};

export const PROMISES_STATUS_ENUM = {
  fullfilled: 'fulfilled',
  rejected: 'rejected',
};

export const AUTO_COMPLETE_TYPES = {
  EMAIL: 'email',
  PHONE: 'phone',
  NAME: 'name',
  URL: 'url',
};

export const VOIVODESHIP_PL = [
  { code: 'PL-DS', name: 'dolnośląskie' },
  { code: 'PL-KP', name: 'kujawsko-pomorskie' },
  { code: 'PL-LU', name: 'lubelskie' },
  { code: 'PL-LB', name: 'lubuskie' },
  { code: 'PL-MA', name: 'małopolskie' },
  { code: 'PL-MZ', name: 'mazowieckie' },
  { code: 'PL-OP', name: 'opolskie' },
  { code: 'PL-PK', name: 'podkarpackie' },
  { code: 'PL-PD', name: 'podlaskie' },
  { code: 'PL-PM', name: 'pomorskie' },
  { code: 'PL-SL', name: 'śląskie' },
  { code: 'PL-SK', name: 'świętokrzyskie' },
  { code: 'PL-WN', name: 'warmińsko-mazurskie' },
  { code: 'PL-WP', name: 'wielkopolskie' },
  { code: 'PL-ZP', name: 'zachodniopomorskie' },
];
