import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';

export const generateTrialLabel = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.trialLabel}>
      {t('subscription.trialLabel')}
      <p>
        <Trans i18nKey="subscription.additionalInfoTrialLabel" components={{ bold: <b /> }} />
      </p>
    </div>
  );
};

export const getModelWrapperClass = ({ popular, isComing, preview, isMobile, isCurrent }) => clsx(
  classes.modelWrapper,
  popular && classes.popularWrapper,
  isComing && !preview && classes.comingSoonWrapper,
  isMobile && preview && classes.mobileWrapper,
  isCurrent && classes.currentSubscription,
);

export const getModelTitleClass = ({ popular }) => clsx(classes.modelTitle, popular && classes.popular);

export const getButtonClass = ({ popular }) => clsx(classes.button, popular && classes.popularBtn);
