import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessages, loadPayUScript, optionsForms } from './utils';
import classes from './styles.module.css';
import CardNumberField from '../SecureCardNumberField';
import { useNotification } from '../../../../../shared/helpers/notification';
import CardDetailsField from '../SecureCardDetailsField';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import Button from '../../../../../shared/components/Button';
import { getOfferDetails } from '../PurchaseSummary/utils';
import SecureFormPaymentDetails from '../SecureFormPaymentDetails';
import PurchaseFormBusinessProfileDetails from '../PurchaseFormBusinessProfileDetials';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { ReactComponent as ErrorTriangle } from '../../../../../shared/assets/errorTriangle.svg';

const EXPECTED_FIELDS_COUNT = 3;
const posId = process.env.REACT_APP_PAYU_CLIENT_ID;
const TOKENIZE_TYPE = 'MULTI';

const SecurePaymentForm = ({ selectedOffer, onDataChange, submitFormHandler }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [payuSDK, setPayuSDK] = useState(null);
  const [secureForms, setSecureForms] = useState(null);
  const [tokenizeError, setTokenizeError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initializedFields, setInitializedFields] = useState([]);
  const [fieldBlurred, setFieldBlurred] = useState({ card: true, date: true, cvv: true });
  const [cardToken, setCardToken] = useState(null);
  const paymentDetails = getOfferDetails({ isTrial: false, selectedOffer });
  const errorMessageQuere = getErrorMessages(tokenizeError);
  const isErrorMessageDisplayed = errorMessageQuere.length > 0 && Object.values(fieldBlurred).every((el) => el);
  const handleError = useCallback((field, error) => {
    setTokenizeError((prev) => ({
      ...prev,
      [field]: error,
    }));
  }, []);

  const tokenize = useCallback(async () => {
    if (!secureForms) {
      showNotification({ type: 'error', message: t('subscription.secureFormsError') });
      return;
    }
    if (!posId) {
      return;
    }
    setIsLoading(true);
    try {
      const {
        body: { token },
      } = await secureForms.tokenize(posId, TOKENIZE_TYPE);
      if (token) {
        onDataChange(token);
        setCardToken(token);
      } else {
        showNotification({ type: 'error', message: t('subscription.tokenizationError') });
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  }, [secureForms, posId]);

  useEffect(() => {
    const areFieldsValid = Object.keys(tokenizeError).length === EXPECTED_FIELDS_COUNT
      && Object.values(tokenizeError).every((error) => !error);
    if (areFieldsValid) {
      tokenize();
    }
  }, [tokenizeError, tokenize]);

  useEffect(() => {
    if (!posId) return;
    const loadSecureForm = async () => {
      try {
        await loadPayUScript();
        const sdk = window.PayU(posId, { dev: true });
        setPayuSDK(sdk);
        setSecureForms(sdk.secureForms());
      } catch (error) {
        showNotification({ type: 'error', message: t('subscription.payuSdkError') });
      }
    };

    if (posId && !payuSDK) {
      loadSecureForm();
    }
  }, [posId, payuSDK, showNotification, t]);

  useEffect(() => {
    if (!secureForms) return;

    const fieldsToAdd = ['number', 'date', 'cvv'].filter((field) => !initializedFields.includes(field));

    if (fieldsToAdd.length === 0) return;

    const fields = {};
    fieldsToAdd.forEach((field) => {
      fields[field] = secureForms.add(field, optionsForms);
      fields[field].render(`#payu-card-${field}`);
      fields[field].on('change', (event) => handleError(field, event.error));
      fields[field].on('blur', () => setFieldBlurred((prev) => ({ ...prev, [field]: true })));
      fields[field].on('focus', () => setFieldBlurred((prev) => ({ ...prev, [field]: false })));
    });

    setInitializedFields((prev) => [...prev, ...fieldsToAdd]);
  }, [secureForms, handleError, initializedFields]);

  return (
    <div className={classes.wrapper}>
      {isLoading && <LoaderOverlay customStyle={classes.loader} />}
      <PurchaseFormBusinessProfileDetails />
      <SecureFormPaymentDetails selectedPlan={selectedOffer} paymentDetails={paymentDetails} />
      <section className={classes.container}>
        <div className={classes.title}>{t('subscription.recurringData')}</div>
        <CardNumberField />
        <CardDetailsField />
        {isErrorMessageDisplayed && (
          <div className={classes.errorMessage}>
            <ErrorTriangle className={classes.errorIcon} />
            {errorMessageQuere[0]}
          </div>
        )}
      </section>
      <div className={classes.separator} />
      <Button
        onClick={submitFormHandler}
        readOnly={!cardToken}
        label={t('settings.goToPayment')}
        className={classes.btn}
      />
    </div>
  );
};

export default SecurePaymentForm;
