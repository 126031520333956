import React from 'react';
import classes from './styles.module.css';

const CardNumberField = () => (
  <div className={classes.cardContainer}>
    <div className={classes.payuCardForm} id="payu-card-number" />
  </div>
);

export default CardNumberField;
