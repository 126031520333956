import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import { changePasswordFields } from './utils';
import {
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  isSignUpButtonDisabled,
  passwordErrorsConfig,
} from '../../../../SEO/views/SetPassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import AdminLoader from '../../../components/AdminLoader';
import { ERROR_ENUM } from '../../../helpers/enums';
import { ReactComponent as ShowPasswordIcon } from '../../../../../shared/assets/eyeIcon.svg';
import { ReactComponent as InfoIcon } from '../../../../../shared/assets/circleInfo.svg';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const ChangePasswordModalForm = ({ onClose }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ oldPassword: '', newPassword: '', repeatNewPassword: '' });
  const [showInput, setShowInput] = useState({ oldPassword: false, newPassword: false, repeatNewPassword: false });
  const [inputBlurred, setInputBlurred] = useState({
    oldPassword: false,
    newPassword: false,
    repeatNewPassword: false,
  });

  const errMessage = getErrorMessage({
    password: form.newPassword,
    passwordConfirmation: form.repeatNewPassword,
    passwordConfirmationBlurred: inputBlurred.repeatNewPassword,
  });

  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password: form.newPassword,
    passwordConfirmation: form.repeatNewPassword,
    passwordConfirmationBlurred: inputBlurred.repeatNewPassword,
  });

  const passwordHelperMessage = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  const readOnlyButton = !form.oldPassword.length || isSignUpButtonDisabled({ errMessage, errConfirmationPasswordMessage });
  const inputChangeHandler = (name) => (value) => {
    setForm({ ...form, [name]: value });
  };

  const toggleShowPassword = (name) => () => {
    setShowInput((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const onBlurHandler = (name) => () => {
    setInputBlurred((prev) => ({ ...prev, [name]: true }));
  };

  const onFocusHandler = (name) => () => {
    setInputBlurred((prev) => ({ ...prev, [name]: false }));
  };

  const submitChangePassword = async () => {
    setIsLoading(true);
    try {
      await api.post('/acp/user/changePassword', { oldPassword: form.oldPassword, newPassword: form.newPassword });
      showNotification({ type: 'success', message: t('adminControlPanel.passwordChanged') });
    } catch (err) {
      if (err.response.data.code === ERROR_ENUM.forbiddenLoginPassword) {
        showNotification({ type: 'error', message: t('adminControlPanel.incorrectData') });
        return;
      }
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      modalTitle={t('adminControlPanel.changePassword')}
      titleStyle={classes.modalTitleStyle}
      contentStyle={classes.content}
      customIconStyle={classes.customIconStyle}
      onCancel={onClose}
    >
      {isLoading ? (
        <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />
      ) : (
        <>
          <form className={classes.formWrapper}>
            {changePasswordFields(t).map((field) => (
              <div className={classes.inputContainer} key={field.key}>
                <div className={classes.inputTitle}>{field.label}</div>
                <Input
                  admin
                  type={showInput[field.key] ? 'text' : 'password'}
                  className={classes.input}
                  labelRised
                  placeholder={field.placeholder}
                  onChange={inputChangeHandler(field.key)}
                  onFocus={onFocusHandler(field.key)}
                  onBlur={onBlurHandler(field.key)}
                />
                <ShowPasswordIcon className={classes.eyeIcon} onClick={toggleShowPassword(field.key)} />
              </div>
            ))}
          </form>
          <div className={classes.passwordHelper}>
            <InfoIcon className={classes.infoIcon} />
            {passwordHelperMessage}
          </div>
          <Button
            readOnly={readOnlyButton}
            className={classes.savePasswordBtn}
            label={t('adminControlPanel.saveNewPassword')}
            onClick={submitChangePassword}
          />
        </>
      )}
    </Modal>
  );
};

export default ChangePasswordModalForm;
