import React from 'react';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { ReactComponent as Blik } from '../../../../../shared/assets/smartphone.svg';
import { ReactComponent as Card } from '../../../../../shared/assets/credit-card.svg';
import { ReactComponent as Transfer } from '../../../../../shared/assets/transfer.svg';
import { ReactComponent as InvoiceIcon } from '../../../../../shared/assets/invoice.svg';
import { PAID_BY_ENUM } from '../../../../../shared/helpers/enums';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const { BLIK, CARD, BANK_ACCOUNT } = PAID_BY_ENUM;

export const translatedPaymentMethod = {
  recurring: 'settings.reccuring',
  one_time_1: 'settings.oneTimeMonth',
  one_time_12: 'settings.oneTimeYear',
};
export const actionEnum = {
  completePayment: 'completePayment',
  getInvoice: 'getInvoice',
};

export const paymentStatusEnum = {
  PENDING: 'PENDING',
  CANCELED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  NEW: 'NEW',
};

const getPaymentItemAction = (item) => {
  if ([paymentStatusEnum.PENDING, paymentStatusEnum.NEW].includes(item.status)) {
    return <Button label="Zapłać" className={classes.payBtn} />;
  }
  if (paymentStatusEnum.COMPLETED === item.status) {
    return {
      Icon: <InvoiceIcon className={classes.openLinkIcon} />,
      value: actionEnum.getInvoice,
    };
  }
  return null;
};

const getPaymentDetails = (item) => {
  if (item.paidBy === '') {
    return {
      Icon: '-',
      value: '-',
    };
  }
  if (item.paidBy === BANK_ACCOUNT) {
    return {
      Icon: Transfer,
      value: i18next.t('settings.transfer'),
      type: BANK_ACCOUNT,
    };
  }
  if (item.paidBy === CARD) {
    return {
      Icon: Card,
      value: `${i18next.t('settings.card')} ${item.paidData.slice(11, item.paidData.length)}`,
      type: CARD,
    };
  }
  if (item.paidBy === PAID_BY_ENUM.BLIK) {
    return {
      Icon: Blik,
      value: 'Blik',
      type: BLIK,
    };
  }
  return {
    Icon: '-',
    value: '-',
  };
};

export const paymentHistoryParser = (data, businessProfiles) => data.map((item) => {
  const foundBusinessProfile = businessProfiles.find((profile) => profile.id === item.businessProfileId);

  const details = getPaymentDetails(item);
  const action = getPaymentItemAction(item);
  return {
    ...item,
    order_date: dayjs(item.orderDate).format('DD/MM/YY, HH:mm'),
    connectedBusinessProfile: {
      name: foundBusinessProfile?.name,
      address: foundBusinessProfile?.address,
    },
    orderNo: item.id,
    subscription: item.subscription,
    payment: item.payment,
    amount: item.amount,
    paidBy: details,
    action,
    paymentType: translatedPaymentMethod[item.paymentType],
    status: {
      label: i18next.t(`settings.${paymentStatusEnum[item.status].toLowerCase()}`),
      value: paymentStatusEnum[item.status].toLowerCase(),
    },
  };
});

export const paymentHistoryFiltersConfig = {
  'settings.filtersPlan': [
    { label: 'landingPage.pricingSection.mini', value: 'mini', filterKey: 'subscription' },
    { label: 'landingPage.pricingSection.basic', value: 'basic', filterKey: 'subscription' },
    { label: 'landingPage.pricingSection.pro', value: 'pro', filterKey: 'subscription' },
  ],
  'settings.filtersPaymentType': [
    { label: 'settings.singleMonthly', value: 'one_time_1', filterKey: 'paymentType' },
    { label: 'settings.singleYearly', value: 'one_time_12', filterKey: 'paymentType' },
    { label: 'settings.recurring', value: 'recurring', filterKey: 'paymentType' },
  ],
  'settings.paymentStatus': [
    { label: 'settings.completedPayment', value: 'COMPLETED', filterKey: 'status' },
    { label: 'settings.pending', value: 'PENDING', filterKey: 'status' },
    {
      label: 'settings.rejected',
      value: 'CANCELED',
      filterKey: 'status',
    },
  ],
  'settings.date': [{ label: 'settings.today', value: 'today', filterKey: 'date' }],
};
