import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import List from '../../../../../shared/components/List';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';
import { paymentListConfig } from './utils';
import classes from './styles.module.css';
import { actionEnum } from '../PaymentHistory/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';

const { getInvoice } = actionEnum;

const PaymentHistoryList = ({ data, onSort, sortKey, sortOrder }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvoice = async (id) => {
    setIsLoading(true);
    try {
      const { data: invoice } = await api.post('/invoice/getInvoiceUrl', { paymentId: id });
      if (invoice?.viewUrl) {
        const newWindow = window.open(invoice.viewUrl, '_blank');
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
          navigator.clipboard.writeText(invoice.viewUrl);
          showNotification({ message: t('invoices.popupBlocked'), type: 'warning' });
        }
      }
    } catch (error) {
      showNotification({ message: t('common:error'), type: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleListAction = useCallback((props) => {
    const [_, key, row] = props;
    const { redirectUrl, id } = row;
    if (key.value === getInvoice) {
      fetchInvoice(id);
    } else {
      window.location.href = redirectUrl;
    }
  }, []);

  if (isLoading) {
    return <LoaderOverlay />;
  }
  return (
    <div>
      <List
        className={classes.list}
        data={data}
        config={paymentListConfig(t)}
        onAction={(...props) => handleListAction(props)}
        sortKey={sortKey}
        sortOrder={sortOrder}
        onSort={onSort}
      />
    </div>
  );
};

export default PaymentHistoryList;
