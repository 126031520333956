import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const PaymentRedirectConfirmation = ({ onClose, paymentData }) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(3);

  useEffect(() => {
    const handleTimeout = () => {
      setCount((prev) => {
        if (prev === 1) {
          window.location.href = paymentData?.redirectUri;
          setTimeout(onClose, 0);
          return prev;
        }
        return prev - 1;
      });
    };

    if (count > 0) {
      const timer = setTimeout(handleTimeout, 1000);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [count, paymentData, onClose]);

  return (
    <Modal backdropLocked contentStyle={classes.modalContent} customIconStyle={classes.customIconStyle}>
      <div className={classes.modalWrapper}>
        {t('subscription.warningRedirectPaymentOperator')}
        <LoaderOverlay customStyle={classes.loaderWrapper} />
      </div>
    </Modal>
  );
};

export default PaymentRedirectConfirmation;
