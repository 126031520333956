import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import UserData from '../UserData';
import AccountInformation from '../AccountInformation';
import { useModal } from '../../../../../shared/helpers/hooks';
import DesactivateAccountModal from '../DesactivateAccountModal';
import { useApi } from '../../../../../shared/helpers/api';

const BasicUserData = ({ userData, fetchData, manager = false }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const [showModal, setShowModal, modalHandler] = useModal();
  const [userChangeHistory, setUserChangeHistory] = useState([]);
  const activateBtnLabel = userData.active
    ? t('adminControlPanel.desactivateAccount')
    : t('adminControlPanel.activateAccount');

  const fetchActiveChangeList = async () => {
    const { data } = await api.post('/acp/client/clientActiveChangeList', {
      userId: userData.id,
      filters: {
        limit: 2,
        offset: 0,
      },
    });
    const parsedData = data.results.map((item) => ({
      ...item,
      createdAt: dayjs(item.createdAt).format('DD.MM.YYYY HH:mm'),
      type: item.status
        ? t('adminControlPanel.activateAccountSuccess')
        : t('adminControlPanel.desactivateAccountSuccess'),
    }));
    setUserChangeHistory(parsedData);
  };

  useEffect(() => {
    if (userData.id) {
      fetchActiveChangeList();
    }
  }, [userData.id]);

  return (
    <div className={classes.wrapper}>
      {showModal && <DesactivateAccountModal onClose={modalHandler} userData={userData} fetchData={fetchData} />}
      <UserData userData={userData} manager={manager} />
      <AccountInformation userData={userData} userChangeHistory={userChangeHistory} />
      <div className={classes.rowContainer}>
        <Button className={classes.actionBtn} label={activateBtnLabel} onClick={modalHandler} />
        <Button label={t('adminControlPanel.suspendAccount')} className={classes.actionBtn} readOnly />
      </div>
    </div>
  );
};

export default BasicUserData;
