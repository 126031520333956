import { subscriptionsConfig } from '../../../helpers/constants';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

const { recurring } = paymentMethodEnum;
const { trial } = subscriptionsConfig;

export const getDisplayCondition = (subscription, paymentType) => {
  const isTrial = subscription === trial;
  const isReccuring = paymentType === recurring;
  return {
    isSinglePaymentInformationDisplayed: paymentType !== recurring && !isTrial,
    isSubscriptionActionsDisplayed: !isTrial && isReccuring,
  };
};
