import React from 'react';
import BasicUserData from '../../modules/UserDetails/BasicUserData';
import UserBusinessCardList from '../../modules/UserDetails/UserBusinessCardList';
import UserSeoCardList from '../../modules/UserDetails/UserSeoCardList';

export const USER_DETAILS_NAVIGATION_KEY_ENUM = {
  basicData: 'basicData',
  businessCards: 'businessCards',
  seoCards: 'seoCards',
};
export const USER_LIST_TABS = (t, businessProfiles, cardDataTotalNumber) => {
  const basicData = 'basicData';
  const businessCards = 'businessCards';
  const seoCards = 'seoCards';

  return [
    { label: t('adminControlPanel.basicData'), key: basicData },
    { label: t('adminControlPanel.businessCards', { value: businessProfiles }), key: businessCards },
    { label: t('adminControlPanel.SEOcards', { value: cardDataTotalNumber }), key: seoCards },
  ];
};

export const componentMap = (userData, fetchData, cardData) => {
  const { basicData, businessCards, seoCards } = USER_DETAILS_NAVIGATION_KEY_ENUM;
  return {
    [basicData]: <BasicUserData userData={userData} fetchData={fetchData} manager />,
    [businessCards]: <UserBusinessCardList userId={userData.id} />,
    [seoCards]: <UserSeoCardList userData={userData} />,
  };
};

export const calculateFullName = (userData) => (userData.name && userData.lastName ? `${userData.name} ${userData.lastName}` : userData.login);
