import React from 'react';
import BusinessCardsSettings from '../../modules/Settings/BusinessCardsSettings';
import ChangePassword from '../../modules/Settings/ChangePassword';
import MyAccount from '../../modules/Settings/MyAccount';
import InvoicesData from '../../modules/Settings/InvoicesData';
import SubscriptionSettings from '../../modules/Settings/SubscriptionSettings';
import PaymentHistory from '../../modules/Settings/PaymentHistory';

export const PATH_ENUM = {
  PROFILE: '/settings/profile',
  PASSWORD: '/settings/password',
  MY_BUSINESS_CARDS: '/settings/my_business_cards',
  SUBSCRIPTIONS: '/settings/subscriptions',
  PAYMENT_HISTORY: '/settings/payment-history',
  INVOICES_DATA: '/settings/invoices-data',
};

export const translatedTabs = (t, businessProfiles) => {
  const tabs = [
    {
      label: t('settings.myAccount'),
      path: PATH_ENUM.PROFILE,
    },
    {
      label: t('settings.password'),
      path: PATH_ENUM.PASSWORD,
    },
  ];

  if (businessProfiles.length) {
    tabs.push(
      {
        label: t('settings.myBusinessCards'),
        path: PATH_ENUM.MY_BUSINESS_CARDS,
      },
      {
        label: t('settings.invoicesData'),
        path: PATH_ENUM.INVOICES_DATA,
      },
      {
        label: t('settings.paymentHistory'),
        path: PATH_ENUM.PAYMENT_HISTORY,
      },
      {
        label: t('settings.subscriptions'),
        path: PATH_ENUM.SUBSCRIPTIONS,
      },
    );
  }

  return tabs;
};

export const TAB_COMPONENTS = {
  '/settings/my_business_cards': <BusinessCardsSettings />,
  '/settings/profile': <MyAccount />,
  '/settings/password': <ChangePassword />,
  '/settings/invoices-data': <InvoicesData />,
  '/settings/payment-history': <PaymentHistory />,
  '/settings/subscriptions': <SubscriptionSettings />,
};
