import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useDashboard } from '../../helpers/dashboard';
import { readOnlySubList } from '../../helpers/constants';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { TAB_ENUM } from '../AddPost/utils';
import MediaHeading from '../../modules/Media/MediaHeading';
import MediaItem from '../../modules/Media/MediaItem';
import Loader from '../../components/Loader';
import { removeNullValues } from '../../../../shared/helpers/parsers';
import { useModal } from '../../../../shared/helpers/hooks';
import AddMediaModal from '../../modules/Media/AddMediaModal';
import MediaEmptyState from '../../modules/Media/MediaEmptyState';
import ConfirmDeleteModal from '../../modules/BusinessProfileEdit/ConfirmDeleteModal';
import { getOnDeletePayload, parsePostponedMedia, parseToGalleryMediaItem } from './utils';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const MockedPhotoLoader = () => (
  <div className={classes.mockedPhotoLoader}>
    <Loader />
  </div>
);

const MediaView = ({ adminPreview, userId, businessProfileId }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { id, subscription },
  } = useDashboard();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchingMoreItems, setFetchingMoreItems] = useState(false);
  const [mediaItems, setMediaItems] = useState({ published: [], planned: [], publishedTotal: 0, nextPageToken: null });
  const [selectedTab, setSelectedTab] = useState(TAB_ENUM.PUBLISHED);
  const [categoryMediaItemToChange, setCategoryMediaItemToChange] = useState();
  const [showFormModal, setShowFormModal, toggleFormModal] = useModal();
  const [showConfirmationModal, setShowConfirmationModal, toggleConfirmationModal] = useModal();
  const [selectedItems, setSelectedItems] = useState([]);

  const observerRef = useRef();
  const isReadOnlySubPlan = readOnlySubList.includes(subscription);

  const isEmptyStateDisplayed = useMemo(
    () => !mediaItems[selectedTab].length && !isLoading,
    [mediaItems, selectedTab, isLoading],
  );

  const tabSelectHandler = useCallback((tab) => setSelectedTab(tab), []);

  const fetchMediaItems = useCallback(
    async (nextPageToken = null) => {
      setIsLoading(true);
      let apiPath = '/media/mediaList';
      if (adminPreview) {
        apiPath = '/acp/client/mediaList';
      }
      try {
        const payloadWithValues = removeNullValues({ businessProfileId: id, limit: 24, nextPageToken });
        if (adminPreview) {
          payloadWithValues.userId = userId;
          payloadWithValues.businessProfileId = businessProfileId;
        }

        const {
          data: { actualMedia, postponedMedia },
        } = await api.post(apiPath, payloadWithValues);
        const { mediaItems: fetchedMedia, totalMediaItemCount } = actualMedia;
        const { media: postponedMediaList, count } = postponedMedia;

        setMediaItems((prev) => {
          const uniquePublished = new Set(nextPageToken ? [...prev.published, ...fetchedMedia] : fetchedMedia);
          const uniquePlanned = new Set(nextPageToken ? [...prev.planned, ...postponedMediaList] : postponedMediaList);

          return {
            published: Array.from(uniquePublished),
            planned: Array.from(uniquePlanned),
            publishedTotal: totalMediaItemCount,
            publishedMediaCount: count,
            nextPageToken: actualMedia.nextPageToken,
          };
        });
      } catch (err) {
        handleApiError({ err, showNotification, t });
      } finally {
        setIsLoading(false);
      }
    },
    [adminPreview, userId, businessProfileId, id, api, showNotification, t],
  );

  const parsedActualMedia = useMemo(() => parseToGalleryMediaItem(t, mediaItems.published), [t, mediaItems.published]);
  const parsedPostponedMedia = useMemo(() => parsePostponedMedia(t, mediaItems.planned), [t, mediaItems.planned]);

  const mediaToDisplay = selectedTab === TAB_ENUM.PUBLISHED ? parsedActualMedia : parsedPostponedMedia;

  const onDeleteItem = async (e, item) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await api.post('/media/delete', { businessProfileId: id, name: [item.name] });
      setMediaItems({ published: [], planned: [], total: 0, nextPageToken: null });
      await fetchMediaItems();
      showNotification({ type: 'success', message: t('media.mediaDeleted') });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCategoryItemType = (e, item) => {
    e.stopPropagation();
    toggleFormModal();
    setCategoryMediaItemToChange(item.locationAssociation.category);
  };

  const selectMediaHandler = (item) => {
    setSelectedItems((prev) => (prev.includes(item) ? prev.filter((selectedItem) => selectedItem !== item) : [...prev, item]),);
  };

  const deleteImageHandler = async (e, file = { name: '', publishDate: null, id: 0 }, deleteCollection = false) => {
    const payload = getOnDeletePayload(id, deleteCollection, selectedItems, file);
    e.stopPropagation();
    setIsLoading(true);
    try {
      await api.post('media/delete', payload);
      if (deleteCollection) setSelectedItems([]);
      showNotification({ type: 'success', message: t('businessProfileEdit.mediaDeleteMediaSuccess') });
      await fetchMediaItems();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSelectedItemHandler = (e) => deleteImageHandler(e, {}, true);

  const lastMediaElementRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && mediaItems.nextPageToken) {
          setFetchingMoreItems(true);
          await fetchMediaItems(mediaItems.nextPageToken);
          setFetchingMoreItems(false);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isLoading, mediaItems.nextPageToken, fetchMediaItems],
  );

  useEffect(() => {
    if (id || userId) {
      fetchMediaItems();
    }
  }, [id, userId]);

  useEffect(() => {
    if (selectedItems.length) setSelectedItems([]);
  }, [selectedTab]);

  useEffect(() => {
    if (!showFormModal) {
      setCategoryMediaItemToChange(null);
    }
  }, [showFormModal]);

  return (
    <div className={clsx(classes.wrapper, adminPreview && classes.adminWrapper)}>
      {isLoading && <LoaderOverlay customStyle={classes.customLoaderWrapper} />}

      {showConfirmationModal && (
        <ConfirmDeleteModal onClose={toggleConfirmationModal} onConfirm={deleteSelectedItemHandler} />
      )}
      <MemoizedMediaHeading
        onTabSelect={tabSelectHandler}
        publishedMediaCount={mediaItems.publishedTotal}
        plannedMediaCount={mediaItems.publishedMediaCount}
        selectedTab={selectedTab}
        adminPreview={adminPreview}
        toggleFormModal={toggleFormModal}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        toggleConfirmationModal={toggleConfirmationModal}
      />
      {isEmptyStateDisplayed && (
        <MediaEmptyState adminPreview={adminPreview} onAddMedia={toggleFormModal} selectedTab={selectedTab} />
      )}
      {showFormModal && (
        <AddMediaModal
          handleModal={toggleFormModal}
          categoryMediaItemToChange={categoryMediaItemToChange}
          onSuccess={fetchMediaItems}
          busienssId={id}
        />
      )}
      <div className={classes.mediaItemList}>
        {Boolean(mediaToDisplay.length)
          && mediaToDisplay.map((mediaItem, index) => {
            const isLastItem = mediaToDisplay.length === index + 1;
            const mediaItemComponent = (
              <MemoizedMediaItem
                index={index}
                key={mediaItem.name}
                item={mediaItem}
                selectedItems={selectedItems}
                galleryCollection={mediaToDisplay}
                onDelete={onDeleteItem}
                onSuccess={fetchMediaItems}
                onSelect={selectMediaHandler}
                totalMediaCount={mediaItems.publishedTotal}
                changeCategoryItemType={handleChangeCategoryItemType}
                adminPreview={adminPreview}
              />
            );
            return isLastItem ? (
              <div ref={lastMediaElementRef} key={mediaItem?.name}>
                {mediaItemComponent}
              </div>
            ) : (
              mediaItemComponent
            );
          })}
        {fetchingMoreItems && <MockedPhotoLoader />}
      </div>
    </div>
  );
};

const MemoizedMediaItem = React.memo(MediaItem);
const MemoizedMediaHeading = React.memo(MediaHeading);

export default MediaView;
