import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useModal } from '../../../../../shared/helpers/hooks';
import ChangeInvoiceDataModal from '../ChangeInvoiceDataModal';
import { ReactComponent as Pin } from '../../../../../shared/assets/pin.svg';
import { ReactComponent as CircleInfoIcon } from '../../../../../shared/assets/circleInfo.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/edit.svg';

const ReccuringSubscriptionBuyerData = ({ refetchFn, data, invoiceData = [] }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isOpenInvoiceModal, setIsOpenInvoiceModal, toggleInvoiceModal] = useModal();
  const isChangeInvoiceBtnVisible = invoiceData?.length > 1;
  const { name, lastName, email, secondEmail, country, address, city, postalCode, nip, companyName, phone = '' } = data;

  const isPersonal = !nip && !companyName;

  const handleEditInvoice = () => {
    navigate('/dashboard/settings/invoices-data');
  };

  return (
    <div className={classes.wrapper}>
      {isOpenInvoiceModal && (
        <ChangeInvoiceDataModal
          onClose={toggleInvoiceModal}
          invoiceData={invoiceData}
          currentInvoiceData={data}
          refetchFn={refetchFn}
        />
      )}
      <div className={classes.headerWrapper}>
        <div className={classes.leftSideRow}>
          <Pin className={classes.pinIcon} />
          <div>
            <div className={classes.planDetails}>
              <div className={classes.title}>{t('subscription.buyerDataForSubscription')}</div>
            </div>
          </div>
          {isChangeInvoiceBtnVisible && (
            <Button label={t('global.change')} className={classes.changeButton} onClick={toggleInvoiceModal} />
          )}
        </div>
      </div>
      <div>
        <div className={classes.invoiceType}>
          <CircleInfoIcon className={classes.infoIcon} />
          {isPersonal ? t('invoices.personalInvoiceType') : t('invoices.companyInvoiceType')}
          <Button
            className={classes.editInvoiceBtn}
            label={t('global.edit')}
            Icon={EditIcon}
            iconStyle={classes.editInvoiceBtnIcon}
            onClick={handleEditInvoice}
          />
        </div>
        {isPersonal ? (
          <div className={classes.invoiceTitle}>{`${name} ${lastName}`}</div>
        ) : (
          <div className={classes.invoiceTitle}>
            {`${companyName} `}
            <div>{`NIP ${nip}`}</div>
          </div>
        )}
        <div className={classes.address}>
          <div>{country}</div>
          <div>{`${postalCode} ${city}`}</div>
          <div>{address}</div>
        </div>
        <div className={classes.contactData}>
          <div>{email}</div>
          <div>{secondEmail}</div>
          <div>{phone}</div>
        </div>
      </div>
    </div>
  );
};

export default ReccuringSubscriptionBuyerData;
