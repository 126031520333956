import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AdminPagination from '../../../components/AdminPagination';
import Table from '../../../../../shared/components/Table';
import { isButtonEnabled, seoCardListTableConfig } from './utils';
import AdminLoader from '../../../components/AdminLoader';
import classes from './styles.module.css';
import ExpandedSeoCardRow from '../ExpandedSeoCardRow';
import { FILTERS_ENUM } from '../../../views/AdministratorList/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { exportCSVDataHandler, prepareExportFilters } from '../../../helpers/utils';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { ROLE_ENUM } from '../../../views/AdministratorDetails/utils';
import { useAdmin } from '../../../helpers/admin';
import LoaderOverlay from '../../../../../shared/components/LoaderOverlay';

const { LIMIT, OFFSET } = FILTERS_ENUM;

const SeoCardList = ({
  filters,
  orderBy,
  isLoading,
  seoCardList,
  sortHandler,
  cardsTotalAmount,
  handleFiltersChange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const { role } = useAdmin();

  const readOnlyExportBtn = !isButtonEnabled(role, seoCardList);

  const onExpandRowRender = (row) => <ExpandedSeoCardRow rowData={row.links} />;

  const onCardDetailsNavigate = (row) => {
    const { id } = row;
    navigate(`/admin/cards/${id}`);
  };

  const exportDataHandler = async () => {
    const parsedFilters = prepareExportFilters(filters);
    try {
      const response = await api.post(
        '/acp/card/cardExport',
        { filters: { ...parsedFilters, orderBy } },
        { responseType: 'blob' },
      );
      const fileName = 'cards';
      exportCSVDataHandler({ response, fileName, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  if (isLoading) {
    return <LoaderOverlay customStyle={classes.loaderWrapper} isAdmin />;
  }
  return (
    <div className={classes.wrapper}>
      <Table
        columns={seoCardListTableConfig(t)}
        data={seoCardList}
        orderBy={orderBy}
        expandableRow
        onExpandRowRender={onExpandRowRender}
        className={classes.tableSeoCardList}
        tableBodyStyle={classes.tableBody}
        onSortClick={sortHandler}
        editHandler={onCardDetailsNavigate}
        stickyCells
        emptyStateTitle={t('adminControlPanel.noData')}
      />
      <AdminPagination
        limit={filters.limit}
        total={cardsTotalAmount}
        offset={filters.offset}
        onPageChange={handleFiltersChange(OFFSET)}
        onLimitChange={handleFiltersChange(LIMIT)}
        onExportData={exportDataHandler}
        disableExport={readOnlyExportBtn}
      />
    </div>
  );
};

export default SeoCardList;
