import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import AdminLoader from '../../../app/Admin/components/AdminLoader';
import Loader from '../../../app/SEO/components/Loader';

const LoaderOverlay = ({ isAdmin = false, customStyle }) => {
  const { t } = useTranslation();
  return <div className={clsx(classes.loaderWrapper, customStyle)}>{isAdmin ? <AdminLoader /> : <Loader />}</div>;
};

export default LoaderOverlay;
