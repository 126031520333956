import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDashboard } from '../../../helpers/dashboard';
import classes from './styles.module.css';
import { useModal } from '../../../../../shared/helpers/hooks';
import PurchaseSubscriptionForm from '../PurchaseSubscriptionForm';
import CancelSubscriptionModalConfirmation from '../CancelSubscriptionModalConfirmation';
import SubscriptionPlanInfo from '../SubscriptionPlanInfo';
import SubscriptionSummary from '../SubscriptionSummary';
import SubscriptionSinglePaymentInfo from '../SubscriptionSinglePaymentInfo';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/constants';
import SubscriptionActions from '../SubscriptionActions';
import { getDisplayCondition } from './utils';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';

const SubscriptionDetails = ({ refetchFn, subscriptionData, isActive }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const { subscription, subscriptionDate, subscriptionBuyDate, paymentType, status } = subscriptionData;
  const [isOpenOfferForm, setIsOpenOfferForm, toggleOfferForm] = useModal();
  const [isOpenConfirmationModal, setIsOpenConfirmationModal, toggleConfirmationModal] = useModal();
  const { isSinglePaymentInformationDisplayed } = getDisplayCondition(subscription, paymentType);

  const isTrial = subscription === 'trial';

  const devOnlyDeleteSubscription = async () => {
    await api.post('subscription/deleteTrial', { businessProfileId: businessProfile.id });
    await refetchFn();
  };

  const onCancelModalClose = async () => {
    await refetchFn();
    toggleConfirmationModal();
  };

  return (
    <div className={classes.wrapper}>
      {isOpenOfferForm && <PurchaseSubscriptionForm onClose={toggleOfferForm} currentSubsription={subscription} />}
      {isOpenConfirmationModal && (
        <CancelSubscriptionModalConfirmation
          title={t('subscription.cancelSubscriptionModalTitle')}
          onClose={onCancelModalClose}
          expireDate={dayjs(subscriptionDate).format(DATE_FORMAT_DD_MM_YYYY)}
          refetchFn={refetchFn}
        />
      )}
      <SubscriptionPlanInfo subscription={subscription} paymentType={paymentType} />
      <SubscriptionSummary
        subscriptionBuyDate={subscriptionBuyDate}
        subscriptionDate={subscriptionDate}
        paymentType={paymentType}
        subscriptionStatus={status}
      />
      {isSinglePaymentInformationDisplayed && <SubscriptionSinglePaymentInfo />}
      {!isTrial && isActive && (
        <SubscriptionActions
          paymentType={paymentType}
          toggleConfirmationModal={toggleConfirmationModal}
          toggleOfferForm={toggleOfferForm}
          subscriptionData={subscriptionData}
        />
      )}
      <div className={classes.desactivateTrialWrapper}>
        <p>UWAGA! Przycisk tymczasowy</p>
        <Button
          label={t('subscription.cancelSubscription')}
          className={classes.cancelSubBtn}
          onClick={devOnlyDeleteSubscription}
        />
      </div>
    </div>
  );
};

export default SubscriptionDetails;
