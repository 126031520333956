import React from 'react';
import i18next from 'i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Checkbox from '../../../../../shared/components/Checkbox';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { DATE_FORMAT_DD_MM_YYYY } from '../../../helpers/constants';
import { ReactComponent as CalendarIcon } from '../../../../../shared/assets/calendar.svg';

export const renderFilterOptions = ({ config, selectedFilters, toggleCalendar, handleFiltersClick }) => Object.entries(config).map(([key, options]) => {
  const { filterKey } = options[0];
  const isSelected = (option) => selectedFilters[filterKey]?.includes(option);
  const isDateSelected = selectedFilters.dateFrom && selectedFilters.dateTo;

  const btnLabel = !isDateSelected
    ? `${i18next.t('settings.selectDate')}`
    : `${dayjs(selectedFilters.dateFrom).format(DATE_FORMAT_DD_MM_YYYY)} - ${dayjs(selectedFilters.dateTo).format(
      DATE_FORMAT_DD_MM_YYYY,
    )}`;

  return (
    <div key={key}>
      {key === 'settings.date' ? (
        <Button className={classes.filterButton} label={btnLabel} Icon={CalendarIcon} onClick={toggleCalendar} />
      ) : (
        <>
          <div className={classes.key}>{i18next.t(key)}</div>
          <div className={classes.optionWrapper}>
            {options.map((option) => (
              <div key={`${filterKey}-${option.value}`} onClick={() => handleFiltersClick(option)}>
                <PillWithIcon className={clsx(classes.option, isSelected(option.value) && classes.selectedOption)}>
                  <Checkbox
                    onClick={() => handleFiltersClick(option)}
                    value={isSelected(option.value)}
                    checkboxStyle={classes.checkboxStyle}
                  />
                  {i18next.t(option.label)}
                </PillWithIcon>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});

export const getFiltersCount = (obj) => Object.entries(obj).reduce((acc, [key, value]) => {
  if ((key === 'dateFrom' || key === 'dateTo') && value) {
    return acc.includes('dateFilter') ? acc : [...acc, 'dateFilter'];
  }

  if (Array.isArray(value) && value.length > 0) {
    return [...acc, ...value];
  }

  return acc;
}, []).length;
