import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import SearchInput from '../../../../shared/components/SearchInput';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import ViewTitle from '../ViewTitle';
import AdminLoader from '../AdminLoader';
import AdminPagination from '../AdminPagination';
import { FILTERS_ENUM } from '../../views/AdministratorList/utils';
import { ROLE_ENUM } from '../../views/AdministratorDetails/utils';
import { useAdmin } from '../../helpers/admin';
import { exportCSVDataHandler, prepareExportFilters } from '../../helpers/utils';
import { apiPathConfigForExport } from './utils';
import Button from '../../../../shared/components/Button';
import { ReactComponent as AddBtnIcon } from '../../../../shared/assets/addCircle.svg';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const { LIMIT, OFFSET, SEARCH } = FILTERS_ENUM;

const ViewWrapper = ({
  children,
  title,
  addBtnLabel,
  onAddButtonClick,
  isLoading,
  filters,
  handleFiltersChange,
  totalRecords,
  orderBy,
  renderAdditionalFilter,
}) => {
  const { t } = useTranslation();
  const { role } = useAdmin();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { pathname } = useLocation();

  const isButtonVisible = addBtnLabel && onAddButtonClick;

  const exportDataHandler = async () => {
    const parsedFilters = prepareExportFilters(filters);
    const { endpoint, fileName } = apiPathConfigForExport[pathname];
    try {
      const response = await api.post(endpoint, { filters: parsedFilters, orderBy }, { responseType: 'blob' });
      exportCSVDataHandler({ fileName, response, showNotification, t });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
  };

  return (
    <div>
      {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
      <ViewTitle title={title} />
      <div className={classes.toolWrapper}>
        <SearchInput
          className={classes.searchInput}
          label={t('global.search')}
          onSearch={handleFiltersChange(SEARCH)}
          value={filters.search}
        />
        {renderAdditionalFilter && renderAdditionalFilter()}
        {isButtonVisible && (
          <Button Icon={AddBtnIcon} className={classes.addCardBtn} label={addBtnLabel} onClick={onAddButtonClick} />
        )}
      </div>
      {children}
      <AdminPagination
        className={classes.pagination}
        total={totalRecords}
        offset={filters.offset}
        limit={filters.limit}
        onPageChange={handleFiltersChange(OFFSET)}
        onLimitChange={handleFiltersChange(LIMIT)}
        onExportData={exportDataHandler}
        disableExport={role !== ROLE_ENUM.superAdmin}
      />
    </div>
  );
};

export default ViewWrapper;
