import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../shared/components/Input';
import { useAdmin } from '../../helpers/admin';
import Button from '../../../../shared/components/Button';
import { useModal } from '../../../../shared/helpers/hooks';
import ChangePasswordModalForm from '../../modules/AdminSettings/ChangePasswordModalForm';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import ViewTitle from '../../components/ViewTitle';
import LoaderOverlay from '../../../../shared/components/LoaderOverlay';

const AdminSettings = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [showModal, setShowModal, modalHandler] = useModal();
  const { login, name, lastName } = useAdmin();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({ name, lastName });
  const isReadOnlyBtn = user.name === name && user.lastName === lastName;
  const userFullNameInputChangeHandler = (key) => (value) => {
    setUser((prev) => ({ ...prev, [key]: value }));
  };

  const saveChangeHandler = async () => {
    setIsLoading(true);
    try {
      await api.post('/acp/user/updateUser', {
        login,
        name: user.name,
        lastName: user.lastName,
        active: true,
      });
      showNotification({ label: t('global.success'), message: t('adminControlPanel.changesSaved'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <ViewTitle title={t('adminControlPanel.settings')} />
      <div className={classes.wrapper}>
        {isLoading && <LoaderOverlay isAdmin customStyle={classes.loaderWrapper} />}
        {showModal && <ChangePasswordModalForm onClose={modalHandler} />}
        <div>
          <div className={classes.form}>
            <div>
              <div className={classes.inputTitle}>{t('global.email')}</div>
              <Input admin className={classes.input} value={login} readOnly />
            </div>
            <div>
              <div className={classes.inputTitle}>{t('adminControlPanel.lastName')}</div>
              <Input
                admin
                className={classes.input}
                value={lastName}
                onChange={userFullNameInputChangeHandler('lastName')}
              />
            </div>
            <div>
              <div className={classes.inputTitle}>{t('adminControlPanel.name')}</div>
              <Input admin className={classes.input} value={name} onChange={userFullNameInputChangeHandler('name')} />
            </div>
            <Button
              label={t('adminControlPanel.changePassword')}
              className={classes.changePasswordBtn}
              onClick={modalHandler}
            />
          </div>
        </div>
        <div>
          <Button
            readOnly={isReadOnlyBtn}
            label={t('adminControlPanel.saveChanges')}
            className={classes.saveBtn}
            onClick={saveChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default AdminSettings;
