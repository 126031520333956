import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { ReactComponent as Close } from '../../assets/close.svg';
import { ReactComponent as ArrowBack } from '../../assets/arrowAlternative.svg';

const Modal = ({
  children,
  contentStyle,
  modalTitle,
  onCancel,
  showArrowBack,
  onBack,
  rwdBackTitle,
  Icon,
  titleStyle,
  customIconStyle,
  backdropLocked,
  isDraggable = false,
}) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const onBackdropClick = (e) => {
    if (!modalRef.current.contains(e.target) && !backdropLocked) {
      onCancel(e);
    }
  };

  const onMouseDown = (e) => {
    if (!isDraggable) return;
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  const onMouseMove = (e) => {
    if (isDragging && isDraggable) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y,
      });
    }
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDraggable) {
      window.addEventListener('mousemove', onMouseMove);
      window.addEventListener('mouseup', onMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [isDragging, offset]);

  return (
    <div onClick={onBackdropClick} className={classes.backdrop}>
      <div
        ref={modalRef}
        className={clsx(classes.modal, contentStyle)}
        style={isDraggable ? { left: `${position.x}px`, top: `${position.y}px`, position: 'absolute' } : {}}
        onMouseDown={onMouseDown}
      >
        <div className={classes.content}>
          {rwdBackTitle && (
            <div className={classes.mobileBackBox} onClick={onBack || onCancel}>
              <ArrowBack className={classes.goBackIconRWD} />
              {t(rwdBackTitle)}
            </div>
          )}
          <div className={clsx(classes.title, titleStyle)}>
            {showArrowBack && <ArrowBack onClick={onBack} className={classes.goBackIcon} />}
            <div className={classes.text}>{modalTitle}</div>
            {Icon && <Icon />}
            {(!rwdBackTitle || !onCancel) && (
              <div onClick={onCancel} className={clsx(classes.iconWrapper, customIconStyle)}>
                <Close className={clsx(classes.closeIcon, !rwdBackTitle && classes.showClose)} />
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
export default Modal;
