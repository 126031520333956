import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../../shared/components/Button';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import { ReactComponent as Business } from '../../../../shared/assets/noBusinessProfile.svg';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';

const RegisterMethods = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const handleGoogleUrl = async () => {
    try {
      const { data } = await api.post('/google_auth/getGoogleAuthUrl', {});
      window.location.href = data.googleAuthUrl;
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };
  const goBackHandler = () => navigate(-1);

  return (
    <div className={classes.wrapper}>
      <div className={classes.form}>
        <Business className={classes.businessIcon} />
        <div className={classes.header}>{t('registerMethods.header')}</div>
        <div className={classes.description}>{t('registerMethods.subHeader')}</div>
        <Button className={classes.button} label={t('registerMethods.connectWithGoogle')} onClick={handleGoogleUrl} />
        <Button className={classes.backButton} label={t('global.goBack')} onClick={goBackHandler} />
      </div>
    </div>
  );
};

export default RegisterMethods;
