import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const FiltersTitle = ({ adminPreview }) => {
  const { t } = useTranslation();

  return <div className={classes.filtersTitle}>{!adminPreview && t('generateReport.yourFilters')}</div>;
};

export default FiltersTitle;
