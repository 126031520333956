export const apiPathConfigForExport = {
  '/admin/managers': {
    endpoint: '/acp/client/clientListExport',
    fileName: 'managerList',
  },
  '/admin/users': {
    endpoint: '/acp/client/clientListExport',
    fileName: 'clientList',
  },
  '/admin/administrators': {
    fileName: 'acpUsers',
    endpoint: '/acp/user/userListExport',
  },
};
